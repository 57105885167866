import {
  Link,
  useFetcher,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import AuthService from "../../../api/AuthService";
import ErrorAlert from "../../../components/ErrorAlert";
import { useEffect, useState } from "react";
import { string } from "yup";
import Spinner from "../../../components/Spinner";
import LabeledInput from "../../../components/LabeledInput";

export async function action(data: any) {
  let formData = await data.request.formData();

  let email = formData.get("email");
  let username = formData.get("username");
  let confirmPassword = formData.get("confirmPassword");
  let accountType = "INDIVIDUAL";

  const response = (await AuthService.signUp(
    email,
    username,
    confirmPassword,
    accountType
  )) as any;

  if (response.hasError) {
    return {
      authFailed: true,
      errors: response.error.response.data.errors,
    };
  }

  return {};
}

export const loader = async ({ request }: any) => {
  const params = new URL(request.url).searchParams;
  const isVerify = params.get("verify");
  return {
    isVerify: isVerify,
  };
};

export default function SignUp() {
  const fetcher = useFetcher();
  const loaderData = useLoaderData() as any;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyFailedMessage, setVerifyFailedMessage] = useState(undefined as any);

  const fieldsAreValid = () => {
    return email && username && password && confirmPassword ? true : false;
  };

  useEffect(() => {
    const sub = searchParams.get("sub");
    const code = searchParams.get("code");
    let accountType = "INDIVIDUAL";

    if (sub && code) {
      AuthService.verifyEmail(sub, code, accountType)
        .then()
        .then((response:any) => {
          if (response.hasError) {
            setVerifyFailedMessage(response?.error?.response?.data.message as any);
          } else {
            navigate('/auth/signin');
          }
        });
    }
  }, []);

  if (loaderData.isVerify) {
    return (
      <div className="flex items-center justify-center flex-col gap-5 text-white text-opacity-50">
        {
          (verifyFailedMessage) ?
          (
            <ErrorAlert message={`Could not verify your email! Server response: ${verifyFailedMessage}`}></ErrorAlert>
          ) :
          (
            <>
            <div>Verifing Email</div>
            <Spinner heigt="h-10"></Spinner>
            </>
          )
        }
      </div>
    );
  }

  if (fetcher?.data) {
    if (!fetcher?.data?.authFailed) {
      return (
        <div className="text-white text-opacity-50">
          Check your inbox for a confirmation email and follow the instructions
          to activate your account.
        </div>
      );
    }
  }

  return (
    <fetcher.Form method="post" className="flex flex-col gap-7">
      {fetcher?.data?.authFailed ? (
        <ErrorAlert message="Could not sign up"></ErrorAlert>
      ) : (
        ""
      )}
      <LabeledInput
        type="email"
        name="email"
        top="Email"
        yupSchema={string().email()}
        onInput={setEmail}
      ></LabeledInput>
      <LabeledInput
        type="text"
        name="username"
        top="Username"
        onInput={setUsername}
        yupSchema={string().min(4)}
      ></LabeledInput>
      <LabeledInput
        type="password"
        name="password"
        top="Password"
        onInput={setPassword}
        yupSchema={string().min(1)}
      ></LabeledInput>
      <LabeledInput
        type="password"
        name="confirmPassword"
        top="Confirm Password"
        onInput={setConfirmPassword}
        yupSchema={string().min(1).equals([password], "Passwords do not match")}
      ></LabeledInput>

      <div className="flex flex-col gap-4">
        {fetcher.state === "submitting" || fetcher.state === "loading" ? (
          <button
          type="submit"
          className={`btn btn-primary text-white`}
          disabled={true}
        >
          <span className="loading loading-spinner "></span>
          Sign Up
        </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary text-white"
            disabled={!fieldsAreValid()}
          >
            Sign Up
          </button>
        )}

        <div className="text-sm text-center text-opacity-50">
          Already have an account?{" "}
          <Link className="link link-info" to={"/auth/signin"}>
            Sign In
          </Link>
        </div>

        <div className="text-sm  text-center text-opacity-50">
          You forgot your password?{" "}
          <Link className="link link-info" to={"/auth/resetpassword"}>
            Reset password
          </Link>
        </div>
      </div>
    </fetcher.Form>
  );
}
