"use client";

import HomeCard from "./HomeMainCarouselCard";
import Carousel from "react-multi-carousel";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useSearchAndFilterCoursesQuery } from "../api/CourseService";
import HomeMainCarouselCardSkeleton from "./HomeMainCarouselCardSkeleton";
import CarouselArrow from "./CarouselArrow";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function MainHomeCarousel() {
  const { data, loading } = useSearchAndFilterCoursesQuery({
    variables: {
      pagination: {
        page: 1,
        limit: 10,
      },
      searchString: "",
      filter: {
        homeCourses: true,
      },
    },
  });

  if (loading) {
    return (
      <div className="h-full flex">
        <div className="xl:w-1/2 lg:w-1/2 md:w-full h-full flex">
          <div className="mt-auto w-full">
            <HomeMainCarouselCardSkeleton></HomeMainCarouselCardSkeleton>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="h-full ">
      <Carousel
        rtl={"" as any}
        customRightArrow={<CarouselArrow direction="right"/>}
        customLeftArrow={<CarouselArrow direction="left"/>}
        className=""
        responsive={responsive}
        containerClass="carousel-wrapper"
      >
        {data?.search?.docs?.map((course) => {
          return (
            <div key={course?._id} className="h-full">
              <div className="relative w-full h-full">
                <img
                  src={`https://static.socora.com/img/course/large/${course?._id}.jpg`}
                  className="h-[35rem] w-full object-cover "
                  alt="Tailwind CSS Carousel component"
                />
                <div className="absolute top-0 h-full w-full bg-gradient-to-b from-transparent to-base-100 ">
                  <div className="xl:w-1/2 lg:w-1/2 md:w-full h-full flex">
                    <div className="mt-auto w-full">
                      <HomeCard course={course as any}></HomeCard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
