import {
  CourseSectionBookmark,
  CourseSubsectionBookmark,
  Sections,
  Subsection,
  UserCourseState,
} from "../api/CourseService";
import { arrayToMap, secondsToHoursAndMinutes } from "../util/convertors";
import { useEffect, useState } from "react";
import { RadialProgress } from "./RadialProgress";
import SubsectionBookmark from "./SubsectionBookmark";

export default function CourseContentCollapse(props: {
  data: {
    state: UserCourseState;
    sections: Sections[];
    sectionBookmarks: CourseSectionBookmark[];
    subsectionBookmarks: CourseSubsectionBookmark[];
    currentSubsection: Subsection;
  };
  courseId: string;
  sectionClickHandler?: any;
  subsectionClickHandler?: any;
}) {
  useEffect(() => {
    setUserState(
      arrayToMap(props?.data.state?.videosState as any[], "subsectionId")
    );
  }, [props.data.state]);

  const [sectionBookmarks] = useState(() =>
    arrayToMap(props?.data?.sectionBookmarks, "sectionId")
  );
  const [subsectionBookmarks] = useState(() =>
    arrayToMap(props?.data?.subsectionBookmarks, "subsectionId")
  );

  const [userStateMap, setUserState] = useState({} as any);

  const sectionFinishedCount = (section: Sections) => {
    if (props.data.state) {
      let finishedCount = 0;
      for (const subsection of section?.subsections!) {
        if (userStateMap?.[subsection?._id as string]?.finished) {
          finishedCount++;
        }
      }
      return finishedCount;
    } else {
      return 0;
    }
  };

  const subsectionClickHandler = (
    sectionIndex: number,
    subsectionIndex: number
  ) => {
    props.subsectionClickHandler(sectionIndex, subsectionIndex);
  };

  return (
    <div className={`flex flex-col w-full`}>
      <ul className="menu w-full rounded-box p-0">
        {props?.data?.sections?.map((section, sectionIndex: number) => {
          return (
            <li className="w-full lg:text-base text-xs" key={section?._id}>
              <h2 className="menu-title p-0 pt-5">
                <div className="flex gap-2 items-center">
                  <div>
                    <RadialProgress
                      value={
                        (sectionFinishedCount(section) /
                          section?.subsections?.length!) *
                        100
                      }
                      label={sectionFinishedCount(section).toString()}
                    />
                  </div>
                  <span className="">
                    Module {sectionIndex}: {section?.title}
                  </span>
                </div>
              </h2>

              <ul className="w-full">
                {section?.subsections?.map(
                  (subsection: any, subsectionIndex: number) => {
                    return (
                      <div
                        key={subsection?._id}
                        onClick={() =>
                          subsectionClickHandler(sectionIndex, subsectionIndex)
                        }
                        className={`rounded-lg hover:bg-base-300 cursor-pointer p-2`}
                      >
                        <div className={`font-semibold w-full`}>
                          <div className="flex items-center">
                            <div
                              className={`flex flex-col w-full ${
                                userStateMap?.[subsection?._id]?.finished
                                  ? "dark:text-green-300 text-green-600"
                                  : ""
                              }
                              ${
                                props.data.currentSubsection?._id ===
                                subsection?._id
                                  ? "text-primary "
                                  : ""
                              } 
                              `}
                            >
                              <div className="whitespace-normal break-words">{section.title}</div>
                              <div className="text-xs opacity-50">
                                {secondsToHoursAndMinutes(
                                  section?.duration as number
                                )}
                              </div>
                            </div>
                            <div className="ml-auto">
                              <SubsectionBookmark
                                courseId={props.courseId}
                                sectionId={section._id as string}
                                subsectionId={subsection._id as string}
                                isBookmarked={
                                  subsectionBookmarks?.[subsection?._id]
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
