// @ts-nocheck
import {Xterm} from "./xterm";
import {Terminal, WebTTY, protocols} from "./webtty";
import {ConnectionFactory} from "./websocket";


export class Gotty {

    public wsConnection : any;
    public xterm: any;

    constructor(elementId, conUrl) {

        const elem = document.getElementById(elementId);

        if (elem !== null) {
            var term: Terminal;
            term = new Xterm(elem);

            const httpsEnabled = window.location.protocol == "https:";
            const url = (httpsEnabled ? 'wss://' : 'ws://') + conUrl;
            const args = window.location.search;
            const factory = new ConnectionFactory(url, protocols);
            const wt = new WebTTY(term, factory, args, '');
            const closer = wt.open();

            window.addEventListener("unload", () => {
                closer();
                term.close();
            });

            this.wsConnection = wt.wsConnection;
            this.xterm = term;
            Window['wsConnection'] = wt.wsConnection.bare
        }
    }
}
