import { useState } from "react";
import { useCreateCourseSubsectionBookmarkMutation, useDeleteCourseSubsectionBookmarkMutation } from "../api/CourseService";
import { BookmarkIcon } from "@heroicons/react/24/outline";

export default function SubsectionBookmark(props: {
  isBookmarked: boolean;
  courseId: string;
  sectionId: string;
  subsectionId: string;
}) {
  const [isBookmarked, setIsBookmarked] = useState(props.isBookmarked);

  const [createSectionBookmarkHandler] =
    useCreateCourseSubsectionBookmarkMutation({
      variables: {
        input: {
          courseId: props.courseId,
          sectionId: props.sectionId,
          subsectionId: props.subsectionId,
        },
      },
    });

  const [deleteSectionBookmarkHandler] =
    useDeleteCourseSubsectionBookmarkMutation({
      variables: {
        input: {
          id: props.subsectionId,
        },
      },
    });

  const bookmarkHandler = (e: any) => {
    e.stopPropagation();
    if (isBookmarked) {
      deleteSectionBookmarkHandler();
    } else {
      createSectionBookmarkHandler();
    }
    setIsBookmarked(!isBookmarked);
  };

  return (
    <BookmarkIcon
      onClick={(e) => bookmarkHandler(e)}
      className={`h-5 cursor-pointer  ${
        isBookmarked
          ? "fill-accent text-accent"
          : "hover:text-primary fill-transparent"
      }`}
      fill=""
    ></BookmarkIcon>
  );
}

