import { ApolloClient, InMemoryCache } from "@apollo/client";
import * as Apollo from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink({
  delay: {
    initial: 400,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      if (
        error.message ===
        "Failed to execute 'text' on 'Response': body stream already read"
      )
        return true;

      let unauthenticatedError = error.result.errors?.find(
        (err: any) => err.message === "UNAUTHENTICATED"
      );
      
      return !!unauthenticatedError || error.result.msg === "Unauthorized"
    },
  },
});

const defaultOptions = {} as const;

const httpLink = new Apollo.HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/course/gql`,
  credentials: "include",
});

const courseClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/course/gql`,
  cache: new InMemoryCache(),
  credentials: "include",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  link: Apollo.from([retryLink, httpLink]),
});

const labClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/lab/gql`,
  cache: new InMemoryCache(),
  credentials: "include",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export function getCourseApolloClient() {
  return courseClient;
}

export function getLabApolloClient() {
  return labClient;
}

export function useCreateBookmarkMutation(baseOptions: any, document: any) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<any, any>(document, options);
}

export function useDeleteBookmarkMutation(baseOptions: any, document: any) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<any, any>(document, options);
}

export function useRateMutation(baseOptions: any, document: any) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<any, any>(document, options);
}
