"use client";

import { FunnelIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import {
  useSearchTagsQuery,
  SearchTagsQuery,
  SearchTagsDocument,
} from "../api/CourseService";
import { getCourseApolloClient } from "../util/apollo";
import { QueryOptions } from "@apollo/client";

export const predefinedFilters = [
  {
    label: "Difficulty",
    options: [
      {
        id: 1,
        label: "Begginer",
      },
      {
        id: 2,
        label: "Intermediate",
      },
      {
        id: 3,
        label: "Advanced",
      },
    ],
  },
  {
    label: "Plan",
    options: [
      {
        id: 1,
        label: "Basic",
      },
      {
        id: 2,
        label: "Standard",
      },
      {
        id: 3,
        label: "Premium",
      },
    ],
  },
  {
    label: "Status",
    options: [
      {
        id: 0,
        label: "Finished",
      },
      {
        id: 0,
        label: "Currently Watching",
      },
    ],
  },
  {
    label: "Tags",
    options: [
      {
        id: 0,
        label: "Java",
      },
      {
        id: 1,
        label: ".NET",
      },
      {
        id: 2,
        label: "Cloud",
      },
    ],
  },
];

export function FilterButtons(props: { onChange: any }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState(predefinedFilters);
  const [pickedFilters, setPickedFilters] = useState({} as any);
  // const {data, loading, error} = useSearchTagsQuery({
  //   variables: {
  //     pagination: {
  //       page: 1,
  //       limit: 10
  //     },
  //     searchString: ""
  //   }
  // });

  const [data, setData] = useState(async () => {
    let client = getCourseApolloClient();

    let tags = await client.query({
      query: SearchTagsDocument,
      variables: {
        pagination: {
          page: 1,
          limit: 10,
        },
        searchString: "",
      },
    });

    return tags.data.searchTags.docs;
  });

  useEffect(() => {
    let tempFilter = JSON.parse(JSON.stringify(filters));

    let client = getCourseApolloClient();

    client
      .query({
        query: SearchTagsDocument,
        variables: {
          pagination: {
            page: 1,
            limit: 10,
          },
          searchString: "",
        },
      })
      .then((res: any) => {
        tempFilter[3].options = res.data?.searchTags?.docs?.map((tag: any) => {
          return {
            id: tag?._id,
            label: tag?.value,
          };
        }) as any;
        setFilters(tempFilter);
      });
  }, []);

  function setFilterSelection(filter: string, option: any) {
    let tempPickedFilters = { ...pickedFilters };
    let currentFilter = tempPickedFilters[filter];

    if (!currentFilter) {
      tempPickedFilters[filter] = [option];
      setPickedFilters(tempPickedFilters);
      props.onChange(tempPickedFilters);
      return;
    }

    let optionInFilter = currentFilter.findIndex((o: string) => option === o);
    if (optionInFilter !== -1) {
      currentFilter.splice(optionInFilter, 1);
      if (Object.keys(currentFilter).length === 0) {
        delete tempPickedFilters[filter];
      }
    } else {
      currentFilter.push(option);
    }

    setPickedFilters(tempPickedFilters);
    props.onChange(tempPickedFilters);
  }

  return (
    <div className="flex sm:flex-row flex-col gap-2">
      {!isFilterOpen ? (
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className={`btn btn-primary text-white w-fit border-none gap-2 ${
            Object.keys(pickedFilters).length !== 0 ? "btn-primary" : ""
          }`}
        >
          <FunnelIcon className="md:h-4 h-3"></FunnelIcon>
          <div className="md:w-5 w-3">
            ({Object.keys(pickedFilters).length})
          </div>
        </button>
      ) : (
        <div className="flex">
          <button
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className="btn btn-primary btn-active rounded-r-none gap-2 text-white"
          >
            <FunnelIcon className="md:h-4 h-3"></FunnelIcon>
            <div className="md:w-5 w-3">
              ({Object.keys(pickedFilters).length})
            </div>
          </button>
          {filters.map((filter, index) => {
            return (
              <div
                key={index}
                className={`dropdown ${
                  index > 1 && window.innerWidth <= 1024 ? "dropdown-end" : ""
                } z-10`}
              >
                <label
                  tabIndex={0}
                  className={`btn bg-base-200 border-none ${
                    index + 1 === filters.length
                      ? "rounded-l-none"
                      : "rounded-none"
                  }
                  ${pickedFilters?.[filter.label] ? "bg-base-300" : ""}
                  `}
                >
                  <span className="md:text-sm	text-xs">{filter.label}</span>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  {filter.options.map((option, optionIndex) => {
                    return (
                      <li key={optionIndex}>
                        <label className="label cursor-pointer">
                          <span className="label-text">{option.label}</span>
                          <input
                            type="checkbox"
                            className="checkbox checkbox-primary"
                            checked={pickedFilters?.[filter.label]?.includes(
                              option
                            )}
                            onClick={() =>
                              setFilterSelection(filter.label, option)
                            }
                          />
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
      {Object.keys(pickedFilters).length !== 0 ? (
        <button
          onClick={() => {
            setPickedFilters({});
            props.onChange({});
          }}
          className={`btn btn-error btn-ghost gap-2`}
        >
          <TrashIcon className="md:h-4 h-3" />
          <span className="md:text-sm	text-xs">Clear Filters</span>
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export function SmallScreenFilterButtons(props: { onChange: any }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState(predefinedFilters);
  const [pickedFilters, setPickedFilters] = useState({} as any);
  // const {data, loading, error} = useSearchTagsQuery({
  //   variables: {
  //     pagination: {
  //       page: 1,
  //       limit: 10
  //     },
  //     searchString: ""
  //   }
  // });

  const [data, setData] = useState(async () => {
    let client = getCourseApolloClient();

    let tags = await client.query({
      query: SearchTagsDocument,
      variables: {
        pagination: {
          page: 1,
          limit: 10,
        },
        searchString: "",
      },
    });

    return tags.data.searchTags.docs;
  });

  useEffect(() => {
    let tempFilter = JSON.parse(JSON.stringify(filters));

    let client = getCourseApolloClient();

    client
      .query({
        query: SearchTagsDocument,
        variables: {
          pagination: {
            page: 1,
            limit: 10,
          },
          searchString: "",
        },
      })
      .then((res: any) => {
        tempFilter[3].options = res.data?.searchTags?.docs?.map((tag: any) => {
          return {
            id: tag?._id,
            label: tag?.value,
          };
        }) as any;
        setFilters(tempFilter);
      });
  }, []);

  function setFilterSelection(filter: string, option: any) {
    let tempPickedFilters = { ...pickedFilters };
    let currentFilter = tempPickedFilters[filter];

    if (!currentFilter) {
      tempPickedFilters[filter] = [option];
      setPickedFilters(tempPickedFilters);
      props.onChange(tempPickedFilters);
      return;
    }

    let optionInFilter = currentFilter.findIndex((o: string) => option === o);
    if (optionInFilter !== -1) {
      currentFilter.splice(optionInFilter, 1);
      if (Object.keys(currentFilter).length === 0) {
        delete tempPickedFilters[filter];
      }
    } else {
      currentFilter.push(option);
    }

    setPickedFilters(tempPickedFilters);
    props.onChange(tempPickedFilters);
  }

  return (
    <div className="drawer z-20 w-fit">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <label
          htmlFor="my-drawer"
          className="btn btn-primary drawer-button h-full text-white uppercase"
        >
          <div className="flex gap-1">
            <FunnelIcon className="md:h-4 h-3"></FunnelIcon>
            <div className="md:w-5 w-3">
              ({Object.keys(pickedFilters).length})
            </div>
          </div>
        </label>
      </div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu p-4 pt-14 w-80 min-h-full bg-base-200 text-base-content">
          <div className=" flex flex-col gap-4 p-4">
            {filters.map((filter, index) => {
              return (
                <div key={index}>
                  <ul
                    tabIndex={0}
                    className=" menu p-2 shadow bg-base-100 rounded-box w-full"
                  >
                    <h2 className="menu-title">{filter.label}</h2>
                    {filter.options.map((option, optionIndex) => {
                      return (
                        <li key={optionIndex}>
                          <label className="label cursor-pointer">
                            <span className="label-text">{option.label}</span>
                            <input
                              type="checkbox"
                              className="checkbox checkbox-primary"
                              checked={pickedFilters?.[filter.label]?.includes(
                                option
                              )}
                              onClick={() =>
                                setFilterSelection(filter.label, option)
                              }
                            />
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );
}
