import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../util/misc";
import ThemeToggler from "../../components/ThemeToggler";

const pageTitles = {
  "/auth/signup": "Sign Up",
  "/auth/signin": "Sign In",
  "/auth/forceChangePassword": "Force Change Password",
  "/auth/signin/mfa": "Sign In MFA",
};

export default function Auth() {
  const location = useLocation();

  const [title, setTitle] = useState("Auth");

  useEffect(() => {
    // @ts-ignore
    setTitle(pageTitles[location.pathname]);

    document.title =
      "Socora | " + capitalizeFirstLetter(location.pathname.split("/")[2]);

    setTimeout(() => {
      // @ts-ignore
      document.getElementById("welcome_msg_1").style.opacity = 100;
    }, 500);
    setTimeout(() => {
      // @ts-ignore
      document.getElementById("welcome_msg_2").style.opacity = 100;
    }, 1000);
  }, [location]);

  return (
    <div className="h-screen flex bg-base-100 ">
      {/* form */}
      <div className="xl:w-[40%] w-[100%] flex items-center justify-center sm:p-24 p-10">
        <div className="md:w-[70%] w-full flex flex-col  ">
          <div className="flex items-center justify-center flex-col mb-10">
            <img width={230} height={230} src="/socora_logo_text.png" className="hidden dark:block"></img>
            <img width={230} height={230} src="/socora_logo_text_light.png" className="block dark:hidden"></img>
            {/* <div className="text-lg font-bold">{title}</div> */}
          </div>
          <Outlet />
          <div className=" pt-20 text-accent text-opacity-30 text-center text-xs">
            Copyright © 2021 - 2023 Socora LLC. All rights reserved.
          </div>
          <div className="xl:hidden block fixed top-2 right-0">
            <ThemeToggler />
          </div>
        </div>
      </div>
      {/* image */}
      <div className="xl:block hidden w-[60%] ">
        <div className="relative h-screen">
          {/* image text */}
          <div className="absolute w-full h-full flex items-center justify-center z-50">
            <div className="bg-black bg-opacity-50 p-10 rounded-3xl">
              <div
                id="welcome_msg_1"
                className="text-4xl text-white text-opacity-75 transition-opacity duration-700 ease-in opacity-0"
              >
                Mastering Cybersecurity, One Lab at a Time
              </div>
              <div
                id="welcome_msg_2"
                className="text-4xl text-white text-opacity-75 transition-opacity duration-700 ease-in opacity-0"
              >
                Welcome to{" "}
                <span className="text-4xl text-primary stat-value">Socora</span>
              </div>
            </div>
            <div className="fixed top-2 right-0">
              <ThemeToggler />
            </div>
          </div>

          <img
            className="object-cover h-full w-full block dark:hidden"
            alt="asd"
            src="https://static.socora.com/img/bg/login_light.jpg"
          ></img>
          <img
            className="object-cover h-full w-full hidden dark:block"
            alt="asd"
            src="https://static.socora.com/img/bg/login.jpg"
          ></img>
        </div>
      </div>
    </div>
  );
}
