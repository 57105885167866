import { Link, useRouteError } from "react-router-dom";

const errorMessage = {
  404: "404 Page not found",
  500: "500 Internal Server Error"
};

export default function AppErrorBoundary() {

  let error = useRouteError() as any;

  // @ts-ignore
  let message = errorMessage[error?.status]
  
  return (
    <div className="h-screen w-screen flex items-center justify-center flex-col gap-5">
      <div className="flex items-center justify-center gap-2">
     
        <div className="text-lg font-bold">
          {
            (message) ? message : 'Unknown error occurred'
          }
        </div>
      </div>
     
      <Link to={"/home"} className="link-primary underline">
        Return home
      </Link>
    </div>
  );
}
