import SocoraCard from "./SocoraCard";
import SocoraCardSkeleton from "./SocoraCardSkeleton";

const skeletons = Array.from(Array(20).keys());

export default function List(props: {
  loading: boolean;
  lazyLoading: boolean;
  lazyLoadingCount: number;
  content: any;
  contentType: string;
}) {
  if (props.loading && !props.lazyLoading) {
    return (
      <div className="flex flex-wrap justify-around min-h-screen">
        {skeletons.map((i) => (
          <SocoraCardSkeleton key={i}></SocoraCardSkeleton>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap justify-around ">
      {props.content.map((content: any) => {
        return (
          <div key={content._id} className="p-2">
            <SocoraCard
              contentType={props.contentType}
              content={content}
            ></SocoraCard>
          </div>
        );
      })}
      {props.lazyLoading
        ? skeletons.map((i) => (
            <SocoraCardSkeleton key={i}></SocoraCardSkeleton>
          ))
        : ""}
      {/* Temporary solution */}
      <div className="card card-compact w-[19rem] bg-transparent "></div>
      <div className="card card-compact w-[19rem] bg-transparent "></div>
      <div className="card card-compact w-[19rem] bg-transparent "></div>
      <div className="card card-compact w-[19rem] bg-transparent "></div>
    </div>
  );
}
