import { CheckIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

const severityIcons = {
  "alert-error": {
    icon: <XMarkIcon className="h-6"></XMarkIcon>,
  },
  "alert-success": {
    icon: <CheckIcon className="h-6"></CheckIcon>,
  },
  "alert-warning": {
    icon: <ExclamationCircleIcon className="h-6" />,
  },
};

export default function Alert(props: { message: string; severity: string }) {
  return (
    <div className={`alert ${props.severity}`}>
      <div className="flex gap-4 items-center">
        <div >
        {
          // @ts-ignore
          severityIcons[props.severity].icon
        }
        </div>
       
        <span className="text-sm">{props.message}</span>
      </div>
    </div>
  );
}
