import { ApolloProvider } from "@apollo/client";
import { Course } from "../api/CourseService"
import SocoraCard from "./SocoraCard";
import { getLabApolloClient } from "../util/apollo";
import { useGetLabsByIdsQuery } from "../api/LabService";
import SocoraCardSkeleton from "./SocoraCardSkeleton";


function PathLabsList(props: {
  labs: string[]
}) {

  const { data, loading, error } = useGetLabsByIdsQuery({
    variables: {
      labIds: props.labs
    }
  });

  if(loading) {
    return (
      <div className="flex flex-wrap justify-around gap-3 ">
        {
          props.labs.map(labId => (
            <SocoraCardSkeleton key={labId}></SocoraCardSkeleton>
          ))
        }  
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
      </div>
    )
  }


  return (
    <div className="flex flex-wrap justify-around gap-3">
        {
          data?.getLabsByIds?.map((lab) => {
            return (
              <div key={lab?._id} className="p-2">
                <SocoraCard
                  contentType={"lab"}
                  content={lab as any}
                ></SocoraCard>
              </div>
            )
          })
        }
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
    </div>
  )
}


export default function PathContent(props: {
  courses: Course[],
  labs: string[]
}) {


  
  return (
    <div className="flex flex-col gap-4">
      <article className="prose max-w-full prose-sm">
        <h2>Courses</h2>
      </article>
      <div className="flex flex-wrap justify-around gap-3">
        {
          props.courses.map((course) => {
            return (
              <div key={course._id} className="p-2">
                <SocoraCard
                  contentType={"course"}
                  content={course as any}
                ></SocoraCard>
              </div>
            )
          })
        }
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
        <div className="card card-compact w-[19rem] bg-transparent "></div>
      </div>
      <article className="prose max-w-full prose-sm">
        <h2>Labs</h2>
      </article>
      <div>
        <ApolloProvider client={getLabApolloClient()}>
          <PathLabsList labs={props.labs}></PathLabsList>
        </ApolloProvider>
        
      </div>
    </div>
  )
}