import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import Carousel from "react-multi-carousel";
import SocoraCardSkeleton from "./SocoraCardSkeleton";

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function HomeContentCarouselSkeleton() {
  return (
    <div className="h-full w-full">
      <Carousel
        arrows={false}
        responsive={responsive}
        className="pt-3"
      >
        {
          arr.map(i => {
            return (
              <div key={'skeleton-' + i} className="flex justify-center content-center">
                <SocoraCardSkeleton></SocoraCardSkeleton>
              </div>
            )
          })
        }
      </Carousel>
    </div>
  );
}