import { useParams } from "react-router-dom";
import { usePathAboutPageDataQuery } from "../../api/CourseService";
import Spinner from "../../components/Spinner";
import ContentAbout from "../../components/ContentAbout";

export default function Path() {
  let { pathId } = useParams();

  const { data, loading } = usePathAboutPageDataQuery({
    variables: {
      id: pathId as string
    },
  });

  if (loading) {
    return (
      <div className="h-[80vh] flex items-center justify-center">
        <Spinner heigt={"h-10"} />
      </div>
    );
  }

  return (
    <ContentAbout
      state={data?.getPathById?.state}
      contentType="path"
      userRate={data?.userRate}
      contentData={data?.getPathById}
    />
  );
}