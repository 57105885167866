import { useEffect, useRef, useState } from "react";
import {
  Note,
  Subsection,
  useGenerateCourseVideoTokenQuery,
} from "../../../api/CourseService";
import Spinner from "../../../components/Spinner";
import Plyr from "plyr";
import Hls from "hls.js";
import { createNoteDom, noteClickHandler } from "./player.util";
import { Stream } from "@cloudflare/stream-react";

function setupHLS(videoURL: string) {
  var video = document.querySelector("#player");

  if (Hls.isSupported()) {
    var hls = new Hls();
    hls.loadSource(
      `https://customer-6oi5coeyu1cnbn15.cloudflarestream.com/${videoURL}/manifest/video.m3u8`
    );
    // @ts-ignore
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, function () {});

    // @ts-ignore
    Plyr.setup(video);
  }
}

function setPlayerNotes(player: any, focusNotesTab: any) {
  // Delete current notes if any occurr.
  document.querySelectorAll(".plyr__tooltip_copy").forEach((el) => el.remove());

  // @ts-ignore
  let notesStr = document.getElementById("notes")?.innerText;
  if (notesStr) {
    const notes = JSON.parse(notesStr as string);
    notes.forEach((note: Note) => {
      createNoteDom(
        note,
        ((note?.videoTime as number) / player.duration) * 100
      );
      // @ts-ignore
      document.getElementById(note?.id).addEventListener("click", () => {
        noteClickHandler(note?.id as string, note?.videoTime as number);
        focusNotesTab();
      });
    });
  }
}

// function playerLoading(isLoading: boolean) {
//   if (document.getElementById("player")) {
//     // @ts-ignore
//     document.getElementById("player").hidden = isLoading;
//   }
//   // @ts-ignore
//   // document.getElementById("player_loading").style.display = !isLoading
//   //   ? "none"
//   //   : "";
// }

export default function SocoraPlyr(props: {
  videoDuration: number;
  courseId: string;
  subsection: Subsection;
  notes: Note[];
  noteClickHandler: any;
  onVideoFinish: any;
  seek: number;
  onStreamStartRef: any;
}) {
  const [videoSize, setVideoSize] = useState(window.innerWidth >= 1024);
  const [fullscreen, setFullscreen] = useState(false);
  const [showIFrame, setShowIFrame] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [iframeData, setIFrameData] = useState("");
  const streamRef = useRef(null as any);
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      // Update the state based on the screen size
      setVideoSize(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let { data, loading, error } = useGenerateCourseVideoTokenQuery({
    variables: {
      input: {
        courseId: props.courseId,
        videoId: props.subsection.videoId as string,
      },
    },
  });

  useEffect(() => {
    setShowIFrame(false);

    if (Hls.isSupported()) {
      setShowPlayer(true);
      if (!loading) {
        const player = new Plyr("#player", {
          tooltips: {
            seek: false,
          },
          invertTime: false,
        });

        player.on("loadeddata", () => {
          (document.getElementById("loading") as any).style.display = "none";
          (document.getElementById("player") as any).hidden = false;
          setPlayerNotes(player, props.noteClickHandler);
          player.currentTime = parseInt(
            document?.getElementById("seek")?.innerText as string
          );
        });

        player.on("enterfullscreen", () => {
          setFullscreen(true);
        });

        player.on("exitfullscreen", () => {
          setFullscreen(false);
        });

        player.on("ended", async (event) => {
          props.onVideoFinish(
            document.getElementById("current_subsection")?.innerText
          );
        });

        setupHLS(data?.getCourseVideoToken?.accessToken as string);
      }
    } else {
      if (!loading) {
        setIFrameData(
          `https://customer-6oi5coeyu1cnbn15.cloudflarestream.com/${data?.getCourseVideoToken?.accessToken}/iframe`
        );
        setShowIFrame(true);
      }
    }
  }, [loading]);

  return (
    <div
      className={`h-full flex items-center justify-center w-full min-h-[15rem]`}
    >
      <div>
        <div hidden id="seek">
          {props.seek}
        </div>
        <div hidden id="notes">
          {JSON.stringify(props.notes)}
        </div>
        <div id="current_subsection" hidden>
          {props.subsection._id}
        </div>

        {loading ? (
          <div className="flex items-center justify-center">
            <span className="loading loading-spinner"></span>
          </div>
        ) : (
          <>
            <div id="loading" className="flex items-center justify-center">
              <span className="loading loading-spinner"></span>
            </div>
            {showPlayer && (
              <div className="h-full rounded-lg shadow-xl z-[1] overflow-hidden">
                <video autoPlay id="player" hidden={true} className=""></video>
              </div>
            )}
            {showIFrame && (
              <>
                <Stream
                  onLoadStart={() => {
                    props.onStreamStartRef(streamRef);
                  }}
                  onLoadedData={() => {
                    streamRef.current.currentTime = parseInt(
                      document?.getElementById("seek")?.innerText as string
                    );
                  }}
                  onEnded={() => {
                    props.onVideoFinish(
                      document.getElementById("current_subsection")?.innerText
                    );
                  }}
                  streamRef={streamRef}
                  className="w-full h-full"
                  controls
                  src={iframeData}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
