import { redirect } from "react-router-dom";
import AuthService from "./api/AuthService";

export async function loaderUserRole(data: any) {
  let response = (await AuthService.getUser()) as any;
  if (response.data['cognito:groups'][0] === "BUSINESS") {    
    return redirect(
      "/settings/password"
    );
  } else {
    return response.data;
  }
}