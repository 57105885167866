import { useEffect, useRef, useState } from "react";
import ContentListSearchAutocomplete from "../components/ContentListSearchAutocomplete";
import {FilterButtons} from "../components/FilterButtons";
import {SmallScreenFilterButtons} from "../components/FilterButtons"
import parseFilter from "../util/filter";
import useOnScreen from "../components/useOnScreen";
import List from "./List";
import { useGQLSearch } from "../api/SearchService";

export default function ContentList(props: {
  contentType: string;
  showSearch?: boolean;
  searchString?: string;
  filter?: string;
}) {
  const [searchString, setSearchString] = useState("");
  const [filter, setFilter] = useState({});

  const [page, setPage] = useState(1);
  const [docs, setDocs] = useState([] as any);
  const [lazyLoading, setLazyLoading] = useState(false);

  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  const { data, loading, error } = useGQLSearch(props.contentType, {
    pagination: {
      page: page,
      limit: 20,
    },
    searchString: props.searchString ? props.searchString : searchString,
    filter: props.filter ? parseFilter(props.filter) : filter,
  });

  useEffect(() => {
    if (!loading && page > 1 && docs.length !== data?.search?.totalDocs) {
      let tempDocs = [...docs];
      setDocs(tempDocs.concat(data?.search?.docs));
      setLazyLoading(false);
    }

    if (!loading && isOnScreen) {
      if (page !== data?.search?.totalPages) {
        setLazyLoading(true);
        setPage(page + 1);
      }
    }

    if (!loading && page === 1 && !isOnScreen) {
      if (props.showSearch === false) {
        // @ts-ignore
        document.getElementById(
          `tab_label_${props.contentType}s`
        ).innerText = `${props.contentType}s (${data?.search?.totalDocs})`;
      }
      setDocs(data?.search?.docs);
    }
  }, [data, isOnScreen]);

  const onSearchInputComplete = (searchString: string) => {
    setDocs([]);
    setPage(1);
    setSearchString(searchString);
  };

  const onFilterChangeHandler = (filter: any) => {
    setDocs([]);
    setPage(1);
    setFilter(parseFilter(filter));
  };

  return (
    <div className="p-4 flex flex-col gap-4">
      {(
        props.showSearch === null || props.showSearch === undefined
          ? true
          : props.showSearch
      ) ? (
        <div className="flex flex-row gap-2">
          <div className="xl:hidden block">
            <SmallScreenFilterButtons onChange={onFilterChangeHandler}></SmallScreenFilterButtons>
          </div>
          <div className="lg:w-1/2 w-full">
            <ContentListSearchAutocomplete
              placeHolder="Search..."
              onComplete={onSearchInputComplete}
            ></ContentListSearchAutocomplete>
          </div>
          <div className="xl:block hidden">
            <FilterButtons onChange={onFilterChangeHandler}></FilterButtons>
          </div>
          
        </div>
      ) : (
        ""
      )}

      {!loading && docs.length === 0 ? (
        <div className="text-accent text-opacity-50 text-lg font-bold text-center">
          Nothing found
        </div>
      ) : (
        ""
      )}
      <List
        loading={loading}
        lazyLoading={lazyLoading}
        lazyLoadingCount={20}
        contentType={props.contentType}
        content={docs}
      ></List>
      <div ref={elementRef}></div>
    </div>
  );
}
