// @ts-ignore
import Quill from 'quill';
// @ts-ignore
import copy from 'clipboard-copy';

let playIconSVG = `<svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
<path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
</svg>`;

export class SocoraQuill {

  quillInstance: any;

  constructor() {
    let Inline = Quill.import('blots/inline');

    class SocoraCMDBlot extends Inline {
      static create(value: any) {
        let node = super.create();
        node.setAttribute('class', ' wk play-icon');
        node.addEventListener('click', (ev:any) => {
          // @ts-ignore
          Window["wsConnection"].send(1 + value + "\r");
        });
        return node;
      }

      static formats(domNode: Element) {
        return domNode.textContent;
      }
    }

    // @ts-ignore
    SocoraCMDBlot.blotName = 'socora-wk-cmd';
    // @ts-ignore
    SocoraCMDBlot.tagName = 'socora-wk-cmd';
    Quill.register(SocoraCMDBlot);

    class SocoraCopyBlot extends Inline {
      static create(value: any) {
        if (value.copy) {

          let node = super.create();
          node.setAttribute('class', ' wk');
          node.addEventListener('click', (ev:any) => {
            copy(value.text)
          });
          return node;

        } else {
          return super.create();
        }
      }

      static formats(domNode: Element) {
        if (domNode.tagName === "SOCORA-WK-CP") return {copy: true, text: domNode.textContent};
        return true;
      }
    }

    // @ts-ignore
    SocoraCopyBlot.blotName = 'socora-wk-cp';
    // @ts-ignore
    SocoraCopyBlot.tagName = 'socora-wk-cp';
    Quill.register(SocoraCopyBlot);

    this.quillInstance = Quill;

  }
}
