import {
  SearchAndFilterCoursesQueryVariables,
  useSearchAndFilterCoursesQuery,
  useSearchAndFilterPathsQuery,
} from "../api/CourseService";
import { ApolloProvider } from "@apollo/client";
import { getCourseApolloClient, getLabApolloClient } from "../util/apollo";
import ContentTable from "./ContentTable";
import {
  SearchAndFilterLabsQueryVariables,
  useSearchAndFilterLabsQuery,
} from "../api/LabService";
import ContentTableSkeleton from "./ContentTableSkeleton";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";

const variables = {
  pagination: {
    limit: 10,
    page: 1,
  },
  searchString: "",
  filter: {
    currentlyWatching: true,
  },
};

const tabs = [
  {
    label: "Courses",
    component: (
      <ApolloProvider client={getCourseApolloClient()}>
        <CurrenltyWatchingCourses></CurrenltyWatchingCourses>
      </ApolloProvider>
    ),
  },
  {
    label: "Paths",
    component: (
      <ApolloProvider client={getCourseApolloClient()}>
        <CurrenltyWatchingPaths></CurrenltyWatchingPaths>
      </ApolloProvider>
    ),
  },
  {
    label: "Labs",
    component: (
      <ApolloProvider client={getLabApolloClient()}>
        <CurrenltyWatchingLabs></CurrenltyWatchingLabs>
      </ApolloProvider>
    ),
  },
];

export function CurrenltyWatchingCourses(props: {
  variables?: SearchAndFilterCoursesQueryVariables;
}) {
  const { data, loading } = useSearchAndFilterCoursesQuery({
    variables: props.variables ? props.variables : variables,
  });

  if (loading) {
    return <ContentTableSkeleton rows={5}></ContentTableSkeleton>;
  }

  if (data?.search?.totalDocs === 0) {
    return (
      <div className="w-full p-10">
        <div className="h-full w-full sm:text-sm text-xs flex items-center justify-center text-accent text-opacity-50">
          Nothing here yet. Start watching 
          <Link to={"/courses"} className="text-primary link ml-1">courses</Link>
        </div>
      </div>
    );
  }

  return <ContentTable data={data} contentType="course"></ContentTable>;
}

export function CurrenltyWatchingPaths(props: {
  variables?: SearchAndFilterCoursesQueryVariables;
}) {
  const { data, loading } = useSearchAndFilterPathsQuery({
    variables: props.variables ? props.variables : variables,
  });

  if (loading) {
    return <ContentTableSkeleton rows={5}></ContentTableSkeleton>;
  }

  if (data?.search?.totalDocs === 0) {
    return (
      <div className="w-full pt-10">
        <div className="h-full w-full sm:text-sm text-xs flex items-center justify-center text-accent text-opacity-50">
          Nothing here yet. Start watching 
          <Link to={"/paths"} className="text-primary link ml-1">paths</Link>
        </div>
      </div>
    );
  }

  return <ContentTable data={data} contentType="path"></ContentTable>;
}

export function CurrenltyWatchingLabs(props: {
  variables?: SearchAndFilterLabsQueryVariables;
}) {
  const { data, loading } = useSearchAndFilterLabsQuery({
    variables: props.variables ? props.variables : variables,
  });

  if (loading) {
    return <ContentTableSkeleton rows={5}></ContentTableSkeleton>;
  }

  if (data?.search?.totalDocs === 0) {
    return (
      <div className="w-full pt-10">
        <div className="h-full w-full sm:text-sm text-xs flex items-center justify-center text-accent text-opacity-50">
          Nothing here yet. Start watching 
          <Link to={"/labs"} className="text-primary link ml-1">labs</Link>
        </div>
      </div>
    );
  }

  return <ContentTable data={data} contentType="lab"></ContentTable>;
}

function CurrenltyWatchingTabs() {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <div className="">
      <div className="tabs tabs-bordered sticky top-0 z-10 bg-base-100">
        {tabs.map((tab, index) => (
          <div
            key={tab.label}
            onClick={() => setCurrentTab(index)}
            className={`tab tab-bordered ${
              currentTab === index ? "tab-active" : ""
            }`}
          >
            {tab.label}
          </div>
        ))}
      </div>
      {tabs[currentTab].component}
    </div>
  );
}

export default function CurrenltyWatchingDropdown() {
  const location = useLocation();

  const [currentlyWatchingOpen, setCurrentlyWatchingOpen] = useState(false);

  useEffect(() => {
    setCurrentlyWatchingOpen(false);
  }, [location]);

  return (
    <div className="dropdown dropdown-end dropdown-open">
      <button
        onClick={() => setCurrentlyWatchingOpen(!currentlyWatchingOpen)}
        tabIndex={0}
        className={`btn btn-ghost ml-auto ${
          currentlyWatchingOpen ? "text-primary" : ""
        }`}
      >
        <ClockIcon className="h-4"></ClockIcon>
      </button>
      {currentlyWatchingOpen ? (
        <div
          tabIndex={0}
          className="p-0 menu dropdown-content shadow-xl bg-base-100 rounded-box mt-3 sm:w-[30rem] w-[15rem] h-[35rem] overflow-scroll"
        >
          <CurrenltyWatchingTabs></CurrenltyWatchingTabs>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
