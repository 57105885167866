import { Link, Outlet, useOutletContext, useParams } from "react-router-dom";
import ChangePasswordSettings from "./ChangePasswordSettings";
import MFASettings from "./MFASettings";
import { useEffect, useState } from "react";
import { PaymentService } from "../../api/PaymentService";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import {
  PaymentMethod,
  StripePaymentConfirmation,
} from "../subscribe/PaymentMethod";
import CreditCard from "../../components/CreditCard";
import CardSettings from "./CardSettings";
import PlanSettings from "./PlanSettings";
import Invoices from "./Invoices";

const settingsMenuItems = [
  {
    title: "Payment Settings",
    children: [
      {
        label: "Plan",
        path: "plan",
      },
      {
        label: "Card",
        path: "card",
      },
      {
        label: "Invoices",
        path: "invoices",
      },
    ],
  },
  {
    title: "Account Settings",
    children: [
      {
        label: "Change Password",
        path: "password",
      },
      {
        label: "Multi-Factor Authentication",
        path: "mfa",
      },
    ],
  },
  {
    title: "Theme Settings",
    children:[
      {
        label: "Theme",
        path: "theme"
      }
    ]
  }
];

const settingsMenuItemsBussines = [
  {
    title: "Account Settings",
    children: [
      {
        label: "Change Password",
        path: "password",
      },
      {
        label: "Multi-Factor Authentication",
        path: "mfa",
      },
    ],
  },
  {
    title: "Theme Settings",
    children:[
      {
        label: "Theme",
        path: "theme"
      }
    ]
  }
];

function SettingsMenu({
  showAccordion,
  setShowAccordion,
  accordionChildren,
  setAccordionChildren,
}: {
  showAccordion: string;
  setShowAccordion: (value: string) => void;
  accordionChildren: any[];
  setAccordionChildren: (value: any[]) => void;
}) {
  let { settingsId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { userRole } = useOutletContext<{ userRole : any }>();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };  

  return (
    <div>
      <div className="w-full">
        <ul className="menu rounded-box">
          {userRole === "BUSINESS_MEMBER" ? (
            settingsMenuItemsBussines.map((setting, index) => {
              return (
                <li key={index}>
                  <h2 className="menu-title">{setting.title}</h2>
                  <ul>
                    {setting.children.map((child, index) => (
                      <li key={index}>
                        <Link
                          to={`/settings/${child.path}`}
                          className={`no-underline hover:text-primary hover:bg-transparent ${
                            child.path === settingsId ? "link-primary " : ""
                          }`}
                        >
                          {child.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })
          ) : (
            settingsMenuItems.map((setting, index) => {
              return (
                <li key={index}>
                  <h2 className="menu-title">{setting.title}</h2>
                  <ul>
                    {setting.children.map((child, index) => (
                      <li key={index}>
                        <Link
                          to={`/settings/${child.path}`}
                          className={`no-underline hover:text-primary hover:bg-transparent ${
                            child.path === settingsId ? "link-primary " : ""
                          }`}
                        >
                          {child.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })
          )}
        </ul>
      </div>
    </div>
  );
}

// const settingsComponents = {
//   plan: {
//     component: <PlanSettings></PlanSettings>,
//   },
//   card: {
//     component: <CardSettings></CardSettings>,
//   },
//   invoices: {
//     component: <Invoices></Invoices>,
//   },
//   password: {
//     component: <ChangePasswordSettings></ChangePasswordSettings>,
//   },
//   mfa: {
//     component: <MFASettings></MFASettings>,
//   },
// };

// const settingsComponentsBussines = {
//   password: {
//     component: <ChangePasswordSettings></ChangePasswordSettings>,
//   },
//   mfa: {
//     component: <MFASettings></MFASettings>,
//   },
// };

export default function Settings() {
  let { settingsId } = useParams();
  const [showAccordion, setShowAccordion] = useState("");
  const [accordionChildren, setAccordionChildren] = useState<any[]>([]);

  useEffect(() => {
    for (const item of settingsMenuItems) {
      const match = item.children.find((child) => child.path === settingsId);
      if (match) {
        setShowAccordion(item.title);
        setAccordionChildren(item.children);
        break;
      }
    }
  }, [settingsId]);

  return (
    <div className="flex md:flex-row flex-col p-4">
      <div className="z-40">
        <SettingsMenu
          showAccordion={showAccordion}
          setShowAccordion={setShowAccordion}
          accordionChildren={accordionChildren}
          setAccordionChildren={setAccordionChildren}
        ></SettingsMenu>
      </div>
      <div className="md:divider hidden md:divider-horizontal"></div>
      <div className="md:hidden divider">{settingsId?.toUpperCase()}</div>

      <div className="w-full md:relative md:block flex justify-center">
        <Outlet />
      </div>
    </div>
  );
}
