import {
  CheckBadgeIcon,
  InformationCircleIcon,
  PlayCircleIcon,
  QueueListIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import Bookmark from "./Bookmark";
import {
  calculateProgress,
  secondsToHoursAndMinutes,
} from "../util/convertors";
import Rating from "./Rating";
import { Rating as RatingType } from "../api/CourseService";
import { useEffect, useState } from "react";
import { PaymentService } from "../api/PaymentService";
import { isNewContent } from "../util/misc";

type propsType = {
  _id: string;
  name: string;
  info: {
    duration: number;
    description: {
      about: string;
    };
    releaseDate: number;
  };
  tags: [{ _id: string; value: string }];
  bookmark: [];
  rating: RatingType;
  state: {
    finalized?: boolean;
    finishedUnits: number;
    totalUnits: number;
    finished?: number;
  };
};

export function ExamBadge() {
  return (
    <div className="flex badge badge-sm gap-1 bg-opacity-70 border-none ">
      <div>
        <InformationCircleIcon className="h-4 text-yellow-500"></InformationCircleIcon>
      </div>
      <div className="">Exam</div>
    </div>
  );
}

export function FinalizedBadge() {
  return (
    <div className="flex badge badge-sm gap-1 bg-opacity-70 border-none ">
      <div>
        <CheckBadgeIcon className="h-4 text-green-500" />
      </div>
      <div className="">Finished</div>
    </div>
  );
}

export default function SocoraCard(props: {
  content: propsType;
  contentType: string;
}) {
  const navigate = useNavigate();

  const routeToContent = () => {};

  return (
    <div
      onClick={() => navigate(`/${props.contentType}s/${props.content?._id}`)}
      className="card card-compact w-72 h-60 bg-transparent indicator"
    >
      {isNewContent(props.content.info.releaseDate) ? (
        <span className="indicator-item badge badge-primary z-20 badge-sm">
          NEW
        </span>
      ) : (
        ""
      )}
      <div className="relative ">
        <img
          className="rounded-box"
          src={`https://static.socora.com/img/${props.contentType}/small/${props.content._id}.jpg`}
          alt="Shoes"
          // sizes="100vw"
          style={{ width: "100%" }} // optional
        />
        <div
          className="absolute top-0 h-full w-full bg-black bg-opacity-60 rounded-2xl z-[1]
        opacity-0 hover:opacity-100 transition ease-in-out duration-300 cursor-pointer"
        >
          <div className="flex h-full w-full p-2">
            <div className="col w-full flex flex-col">
              {props.contentType === "course" ? (
                props.content.state?.finalized ? (
                  <FinalizedBadge />
                ) : props.content.state?.finished ? (
                  <ExamBadge />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {(props.contentType === "lab" || props.contentType === "path") &&
              props.content.state?.finished ? (
                <FinalizedBadge />
              ) : (
                ""
              )}

              <Rating rating={props.content?.rating}></Rating>
            </div>
            <div className=" col w-full flex flex-col content-center justify-center">
              <div className="flex content-center justify-center">
                <PlayCircleIcon className="h-12 hover:h-14 text-white hover:text-primary transition-all ease-in-out duration-300"></PlayCircleIcon>
              </div>
            </div>
            <div className="col w-full flex flex-col">
              <div className="ml-auto">
                <Bookmark
                  // @ts-ignore
                  isBookmarked={props.content?.bookmark?.length === 1}
                  iconHeightClass="h-4"
                  contentId={props.content._id}
                  contentType={props.contentType}
                />
              </div>

              <div className=" badge badge-sm border-none font-bold mt-auto ml-auto">
                {secondsToHoursAndMinutes(
                  props.content.info.duration as number
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0  h-full w-full flex p-2 z-0">
          {props.contentType === "course" ? (
            props.content.state?.finalized ? (
              <FinalizedBadge />
            ) : props.content.state?.finished ? (
              <ExamBadge />
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {(props.contentType === "lab" || props.contentType === "path") &&
          props.content.state?.finished ? (
            <FinalizedBadge />
          ) : (
            ""
          )}

          <div className=" badge badge-sm border-none font-bold mt-auto ml-auto bg-opacity-70">
            {secondsToHoursAndMinutes(props.content.info.duration as number)}
          </div>
        </div>
        <div className="absolute top-0 h-full w-full flex rounded-2xl overflow-hidden">
          {props.content?.state?.finishedUnits ? (
            <progress
              max="100"
              className="h-[0.2rem] w-full progress progress-primary mt-auto bg-white bg-opacity-10 "
              value={calculateProgress(
                props.content?.state?.finishedUnits,
                props.content?.state?.totalUnits
              )}
            ></progress>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="card-body" style={{ padding: "0.5rem" }}>
        <div
          className="card-title text-base line-clamp-1 "
          style={{ marginBottom: "0px" }}
        >
          <Link
            className="hover:link"
            to={`/${props.contentType}s/${props.content._id}`}
          >
            {" "}
            {props.content.name}
          </Link>
        </div>
        <div className="flex gap-4">
          {props.content.tags.slice(0, 2)?.map((tag) => {
            return (
              <div
                key={tag._id}
                className="badge gap-2 border-0 text-xs p-0 bg-transparent "
              >
                <TagIcon className="h-3 "></TagIcon>
                {tag.value}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
