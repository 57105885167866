
export function RadialProgress(props: { value: number; label: string }) {
  return (
    <div
      className={`radial-progress  text-xs ${
        props.value === 0 ? "text-base-300" : "dark:text-green-300 text-green-600"
      } border-4 border-none`}
      style={
        {
          "--value": props.value === 0 ? 100 : props.value,
          "--size": "2rem",
          "--thickness": "2px",
        } as any
      }
    >
      <div className="text-black dark:text-white opacity-60 ">{props.label}</div>
    </div>
  );
}