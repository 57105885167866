"use client";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import SocoraCard from "./SocoraCard";
import SocoraCardSkeleton from "./SocoraCardSkeleton";
import { useGQLSearch } from "../api/SearchService";
import CarouselArrow from "./CarouselArrow";

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 4,
  },
  small_desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
};

function getVariablesObj(tags: string[], currentlyWatching: boolean) {

  let filter = {
    currentlyWatching,
  } as any;

  if(tags.length > 0) {
    filter.tags = tags
  }

  return {
    pagination: {
      limit: 10,
      page: 1
    },
    searchString: "",
    filter
  }
}


function sortAndMapTags(tags: any) {
  return tags
        ?.sort((a:any,b:any) => (a?.count! >= b?.count!) ? 1 : ((b?.count! > a?.count!) ? -1 : 0))
        .reverse()
        .map((tag:any) => tag?._id)
  
}


export default function HomeContentCarousel(props: {
  contentType: string
  tags?: any,
  currentlyWatching: boolean
}) {

  const {data, loading, error} = useGQLSearch(
    props.contentType, 
    getVariablesObj((props?.tags && props.tags.length > 5) ? sortAndMapTags([...props.tags]): [], props.currentlyWatching)
  )

  return (
    <div className="h-full w-full">
      <Carousel
      rtl={""as any}
      customRightArrow={<CarouselArrow direction="right"/>}
      customLeftArrow={<CarouselArrow direction="left"/>}
        className="pt-3"
        responsive={responsive}
      >
        {
          (loading) ? 
          (
            arr.map(i => {
              return (
                <div key={i} className="flex justify-center content-center">
                  <SocoraCardSkeleton></SocoraCardSkeleton>
                </div>
              )
            })
          ): 
          (
            data.search.docs.map((content:any) => {
              return (
                <div key={content._id} className="flex justify-center content-center">
                  <SocoraCard
                    contentType={props.contentType}
                    content={content}
                  ></SocoraCard>
                </div>
              );
            })
          )
        }
        
      </Carousel>
    </div>
  );
}
