import { useEffect, useState } from "react";
import { useRateMutation } from "../util/apollo";
import * as LabService from "../api/LabService";
import * as CourseService from "../api/CourseService";
import * as Apollo from "@apollo/client";

const starsArray = [1, 2, 3, 4, 5];


const contentRateMap = {
  course: {
    getBaseOptions: () => {
      let baseOptions: Apollo.MutationHookOptions<
        CourseService.SaveUserCourseRateMutation,
        CourseService.SaveUserCourseRateMutationVariables
      > = {};
      return baseOptions;
    },
    getDocument: () => {
      return CourseService.SaveUserCourseRateDocument;
    },
    variables: (id: string, rate: number) => {
      return {
        input: {
          courseId: id,
          rate: rate
        }
      }
    }
  },
  path: {
    getBaseOptions: () => {
      let baseOptions: Apollo.MutationHookOptions<
        CourseService.SaveUserPathRateMutation,
        CourseService.SaveUserPathRateMutationVariables
      > = {};
      return baseOptions;
    },
    getDocument: () => {
      return CourseService.SaveUserPathRateDocument;
    },
    variables: (id: string, rate: number) => {
      return {
        input: {
          pathId: id,
          rate: rate
        }
      }
    }
  },
  lab: {
    getBaseOptions: () => {
      let baseOptions: Apollo.MutationHookOptions<
        LabService.SaveUserLabRateMutation,
        LabService.SaveUserLabRateMutationVariables
      > = {};
      return baseOptions;
    },
    getDocument: () => {
      return LabService.SaveUserLabRateDocument;
    },
    variables: (id: string, rate: number) => {
      return {
        input: {
          labId: id,
          rate: rate
        }
      }
    }
  },
};

export default function UserRating(props: { 
  contentId:string, 
  currentRating: any,
  rateChangeHandler: any,
  contentType: string
}) {
  

  const [selectedStar, setSelectedStar] = useState(() => {
    return props.currentRating ? props.currentRating : 0
  });
  

  const [rateHandler] = useRateMutation(
     // @ts-ignore
     contentRateMap[props.contentType].getBaseOptions(),
    // @ts-ignore
    contentRateMap[props.contentType].getDocument()
  );

  useEffect(() => {},[selectedStar])

  const handleUserRating = (star: any) => {
    setSelectedStar(star)
    props.rateChangeHandler(star);
    rateHandler({
      // @ts-ignore
      variables: contentRateMap[props.contentType].variables(
        props.contentId,
        star
      )
    })
  }

  return (
    <div className=" rating rating-sm mt-auto gap-1">
      {starsArray.map((star) => (
        <input
          key={star} 
          type="radio"
          name={`rating-${star}`}
          onClick={() => handleUserRating(star)}
          onChange={() => {}}
          checked={selectedStar === star }
          className={`mask mask-star bg-primary ${
            selectedStar < star ? "bg-opacity-20" : ""
          }`}
        />
      ))}
    </div>
  );
}
