import { string } from "yup";
import AuthService from "../../../api/AuthService";
import ErrorAlert from "../../../components/ErrorAlert";
import LabeledInput from "../../../components/LabeledInput";
import { useState } from "react";
import { Link, useFetcher } from "react-router-dom";
import * as QRCode from "qrcode";
import Alert from "../../../components/Alert";

function renderQR(secret: string) {
  var canvas = document.getElementById("qrmfa");
  QRCode.toCanvas(
    canvas,
    `otpauth://totp/Socora(user)?secret=${secret}`,
    function (error) {
      if (error) console.error(error);
    }
  );
}

export async function action(data: any) {
  let formData = await data.request.formData();

  let username = formData.get("username");
  let password = formData.get("password");
  let recoveryCode = formData.get("recoveryCode");

  const response = (await AuthService.recoverMFA(
    username,
    password,
    recoveryCode
  )) as any;

  if (response.hasError) {
    return {
      failed: true,
      message: response.error.response.data.message,
    };
  }

  renderQR(response.response.data.SecretCode);

  return {
    secretCode: response.response.data.SecretCode,
    message: response.response.data.message,
  };
}

export async function loader() {
  return {};
}

export default function RecoverMFA() {
  const fetcher = useFetcher();

  const [username, setUsername] = useState("adriatikraci");
  const [password, setPassword] = useState("adriatikraci");
  const [recoveryCode, setRecoveryCode] = useState();

  const fieldsAreValid = () => {
    return username && recoveryCode && password ? true : false;
  };

  return (
    <div>
      <div hidden={!fetcher?.data?.secretCode}>
        <div className="flex items-center justify-center flex-col gap-4">
          <Alert
            severity="alert-success"
            message={
              "Rescan the qr code with the authenticator app and proceed to login as usual"
            }
          ></Alert>
          <Alert
            severity="alert-warning"
            message={fetcher?.data?.message}
          ></Alert>
          <canvas className="rounded-2xl" id="qrmfa"></canvas>
          <div className="text-sm text-accent text-opacity-50 text-center">
            Go back to sign in{" "}
            <Link className="link link-info" to={"/auth/signin"}>
              Sign In
            </Link>
          </div>
        </div>
      </div>
      <div hidden={fetcher?.data?.secretCode}>
        <fetcher.Form
          hidden={fetcher?.data?.secretCode}
          method="post"
          className="flex flex-col gap-7"
        >
          {fetcher.state !== "submitting" && fetcher?.data?.failed ? (
            <ErrorAlert message={fetcher.data.message}></ErrorAlert>
          ) : (
            ""
          )}
          <LabeledInput
            value={username}
            type="text"
            name="username"
            top="Username"
            onInput={setUsername}
            yupSchema={string().min(1)}
          ></LabeledInput>
          <LabeledInput
            value={password}
            type="password"
            name="password"
            top="Password"
            onInput={setPassword}
            yupSchema={string().min(1)}
          ></LabeledInput>
          <LabeledInput
            value={recoveryCode}
            type="password"
            name="recoveryCode"
            top="Recovery Code"
            onInput={setRecoveryCode}
            yupSchema={string()
              .required()
              .test(
                "len",
                "Recovery Code must be exactly 32 digits",
                (val) => val.length === 32
              )}
          ></LabeledInput>

          {fetcher.state === "submitting" || fetcher.state === "loading" ? (
            <button
              type="submit"
              className={`btn btn-primary text-white uppercase`}
              disabled={true}
            >
              <span className="loading loading-spinner "></span>
              Recover MFA
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary text-white uppercase"
              disabled={!fieldsAreValid()}
            >
              Recover MFA
            </button>
          )}
        </fetcher.Form>
      </div>
    </div>
  );
}
