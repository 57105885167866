import { Link, useParams } from "react-router-dom";
import Split from "react-split";
import {
  LabMachineFlag,
  Machine,
  MachineHint,
  useGetLabMachineWalkthroughQuery,
  useGetLabWorkspaceDataQuery,
  useStartLabMutation,
  useSubmitLabMachineFlagMutation,
  useUnlockMachineHintMutation,
} from "../../../api/LabService";
import { useEffect, useState } from "react";
import CourseContentCollapseSkeleton from "../../../components/CourseContentCollapseSkeleton";
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon,
  ClockIcon,
  FlagIcon,
  LockClosedIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  PlusIcon,
  QueueListIcon,
  ServerIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { secondsToHoursAndMinutes } from "../../../util/convertors";
import LabeledInput from "../../../components/LabeledInput";
import { string } from "yup";
import Spinner from "../../../components/Spinner";
import ReactQuill from "react-quill";
import axios from "axios";
import { Gotty } from "./gotty/src/main";
import ErrorAlert from "../../../components/ErrorAlert";
import Exam from "../../../components/LabExam";
import { Question } from "../../../api/CourseService";
import { SocoraQuill } from "./socora-quill";
import Assistant from "../../../components/Assistant";
import LabNotes from "../../../components/LabNotes";

const tabs = [
  {
    label: "Machines",
  },
  {
    label: "Notes",
  },
  {
    label: "Assistant",
  },
];

function Hint(props: { hint: MachineHint; labId: string; machineId: string }) {
  const [hint, setHint] = useState(props.hint);

  const [unlockHintHandler, { data, loading, error }] =
    useUnlockMachineHintMutation({
      variables: {
        labId: props.labId,
        machineId: props.machineId,
      },
    });

  useEffect(() => {
    if (data) setHint(data?.unlockMachineHint as MachineHint);
    if (props.hint) setHint(props.hint);
  }, [loading]);

  return (
    <div className="collapse collapse-arrow bg-accent bg-opacity-5 rounded-xl">
      <input type="checkbox" />
      <div className="collapse-title text-sm font-medium">Hint</div>
      <div className="collapse-content  text-accent text-opacity-50 text-sm flex flex-col gap-2">
        {!hint ? (
          <>
            <LockClosedIcon className="h-4"></LockClosedIcon>
            <p>
              This hint is locked! By unlocking it, you will lose 1 star out of
              3 total stars per lab
            </p>
            <button
              disabled={loading}
              onClick={() => unlockHintHandler()}
              className={`btn btn-xs btn-primary text-white uppercase ${
                loading ? "loading" : ""
              }`}
            >
              Unlock Hint
            </button>
          </>
        ) : (
          <p>{hint?.hint}</p>
        )}
      </div>
    </div>
  );
}

function WalkthroughContent(props: { labId: string; machineId: string }) {
  const { data, loading, error } = useGetLabMachineWalkthroughQuery({
    variables: {
      labId: props.labId,
      machineId: props.machineId,
    },
  });

  useEffect(() => {
    let socoraQuill = new SocoraQuill();
  });

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner heigt="h-5"></Spinner>
      </div>
    );
  }

  return (
    <ReactQuill
      readOnly
      modules={{
        toolbar: false,
      }}
      defaultValue={data?.getLabMachineWalkthrough?.walkthrough as string}
    ></ReactQuill>
  );
}

function Walkthrough(props: { labId: string; machineId: string }) {
  const [unlock, setUnlock] = useState(false);

  return (
    <div className="collapse collapse-arrow bg-accent bg-opacity-5 rounded-xl">
      <input type="checkbox" />
      <div className="collapse-title text-sm font-medium">Walkthrough</div>
      <div className="collapse-content text-accent text-opacity-50 text-sm flex flex-col gap-2">
        {unlock ? (
          <WalkthroughContent
            labId={props.labId}
            machineId={props.machineId}
          ></WalkthroughContent>
        ) : (
          <>
            <LockClosedIcon className="h-4"></LockClosedIcon>
            <p>
              This Walkthrough is locked! By unlocking it, you will lose 2 stars
              out of 3 total stars per lab
            </p>
            <button
              onClick={() => setUnlock(true)}
              className="btn btn-xs btn-primary text-white uppercase"
            >
              Unlock Walkthrough
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function Flag(props: {
  labId: string;
  machineIndex: number;
  flag: { flagHash: string; machineAcquired: boolean; machineId: string };
  onFlagSubmit: any;
  onCorrectExam: any;
}) {
  const [flag, setFlag] = useState(props?.flag?.flagHash);
  const [submitFlagHandler, { data, loading, error }] =
    useSubmitLabMachineFlagMutation({
      variables: {
        submitLabMachineFlagInput: {
          flagHash: flag,
          labId: props.labId,
        },
      },
    });

  useEffect(() => {
    if (!props.flag && data) {
      props.onFlagSubmit(props.machineIndex, flag);
    }
  }, [data]);

  const collapseHandler = () => {
    if (props.flag && !data) {
      submitFlagHandler();
    }
  };

  return (
    <div className="collapse collapse-arrow bg-accent bg-opacity-5 rounded-xl">
      <input onClick={() => collapseHandler()} type="checkbox" />
      <div className="collapse-title text-sm font-medium">
        <div className="">
          {!props.flag ? <div>Flag</div> : <div>Exam</div>}
        </div>
      </div>
      <div className="collapse-content text-accent text-opacity-50 text-sm flex flex-col gap-2">
        {!props.flag ? (
          <FlagIcon className="h-4" />
        ) : (
          <QueueListIcon className="h-4" />
        )}
        {error ? <ErrorAlert message={error.message}></ErrorAlert> : ""}
        {!data && !props.flag ? (
          <>
            <div className="">
              <LabeledInput
                name="Flag"
                top="Please submit flag to take exam"
                type="text"
                onInput={(input: string) => setFlag(input)}
                yupSchema={string().required()}
              ></LabeledInput>
            </div>
            <button
              disabled={loading}
              onClick={() => submitFlagHandler()}
              className={`btn btn-xs btn-primary text-white uppercase ${
                loading ? "loading" : ""
              }`}
            >
              Submit Flag
            </button>
          </>
        ) : !data ? (
          <div className="flex items-center justify-center">
            <Spinner heigt="h-4"></Spinner>
          </div>
        ) : (
          <Exam
            onSuccess={() => props.onCorrectExam(props.machineIndex)}
            flagHash={flag}
            labId={props.labId}
            questions={data?.submitLabMachineFlag?.exam as Question[]}
          ></Exam>
        )}
      </div>
    </div>
  );
}

function LabMachines(props: {
  machines: Machine[];
  hints: MachineHint[];
  submittedFlags: LabMachineFlag[];
  machineIndex?: any;
}) {
  const [machines, setMachines] = useState([] as any);

  let { labId } = useParams();

  useEffect(() => {
    if (machines.length === 0) {
      const machinesAndFlags = props.machines.map((machine) => {
        return {
          ...machine,
          flag: props.submittedFlags.find((f) => f.machineId === machine._id),
        };
      });
      setMachines(machinesAndFlags);
    }
  }, [machines]);

  const flagSubmitHandler = (machineIndex: number, flag: string) => {
    const tempMachines = [...machines];
    tempMachines[machineIndex].flag = {
      machineId: tempMachines[machineIndex].machineId,
      machineAcquired: false,
      flagHash: flag,
    };
    setMachines(tempMachines);
  };

  const onCorrectExamHandler = (machineIndex: number) => {
    setTimeout(() => {
      const tempMachines = [...machines];
      tempMachines[machineIndex].flag.machineAcquired = true;
      setMachines(tempMachines);
    }, 100);
  };

  const machineRevertHandler = (machineIndex: number) => {
    const tempMachines = [...machines];
    const machine = tempMachines[machineIndex];
    tempMachines[machineIndex].reverting = true;
    setMachines(tempMachines);
    revertMachine(machine.imageTag);
    startPing(machineIndex, machine.alias);
  };

  const startPing = async (machineIndex: number, machineAlias: string) => {
    let interval = setInterval(async () => {
      try {
        let response = await axios(
          `${process.env.REACT_APP_LABS_URL}/${machineAlias}/`,
          {
            method: "GET",
          }
        );
        if (response.status !== 400) {
          clearInterval(interval);
          const tempMachines = [...machines];
          tempMachines[machineIndex].reverting = false;
          setMachines(tempMachines);
        } else {
          console.log("Lab not ready");
        }
      } catch (e) {}
    }, 1000);
  };

  return (
    <div className="flex flex-col gap-1">
      {machines
        .filter((machine: any) => machine.type !== "STUDENT")
        .map((machine: any, index: number) => (
          <div
            key={index}
            className="collapse collapse-arrow bg-base-300 rounded-xl"
          >
            <input
              defaultChecked={props.machineIndex == index}
              type="checkbox"
              className=""
            />

            <div className=" collapse-title p-4 flex items-center w-full ">
              <div>
                {machine.reverting ? (
                  <Spinner heigt="h-5"></Spinner>
                ) : (
                  <ServerIcon
                    className={`h-5 ${
                      machine?.flag
                        ? machine?.flag?.machineAcquired
                          ? "text-success"
                          : "text-warning"
                        : ""
                    }`}
                  />
                )}
              </div>
              <div className="pl-4  ">
                <div className="flex items-center gap-2">
                  <div className="text-md font-bold">{machine.description}</div>
                  {machine?.flag && !machine?.flag?.machineAcquired ? (
                    <div className="badge badge-warning badge-xs p-2">
                      Flag submitted
                    </div>
                  ) : (
                    ""
                  )}
                  {machine?.flag && machine?.flag?.machineAcquired ? (
                    <div className="badge badge-success badge-xs p-2">
                      Machine Finished
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex gap-1 text-sm items-center text-gray-500">
                  <div>
                    <ClockIcon className="h-4"></ClockIcon>
                  </div>
                  <div className="text-sm">
                    {machine.duration
                      ? (secondsToHoursAndMinutes(machine.duration) as string)
                      : "0s"}
                  </div>
                </div>
                {machine.reverting ? (
                  <div className="text-xs text-warning pt-1 ">
                    Machine is reverting...Please wait.
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className=" collapse-content  flex flex-col gap-2">
              <div className="flex gap-2 pt-2">
                <button
                  disabled={machine.reverting}
                  onClick={() => machineRevertHandler(index)}
                  className="btn btn-ghost btn-xs flex gap-2"
                >
                  <ArrowUturnLeftIcon className="h-4" />
                  <div>Revert</div>
                </button>
                <button className="btn btn-ghost btn-xs flex gap-2">
                  <ArrowTopRightOnSquareIcon className="h-4" />
                  <div>Open Web</div>
                </button>
              </div>
              {true ? (
                <>
                  <Hint
                    labId={labId as string}
                    machineId={machine._id as string}
                    hint={
                      props?.hints?.find(
                        (h) => h.machineId === machine._id
                      ) as MachineHint
                    }
                  ></Hint>
                  <Walkthrough
                    labId={labId as string}
                    machineId={machine._id as string}
                  ></Walkthrough>
                  <Flag
                    machineIndex={index}
                    onFlagSubmit={flagSubmitHandler}
                    flag={machine?.flag}
                    labId={labId as string}
                    onCorrectExam={onCorrectExamHandler}
                  ></Flag>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

function WorkspaceLoading() {
  return (
    <div className="flex h-screen ">
      <div className="w-[75%] ">
        <div className="h-full w-full bg-black"></div>
      </div>
      <div className="w-[25%] overflow-scroll">
        <div className=" prose-sm p-4">
          <div className="h-7 w-1/2 bg-white bg-opacity-5 rounded-2xl"></div>
        </div>
        <div className="tabs z-10">
          {tabs.map((tab) => (
            <div
              key={tab.label}
              onClick={() => ""}
              className={`tab tab-bordered grow`}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className="p-4">
          <CourseContentCollapseSkeleton></CourseContentCollapseSkeleton>
        </div>
      </div>
    </div>
  );
}

function Terminal(props: {
  id: string;
  isHidden: boolean;
  zoomOptions: {
    size: number;
    in: boolean;
  };
  alias: string;
}) {
  const [gotty, setGotty] = useState(undefined as any);
  const [zoom, setZoom] = useState(undefined as any);

  useEffect(() => {
    if (!gotty) {
      let gottyInstance = new Gotty(
        props.id,
        `${process.env.REACT_APP_LABS_URL?.replaceAll("https://", "")}/${
          props.alias
        }/ws`
      );
      gottyInstance.xterm.fitAddon.fit();
      setGotty(gottyInstance);

      var observer = new MutationObserver((mutations) => {
        mutations.forEach((mutationRecord) => {
          gottyInstance.xterm.fitAddon.fit();
        });
      });
      var target = document.getElementById("browser");
      observer.observe(target as any, {
        attributes: true,
        attributeFilter: ["style"],
      });
      setZoom(props.zoomOptions);
    } else {
      if (props.zoomOptions.size === 0) return;
      if (props.zoomOptions.size === zoom.size) return;
      setZoom(props.zoomOptions);
      const currentFontSize = gotty.xterm.term.options.fontSize;
      gotty.xterm.term.options.fontSize = props.zoomOptions.in
        ? currentFontSize + 1
        : currentFontSize - 1;
      gotty.xterm.fitAddon.fit();
    }
  }, [props.isHidden, props.zoomOptions]);

  return <div style={{ height: "calc(100vh - 6rem)" }} id={props.id}></div>;
}

interface TabType {
  label: string;
  alias: string;
  zoomOptions?: {
    in: false;
    size: 0;
  };
  loading?: boolean;
  machineType?: string;
}

function Browser(props: { machines: Machine[] }) {
  const [tabs, setTabs] = useState([] as TabType[]);

  useEffect(() => {
    if (tabs.length === 0) {
      let tempTabs = [] as TabType[];

      props.machines
        ?.filter((machine) => machine?.hidden !== true)
        .forEach((machine) => {
          tempTabs.push({
            label: machine.description as string,
            alias: machine.alias as string,
            machineType: machine.type as string,
            zoomOptions: {
              in: false,
              size: 0,
            },
          });
        });
      setTabs(tempTabs);
    }
  }, [props.machines]);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const removeTab = (tabIndex: number) => {
    let tempTabs = [...tabs];
    tempTabs.splice(tabIndex, 1);
    
    setTabs(tempTabs);

    if (
      (currentTabIndex === tabIndex && currentTabIndex !== 0) ||
      currentTabIndex > tabIndex
    ) {
      setCurrentTabIndex(currentTabIndex - 1);    
    }
  };

  const addTerminalTab = () => {
    let tempTabs = [...tabs];
    tempTabs.push({
      label: "Terminal",
      alias: `socora_terminal`,
      zoomOptions: {
        in: false,
        size: 0,
      },
    });
    setCurrentTabIndex(tempTabs.length - 1);
    setTabs(tempTabs);
  };

  const addMachineTab = (machine: any) => {
    let tempTabs = [...tabs];
    tempTabs.push({
      label: machine.description,
      alias: machine.alias,
      machineType: machine.type,
      zoomOptions: {
        in: false,
        size: 0,
      },
    });
    setCurrentTabIndex(tempTabs.length - 1);
    setTabs(tempTabs);
  };

  const zoomHandler = (inOut: string) => {
    let tempTabs = [...tabs];
    let currentTab = tempTabs[currentTabIndex];
    let zoom =
      inOut === "in"
        ? {
            in: true,
            size: currentTab.zoomOptions!.size + 1,
          }
        : {
            in: false,
            size: currentTab.zoomOptions!.size - 1,
          };
    tempTabs[currentTabIndex].zoomOptions = zoom as any;
    setTabs(tempTabs);
  };

  const openNewWindowHandler = (alias: string) => {
    window.open(
      `${process.env.REACT_APP_LABS_URL}/${alias}/`,
      "Socora Terminal",
      "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=800,height=400"
    );
  };
  const openNewTabHandler = () => {
    window.open(
      `${process.env.REACT_APP_LABS_URL}/${tabs[currentTabIndex].alias}/`,
      "_blank"
    );
  };

  const refreshHandler = () => {
    if (tabs[currentTabIndex].machineType === "STUDENT") return;
    let iframeId = `web_${tabs[currentTabIndex].alias}`;
    // @ts-ignore
    document.getElementById(iframeId).src += "";
    setLoading(currentTabIndex, true);
  };

  const setLoading = (tabIndex: number, loading: boolean) => {
    let tempTabs = [...tabs];
    tempTabs[tabIndex].loading = loading;
    setTabs(tempTabs);
  };

  if (tabs.length === 0) {
    return <h1>asd</h1>;
  }
  return (
    <div className="h-full ">
      <div className="">
        <div className="tabs tabs-lifted w-fit pt-2">
          <div className="tab"></div>
          {tabs?.map((tab, index) => (
            <div
              onClick={() => setCurrentTabIndex(index)}
              className={`flex items-center justify-center tab gap-2 pl-2 pr-2 pt-2 hover:bg-base-200 ${
                currentTabIndex === index
                  ? "tab-active [--tab-bg:oklch(var(--b2))] font-bold"
                  : ""
              }`}
            >
              <div className="text-xs">
                {tab?.label} #{index}
              </div>
              {tabs.length !== 1 ? (
                <div className="">
                  <XMarkIcon
                    onClick={() => removeTab(index)}
                    className="h-5 hover:bg-accent hover:bg-opacity-20 rounded-full p-1"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ))}

          <div className=" tab p-0  ">
            <div className="dropdown  ">
              <label tabIndex={0} className="btn btn-ghost btn-xs btn-circle">
                <PlusIcon className="h-4"></PlusIcon>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu-xs z-[1] menu p-2 shadow bg-base-200 rounded-box w-60"
              >
                {props.machines
                  ?.filter((machine) => machine?.hidden !== true)
                  .map((machine) => {
                    return (
                      <>
                        <li className="text-sm p-0">
                          <div
                            className="flex gap-2 p-2"
                            onClick={() => addMachineTab(machine)}
                          >
                            <PlusIcon height={20}></PlusIcon>
                            <div>New {machine?.description}</div>
                          </div>
                        </li>
                        <li className="text-sm p-0">
                          <div
                            className="flex gap-2 p-2"
                            onClick={() =>
                              openNewWindowHandler(machine?.alias as string)
                            }
                          >
                            <ArrowTopRightOnSquareIcon
                              height={20}
                            ></ArrowTopRightOnSquareIcon>
                            <div>New Window {machine?.description}</div>
                          </div>
                        </li>
                        <div className="divider p-0 m-0"></div>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className=" pl-2 pr-1 pb-2 pt-2 bg-base-200 rounded-tr-lg ">
          <div className="flex">
            <div className="bg-base-300 w-full rounded-3xl p-2 flex gap-2 items-center text-sm text-accent text-opacity-50">
              <button
                onClick={() => refreshHandler()}
                className="btn btn-circle btn-ghost btn-xs"
              >
                <ArrowPathIcon className="h-4"></ArrowPathIcon>
              </button>
              <div className="text-xs font-bold">
                https://labs.socora.com/{tabs[currentTabIndex]?.alias}/
              </div>

              <div className="flex ml-auto gap-2">
                {tabs[currentTabIndex]?.machineType === "STUDENT" ? (
                  <>
                    <button
                      id="zoom_in"
                      onClick={() => zoomHandler("in")}
                      className="btn btn-circle btn-ghost btn-xs"
                    >
                      <MagnifyingGlassPlusIcon className="h-4"></MagnifyingGlassPlusIcon>
                    </button>
                    <button
                      id="zoom_out"
                      onClick={() => zoomHandler("out")}
                      className="btn btn-circle btn-ghost btn-xs"
                    >
                      <MagnifyingGlassMinusIcon className="h-4"></MagnifyingGlassMinusIcon>
                    </button>
                  </>
                ) : (
                  ""
                )}
                <button
                  onClick={() => openNewTabHandler()}
                  className="btn btn-circle btn-ghost btn-xs"
                >
                  <ArrowTopRightOnSquareIcon className="h-4"></ArrowTopRightOnSquareIcon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {tabs.map((tab, index) => {
        return (
          <div hidden={currentTabIndex !== index}>
            {tab.machineType === "STUDENT" ? (
              <Terminal
                zoomOptions={tab.zoomOptions as any}
                isHidden={currentTabIndex !== index}
                id={`default_terminal_${index}`}
                alias={tab.alias}
              ></Terminal>
            ) : (
              <>
                <iframe
                  hidden={tab?.loading}
                  onLoad={() => setLoading(index, false)}
                  id={`web_${tab.alias}`}
                  className="w-full"
                  style={{ height: "calc(100vh - 6rem)" }}
                  src={`${process.env.REACT_APP_LABS_URL}/${tab.alias}/`}
                ></iframe>
                {tab.loading ? (
                  <div
                    style={{ height: "calc(100vh - 6rem)" }}
                    className="flex items-center justify-center"
                  >
                    <Spinner heigt="h-7"></Spinner>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

function revertMachine(imageTag: string) {
  return axios(`${process.env.REACT_APP_LABS_URL}/revert/${imageTag}`, {
    method: "POST",
  });
}

export default function LabWorkspace() {
  let { labId } = useParams();

  let [labStarted, setLabStarted] = useState(false);

  const { data, loading } = useGetLabWorkspaceDataQuery({
    variables: {
      id: labId as string,
    },
  });

  const [startLabMutation] = useStartLabMutation({
    variables: {
      labId: labId as string,
    },
  });

  const [labState, setLabState] = useState("UNKNOWN");

  const [currentTab, setCurrentTab] = useState(0);
  const [newNoteContent, setNewNoteContent] = useState("");
  let { machineIndex } = useParams();

  const pingMachine = async () => {
    let interval = setInterval(async () => {
      try {
        let response = await axios(
          `${process.env.REACT_APP_LABS_URL}/${
            data?.getLabById?.machines?.filter(
              (machine) => machine?.type === "STUDENT"
            )[0]?.alias
          }/`,
          {
            method: "GET",
          }
        );
        if (response.status !== 400) {
          clearInterval(interval);
          setLabStarted(true);
        } else {
          console.log("Lab not ready");
        }
      } catch (e) {}
    }, 1000);
  };

  useEffect(() => {
    if (!loading && labState === "UNKNOWN") {
      if (data?.getUserLabStateByLabId?.state === "RUNNING") {
        pingMachine();
      } else {
        const ws = new WebSocket(process.env.REACT_APP_WSS_URL as string);

        ws.onmessage = (message) => {
          let messageObj = JSON.parse(message.data);

          if (messageObj.labId !== labId) return;
          if (messageObj.state === "RUNNING") pingMachine();

          setLabState(messageObj.state);
        };
      }

      startLabMutation().then((response) => {});
    }
  }, [loading, labState]);

  if (!labStarted) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="flex flex-col gap-2 ">
          <div className="text-lg font-semibold text-center text-accent text-opacity-50">
            <a hidden={labState === "RUNNING"}> Starting lab...</a>
            <a hidden={labState !== "RUNNING"}>Getting there. Please Wait...</a>
          </div>
          <progress className="progress w-[30vw] bg-base-100 progress-primary"></progress>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen">
      <Split
        className="h-full flex"
        sizes={[70, 30]}
        minSize={100}
        expandToMin={false}
        gutterSize={5}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
      >
        <div className="" id="browser">
          <Browser machines={data?.getLabById?.machines as Machine[]}></Browser>
        </div>

        <div className="h-full flex flex-col">
          <div className=" p-5 h-full">
            <div className="flex flex-col gap-2">
              <div className=" flex items-center pb-5 gap-1 text-sm font-bold  ">
                <Link
                  className="hover:underline hover:text-primary hover:opacity-100 opacity-50"
                  to={"/labs"}
                >
                  <a>Labs</a>
                </Link>
                <span className="opacity-50">/</span>
                <span className="opacity-50">{data?.getLabById?.name}</span>
              </div>
            </div>
            <div className="h-[4%] ">
              <div className="tabs tabs-xs h-full tabs-boxed bg-base-300 rounded-br-none rounded-bl-none ">
                {tabs.map((tab, index) => (
                  <div
                    key={tab.label}
                    id={`tab_${index}`}
                    onClick={() => setCurrentTab(index)}
                    className={`tab transition-all  tab-bordered grow h-full   ${
                      currentTab === index ? "text-white bg-primary" : ""
                    } `}
                  >
                    {tab.label}
                    {index === 3 ? (
                      <span
                        className={`ml-2 rounded-full h-2 w-2 bg-primary opacity-75 ${
                          currentTab !== 3 ? "animate-ping" : ""
                        }`}
                      ></span>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-base-200 h-[92%] overflow-scroll p-5  rounded-b-lg shadow-xl  ">
              <div hidden={currentTab !== 0}>
                <LabMachines
                  submittedFlags={
                    data?.getLabMachineFlagState as LabMachineFlag[]
                  }
                  hints={
                    data?.getUnlockedMachineHints
                      ?.unlockedHints as MachineHint[]
                  }
                  machines={
                    data?.getLabById?.machines?.filter(
                      (machine) => machine?.type !== "STUDENT"
                    ) as Machine[]
                  }
                  machineIndex={machineIndex}
                ></LabMachines>
              </div>
              <div className="h-full" hidden={currentTab !== 1}>
                <LabNotes
                  labId={labId as string}
                  newNoteContent={newNoteContent}
                  onFinish={() => {
                    setNewNoteContent("");
                  }}
                ></LabNotes>
              </div>
              <div className="h-full" hidden={currentTab !== 2}>
                <Assistant
                  saveAsNote={(content: string) => {
                    setNewNoteContent(content);
                    setCurrentTab(1);
                  }}
                ></Assistant>
              </div>
            </div>
          </div>
        </div>
      </Split>
    </div>
  );
}
