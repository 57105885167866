import { TagIcon } from "@heroicons/react/24/outline";

export default function HomeCardSkeleton() {
  return (
    <div className="card bg-transparent w-full h-[200px] ">
      <div className="card-body">
        <div className="bg-white bg-opacity-5 h-8 w-2/3 rounded-2xl"></div>
        <div className="flex w-full gap-2">
          <div className="bg-white bg-opacity-5 h-4 w-10 rounded-2xl"></div>
          <div className="bg-white bg-opacity-5 h-4 w-10 rounded-2xl"></div>
          <div className="bg-white bg-opacity-5 h-4 w-10 rounded-2xl"></div>
        </div>
        <div className="bg-white bg-opacity-5 h-2 w-2/3 rounded-2xl"></div>
        <div className="bg-white bg-opacity-5 h-2 w-2/3 rounded-2xl"></div>
        <div className="bg-white bg-opacity-5 h-2 w-2/3 rounded-2xl"></div>
        <div className="bg-white bg-opacity-5 h-2 w-2/3 rounded-2xl"></div>
      </div>
    </div>
  )
}
