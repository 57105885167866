import { ClockIcon, ServerIcon } from "@heroicons/react/24/outline";
import { secondsToHoursAndMinutes } from "../util/convertors";
import { Machine } from "../api/LabService";

export default function LabMachines(props: { machines: Machine[], machineClickHandler?: any;}) {  
    
  const machineClickHandler = (machineIndex: number) => {
    props.machineClickHandler(machineIndex);
  };

  return (
    <div className="flex flex-col gap-1">
      {props.machines.map((machine , index) => (
        <div className="collapse bg-base-200 hover:bg-base-300 rounded-xl"  
        onClick={() => machineClickHandler(index)
        }>
          <div className="collapse-title p-4 flex items-center w-full ">
            <div>
              <ServerIcon className="h-5" />
            </div>
            <div className="pl-4  ">
              <div className="text-lg">{machine.description}</div>
              <div className="flex gap-1 text-sm items-center text-gray-500">
                <div>
                  <ClockIcon className="h-4"></ClockIcon>
                </div>
                <div className="text-sm">
                  {
                    //@ts-ignore
                    machine.duration ? (secondsToHoursAndMinutes(machine.duration) as string)
                      : "0s"
                  }
                </div>
              </div>
            </div>
            <div className="ml-auto text-sm flex">
              <div className="flex gap-1 "></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
