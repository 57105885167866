export default function Badge(props: {
  id?: string;
  name?: string;
  description?: string;
  focused?: boolean;
}) {
  return (
    <div className={`card h-full card-side shadow-none border border-base-300 hover:bg-base-300 cursor-pointer ${props.focused ? 'scale-150' : ''}`} >
      <figure className="w-40 p-4">
        <img
          src={`https://static.socora.com/img/badge/${props.id}.png`}
          alt="Badge"
        />
      </figure>
      <div className="card-body p-4 pl-0 w-[20rem]">
        <h2 className="sm:block hidden text-sm stat-value">{props.name}</h2>
        <h2 className="sm:hidden block text-sm">{props.name}</h2>
        <p className="text-xs text-accent text-opacity-40">
          {props.description}
        </p>
      </div>
    </div>
  );
}
