import * as CourseService from "../api/CourseService";
import { useState } from "react";
import * as Apollo from "@apollo/client";
import {
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
} from "../util/apollo";
import * as LabService from "../api/LabService";
import { BookmarkIcon } from "@heroicons/react/24/outline";

const contentCreateBookmarkMap = {
  course: {
    getBaseOptions: (id: string) => {
      let baseOptions: Apollo.MutationHookOptions<
        CourseService.CreateCourseBookmarkMutation,
        CourseService.CreateCourseBookmarkMutationVariables
      > = {
        variables: {
          input: {
            courseId: id,
          },
        },
      };
      return baseOptions;
    },
    getDocument: () => {
      return CourseService.CreateCourseBookmarkDocument;
    },
  },
  path: {
    getBaseOptions: (id: string) => {
      let baseOptions: Apollo.MutationHookOptions<
        CourseService.CreatePathBookmarkMutation,
        CourseService.CreatePathBookmarkMutationVariables
      > = {
        variables: {
          input: {
            pathId: id,
          },
        },
      };
      return baseOptions;
    },
    getDocument: () => {
      return CourseService.CreatePathBookmarkDocument;
    },
  },
  lab: {
    getBaseOptions: (id: string) => {
      let baseOptions: Apollo.MutationHookOptions<
        LabService.BookmarkLabMutation,
        LabService.BookmarkLabMutationVariables
      > = {
        variables: {
          input: {
            labId: id,
          },
        },
      };
      return baseOptions;
    },
    getDocument: () => {
      return LabService.BookmarkLabDocument;
    },
  },
};

const contentDeleteBookmarkMap = {
  course: {
    getBaseOptions: (id: string) => {
      let baseOptions: Apollo.MutationHookOptions<
        CourseService.DeleteCourseBookmarkMutation,
        CourseService.DeleteCourseBookmarkMutationVariables
      > = {
        variables: {
          input: {
            id: id,
          },
        },
      };
      return baseOptions;
    },
    getDocument: () => {
      return CourseService.DeleteCourseBookmarkDocument;
    },
  },
  path: {
    getBaseOptions: (id: string) => {
      let baseOptions: Apollo.MutationHookOptions<
        CourseService.DeletePathBookmarkMutation,
        CourseService.DeletePathBookmarkMutationVariables
      > = {
        variables: {
          input: {
            id: id,
          },
        },
      };
      return baseOptions;
    },
    getDocument: () => {
      return CourseService.DeletePathBookmarkDocument;
    },
  },
  lab: {
    getBaseOptions: (id: string) => {
      let baseOptions: Apollo.MutationHookOptions<
        LabService.DeleteLabBookmarkMutation,
        LabService.DeleteLabBookmarkMutationVariables
      > = {
        variables: {
          input: {
            labId: id,
          },
        },
      };
      return baseOptions;
    },
    getDocument: () => {
      return LabService.DeleteLabBookmarkDocument;
    },
  },
};

export default function Bookmark(props: {
  contentId: string,
  contentType: string,
  iconHeightClass: string,
  isBookmarked: boolean
}) {
  const [createBookmarkHandler] = useCreateBookmarkMutation(
    // @ts-ignore
    contentCreateBookmarkMap[props.contentType].getBaseOptions(
      props.contentId
    ),
    // @ts-ignore
    contentCreateBookmarkMap[props.contentType].getDocument()
  );
  const [deleteBookmarkHandler] = useDeleteBookmarkMutation(
    // @ts-ignore
    contentDeleteBookmarkMap[props.contentType].getBaseOptions(
      props.contentId
    ),
    // @ts-ignore
    contentDeleteBookmarkMap[props.contentType].getDocument()
  );

  const [isBookmarked, setIsBookmarked] = useState(
    () =>
      //@ts-ignore
      props.isBookmarked
  );

  const bookmarkClickHandler = (event:any) => {
    event.preventDefault();
    event.stopPropagation();
    if (isBookmarked) {
      deleteBookmarkHandler();
      setIsBookmarked(false);
    } else {
      createBookmarkHandler();
      setIsBookmarked(true);
    }
  };

  return (
    <BookmarkIcon
      onClick={bookmarkClickHandler}
      className={`${props.iconHeightClass} cursor-pointer ${
        isBookmarked ? "fill-accent text-accent" : "hover:text-primary fill-transparent"
      }`}
      fill=""
    ></BookmarkIcon>
  );
}
