import { useState } from "react";
import { Link, redirect, useFetcher } from "react-router-dom";
import LabeledInput from "../../../components/LabeledInput";
import { string } from "yup";
import AuthService from "../../../api/AuthService";
import ErrorAlert from "../../../components/ErrorAlert";

export async function action(data: any) {
  let formData = await data.request.formData();
  let username = formData.get("username");

  if (formData.get("isReset")) {
    const response = (await AuthService.resetPassword(
      formData.get("code"),
      username,
      formData.get("password")
    )) as any;
    if (response.hasError) {
      return {
        authFailed: true,
        errors: response.error.response.data.message,
      };
    } else {
      return redirect("/auth/signin");
    }
  }

  const response = (await AuthService.requestResetPassword(username)) as any;
  if (response.hasError) {
    return {
      authFailed: true,
      errors: response.error.response.data.message,
    };
  } else {
    return {
      submitted: true,
    };
  }
}

export default function ResetPassword() {
  const fetcher = useFetcher();

  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const fieldsAreValid = () => {
    if (!fetcher.data) {
      return username ? true : false;
    } else {
      return code && password && confirmPassword ? true : false;
    }
  };

  return (
    <fetcher.Form method="post" className="flex flex-col gap-4">
      {fetcher?.data?.authFailed ? (
        <ErrorAlert message={fetcher?.data?.errors}></ErrorAlert>
      ) : (
        ""
      )}
      {fetcher?.data?.submitted ? (
        <>
          <p>
            Please check your email inbox for the confimration code to reset
            your password. If you don't see the email, remember to check your
            spam folder.
          </p>
          <LabeledInput
            name="code"
            type="text"
            top="Code"
            onInput={setCode}
            yupSchema={string().min(2).required()}
          />
          <LabeledInput
            name="username"
            type="text"
            top="Username"
            onInput={setUsername}
            yupSchema={string().min(2).required()}
          />
          <LabeledInput
            name="password"
            type="password"
            top="Password"
            onInput={setPassword}
            yupSchema={string().min(2).required()}
          />
          <LabeledInput
            name="confirmPassword"
            type="password"
            top="Confirm Password"
            onInput={setConfirmPassword}
            yupSchema={string().equals([password], "Passwords do not match")}
          />
          <input hidden name="isReset" defaultValue={"true"}></input>
        </>
      ) : (
        <LabeledInput
          name="username"
          type="text"
          top="Username"
          onInput={setUsername}
          yupSchema={string().min(2).required()}
        />
      )}

      {fetcher.state === "submitting" || fetcher.state === "loading" ? (
        <button
          type="submit"
          className={`btn btn-primary loading text-white`}
          disabled={true}
        >
          Reset
        </button>
      ) : (
        <button
          type="submit"
          className="btn btn-primary text-white"
          disabled={!fieldsAreValid()}
        >
          Reset
        </button>
      )}

      <div className="text-sm text-center text-opacity-50">
        Already have an an account?{" "}
        <Link className="link link-info" to={"/auth/signin"}>
          Sign In
        </Link>
      </div>
      <div className="text-sm text-center text-opacity-50">
        You dont have an account?{" "}
        <Link className="link link-info" to={"/auth/signup"}>
          Sign Up
        </Link>
      </div>
    </fetcher.Form>
  );
}
