import { useEffect, useState } from "react";
import { Note as NoteType } from "../api/CourseService";
import {
  ArrowDownTrayIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import "../pages/labs/workspace/socora-quill.css";
import ReactQuill from "react-quill";
import {
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useLabNotesLazyQuery,
  useLabNotesQuery,
  useUpdateNoteMutation,
} from "../api/LabService";
import { SaveIcon } from "./CourseNotes";

function Note(props: {
  labId: string;
  note: NoteType;
  noteIndex: number;
  deleteHandler: any;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [noteContent, setNoteContent] = useState(props.note.content as string);

  const [updateMutation] = useUpdateNoteMutation({
    variables: {
      input: {
        content: noteContent,
        labId: props.labId,
        noteId: props.note.id,
      },
    },
  });

  const [deleteMutation] = useDeleteNoteMutation({
    variables: {
      input: {
        labId: props.labId,
        noteId: props.note.id,
      },
    },
  });

  useEffect(() => {
    const noteElement = document.getElementById("note_" + props.note.id);
    setTimeout(() => {
      noteElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 500);
  }, []);

  const onSave = () => {
    updateMutation();
    setIsEditing(false);
  };

  const onCancel = () => {
    setNoteContent(props.note.content as string);
    setIsEditing(false);
  };

  const onDelete = () => {
    deleteMutation();
    props.deleteHandler(props.noteIndex);
  };

  const seek = () => {
    let video = document.getElementById("player") as any;
    video.currentTime = props.note.videoTime;
  };

  return (
    <div
      id={"note_" + props.note.id}
      className="bg-accent bg-opacity-5 flex flex-col rounded-2xl p-2"
    >
      <div hidden={!isEditing}>
        <ReactQuill
          id="editable"
          modules={{
            syntax: true,
            toolbar: [
              [
                { header: [1, 2, 3, 4, 5, 6, false] },
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "code-block",
                { list: "ordered" },
                { list: "bullet" },
                { script: "sub" },
                { script: "super" },
                { color: [] },
                { background: [] },
                { align: [] },
              ],
            ],
          }}
          value={noteContent}
          onChange={setNoteContent}
        ></ReactQuill>
      </div>
      <div hidden={isEditing}>
        <ReactQuill
          id="readonly"
          modules={{
            toolbar: false,
            syntax: true,
          }}
          readOnly={true}
          value={noteContent}
        ></ReactQuill>
      </div>

      <div className="flex">
        {isEditing ? (
          <div className="flex w-full">
            <button
              onClick={onCancel}
              className="btn btn-circle btn-sm btn-ghost"
            >
              <XMarkIcon className="h-5"></XMarkIcon>
            </button>
            <button
              onClick={onSave}
              className="btn btn-circle btn-sm btn-ghost"
            >
              <SaveIcon />
            </button>
            <button
              onClick={onDelete}
              className=" ml-auto btn btn-circle btn-sm btn-ghost"
            >
              <TrashIcon className="h-5 text-red-500" />
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="btn btn-circle btn-sm btn-ghost"
          >
            <PencilIcon className="h-5"></PencilIcon>
          </button>
        )}
      </div>
    </div>
  );
}

export default function LabNotes(props: {
  labId: string;
  newNoteContent?: string;
  onFinish: any
}) {
  const [newNoteContent, setNewNoteContent] = useState("");
  const [notes, setNotes] = useState([] as NoteType[]);
  const [createNoteHandler] = useCreateNoteMutation();
  const [showNewNoteEditor, setShowNewNoteEditor] = useState(false);
  const [getLabNotes, { called, loading, data }] = useLabNotesLazyQuery({
    variables: {
      id: props.labId,
    },
  });

  // @ts-ignore
  hljs.configure({
    // optionally configure hljs
    languages: ["javascript", "ruby", "python"],
  });

  useEffect(() => {
    if (!called) {
      getLabNotes();
    }
    if (!loading) {
      setNotes(data?.labNotes?.notes as any);
    }
  }, [loading]);


  useEffect(() => {
    setNewNoteContent(props.newNoteContent as string)
    console.log('NEW NOTE');
    
  }, [props.newNoteContent]);

  const deleteHandler = (noteIndex: number) => {
    let tempNotes = [...notes];
    tempNotes.splice(noteIndex, 1);
    setNotes(tempNotes);
  };

  const createHandler = async () => {
    const res = await createNoteHandler({
      variables: {
        input: {
          content: newNoteContent,
          labId: props.labId,
        },
      },
    });

    let tempNotes = [] as any;

    if (notes) {
      tempNotes = [...notes];
    }

    tempNotes.push(res.data?.createNote as NoteType);

    setNewNoteContent("");
    setNotes(tempNotes);
    setShowNewNoteEditor(false);
    props.onFinish();

  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow overflow-auto ">
        <div className="flex flex-col gap-2">
          {notes?.map((note, index: number) => {
            return (
              <Note
                key={note.id}
                noteIndex={index}
                deleteHandler={deleteHandler}
                labId={props?.labId as string}
                note={note}
              ></Note>
            );
          })}
        </div>
      </div>
      <div className="pt-5 rounded">
        {showNewNoteEditor || newNoteContent ? (
          <>
            <div className="bg-base-300 rounded-xl mb-2">
              <ReactQuill
                className="p-4"
                value={newNoteContent}
                modules={{
                  syntax: true,
                  toolbar: [
                    { header: [1, 2, 3, 4, 5, 6, false] },
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "code-block",
                    { list: "ordered" },
                    { list: "bullet" },
                    { script: "sub" },
                    { script: "super" },
                    { color: [] },
                    { background: [] },
                    { align: [] },
                  ],
                }}
                onChange={setNewNoteContent}
              ></ReactQuill>
              <div className="flex  gap-3 pl-2 pr-2 pb-2 ">
                <div className="w-full">
                  <button
                    onClick={createHandler}
                    className="btn btn-primary w-full btn-xs text-white"
                  >
                    SAVE
                  </button>
                </div>

                <div className="w-full">
                  <button
                    onClick={() => {
                      setShowNewNoteEditor(false);
                      setNewNoteContent("");
                      props.onFinish();
                    }}
                    className="btn  w-full   btn-xs"
                  >
                    DISCARD
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <button
            onClick={() => setShowNewNoteEditor(true)}
            className="btn btn-primary w-full btn-xs text-white"
          >
            CREATE
          </button>
        )}
      </div>
    </div>
  );
}
