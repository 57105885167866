import { matchPath } from "react-router-dom";
import { routes } from "..";

export function secondsToHoursAndMinutes(s: number): string {
  if (s === null) return "0";
  s = Number(s);
  var h = Math.floor(s / 3600);
  var m = Math.floor(s % 3600 / 60);
  s = Math.floor(s % 3600 % 60);
  var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  if (hDisplay !== "" || mDisplay !== "") {
    return hDisplay + mDisplay
  } else {
    return sDisplay;
  }
}


export function calculateStars(rating: {
  star1: number,
  star2: number,
  star3: number,
  star4: number,
  star5: number
}) {
  if (rating) {
    let rt = (5 * rating.star5 + 4 * rating.star4 + 3 * rating.star3 + 2 * rating.star2 + 1 * rating.star1)
      / (rating.star5 + rating.star4 + rating.star3 + rating.star2 + rating.star1);
    if (!isNaN(rt))
      return rt;
    else
      return 0;
  }
  return 0;

}

export function calculateProgress(finishedUnits: number, totalUnits: number) {
  if(finishedUnits && totalUnits) return (finishedUnits / totalUnits) * 100
  return 0;
}

export function arrayToMap(data: any[], key: string) {
  return data?.reduce(function (map: any, obj: any) {
    // @ts-ignore
    map[obj[key]] = obj;
    return map;
  }, {});
}


export function matchToPaths(pathToMatch: string) {

  let paths = routes[0].children?.map((route:any) => route.path)
  paths?.push(routes[1].path)

  for (const path of paths!) {
    let matched = matchPath(path, pathToMatch);
    if(matched !== null) {
      return matched
    }  
  }

  return null;

}