export default function SocoraCardSkeleton() {
  return (
    <div className="card card-compact w-[19rem] h-[16.2rem] bg-transparent space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
     
      <div className="card-body w-full" style={{ padding: "0.5rem" }}>
        <div className="skeleton rounded-2xl h-[161px] w-full">

        </div>
        <div className="card-title text-base pt-1" style={{ marginBottom: "0px" }}>
          <div className="h-4 skeleton rounded-full w-2/3"></div>
        </div>
        <div className="flex gap-4">
          <div className="h-4 skeleton rounded-full w-10"></div>
          <div className="h-4 skeleton rounded-full w-10"></div>
        </div>
      </div>
    </div>

  )
}