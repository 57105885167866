import { TagIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Course, CourseSearch } from "../api/CourseService";
import { secondsToHoursAndMinutes } from "../util/convertors";
import { isNewContent } from "../util/misc";
import NewContentBadge from "./NewContentBadge";
import { Link } from "react-router-dom";


export default function HomeCard(props: {
  course: CourseSearch,
}) {
  return (
    <div className="card bg-transparent w-full">
      <div className="card-body">
        <h2 className="card-title text-2xl">
          {props.course.name}
          {
            isNewContent(props.course.info?.releaseDate as number) ? <NewContentBadge />: ''
          }
        </h2>

        <div className="flex gap-4">
          <div>
            <div className="badge  gap-2 border-0 p-0 bg-transparent">
              <ClockIcon className="h-4"></ClockIcon>
              {secondsToHoursAndMinutes(props.course.info?.duration as number)}
            </div>
          </div>

          {
            props.course?.tags?.map(tag => (
              <div key={tag?._id} className="badge  gap-2 border-0 p-0 bg-transparent">
                <TagIcon className="h-4"></TagIcon>
                {tag?.value}
              </div>
            ))
          }
          
        </div>

        <p className="text-sm text-left xl:w-2/3 lg:w-2/3 md:w-full sm:w-full">
          {
            props.course.info?.description?.about?.substring(0, 200)
          }...
          <Link to={`/courses/${props.course?._id}`} className="link text-blue-500">Learn more</Link>
        </p>
      </div>
    </div>
  );
}
