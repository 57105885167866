import { redirect } from "react-router-dom";
import AuthService from "./api/AuthService";

export async function loader(data: any) {
  let response = (await AuthService.getUser()) as any;
  if (response.hasError) {
    return redirect(
      "/auth/signin?returnURL=" + new URL(data.request.url).pathname
    );
  } else {
    return response.data;
  }
}