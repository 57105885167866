import { useEffect, useRef, useState } from "react";
import {
  LockClosedIcon,
  PlayCircleIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import Bookmark from "./Bookmark";
import CourseContentCollapse from "./CourseContentCollapse";
import ContentAboutInfo from "./ContentAboutInfo";
import useOnScreen from "./useOnScreen";
import { calculateProgress } from "../util/convertors";
import LabMachines from "./LabMachines";
import { Subsection, Tag } from "../api/CourseService";
import PathContent from "./PathContent";
import List from "./List";
import { useGQLSearch } from "../api/SearchService";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { PaymentService } from "../api/PaymentService";
import { isNewContent } from "../util/misc";
import NewContentBadge from "./NewContentBadge";
import Badge from "./Badge";
import BadgeDialog from "./BadgeDialog";

function ContentButtons(props: {
  isLocked: boolean;
  hasSubscription?: boolean;
  contentType: string;
  contentId: string;
  isBookmarked: boolean;
}) {
  const navigate = useNavigate();

  const openOrNavigate = () => {
    const url = `/${props.contentType}s/${props.contentId}/${
      props.contentType === "course" ? "player" : "workspace"
    }`;

    if (window.innerWidth > 1280) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <div className=" flex items-center gap-2 ">
        {!props.isLocked ? (
          <PlayCircleIcon
            onClick={openOrNavigate}
            className="sm:h-10 h-7 hover:text-primary cursor-pointer"
          ></PlayCircleIcon>
        ) : (
          <LockClosedIcon className="sm:h-10 h-7"></LockClosedIcon>
        )}

        <Bookmark
          contentType={props.contentType}
          contentId={props.contentId as string}
          isBookmarked={props.isBookmarked}
          iconHeightClass="sm:h-9 h-6"
        />
      </div>
      {props.isLocked ? (
        <div className="text-xs text-red-500">
          Access Denied: Resource limitation on your{" "}
          <Link
            to={`/subscribe${props.hasSubscription ? "/change-plan" : ""}`}
            className="link font-bold"
          >
            Subscription Plan
          </Link>
          .
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
function ContentAboutTitle(props: {
  title: string;
  isOnScreenHandler: any;
  releaseDate: number;
}) {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  useEffect(() => {
    props.isOnScreenHandler(isOnScreen);
  }, [isOnScreen]);

  return (
    <div className="flex gap-4">
      <h1 className="sm:text-3xl text-xl" ref={elementRef}>
        {props.title}
      </h1>
      {isNewContent(props.releaseDate) ? <NewContentBadge /> : ""}
    </div>
  );
}

function ContentAboutTabs(props: { about: string; objectives: string }) {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className="flex flex-col gap-4">
      <div role="tablist" className="tabs tabs-bordered w-fit">
        <div
          onClick={() => {
            setCurrentTab(0);
          }}
          className={`tab ${currentTab === 0 ? "tab-active" : ""}`}
        >
          About
        </div>
        <div
          onClick={() => {
            setCurrentTab(1);
          }}
          className={`tab ${currentTab === 1 ? "tab-active" : ""}`}
        >
          Objectives
        </div>
      </div>
      {currentTab === 0 ? (
        <p className="text-sm text-accent text-opacity-50">{props.about}</p>
      ) : (
        <p className="text-sm text-accent text-opacity-50">
          {props.objectives}
        </p>
      )}
    </div>
  );
}

function ContentAboutTags(props: { tags: Tag[] }) {
  return (
    <div className="flex flex-wrap gap-4">
      {props.tags.map((tag) => {
        return (
          <div
            key={tag._id}
            className="badge gap-2 border-0 text-xs text-opacity-50 p-0 bg-transparent "
          >
            <div>
              <TagIcon className="h-3 w-3"></TagIcon>
            </div>
            <div>{tag.value}</div>
          </div>
        );
      })}
    </div>
  );
}

function RelatedContent(props: { contentType: string; tags: string[] }) {
  const { data, loading } = useGQLSearch(props.contentType, {
    pagination: {
      limit: 20,
      page: 1,
    },
    searchString: "",
    filter: {
      tags: props.tags,
    },
  });

  return (
    <List
      loading={loading}
      lazyLoading={false}
      lazyLoadingCount={20}
      contentType={props.contentType}
      content={data?.search?.docs}
    ></List>
  );
}

export default function ContentAbout(props: {
  contentType: string;
  contentData: any;
  userRate: any;
  state: any;
  bookmarks?: any;
}) {
  const relatedContentRef = useRef<HTMLDivElement>(null);
  const relatedContentIsOnScreen = useOnScreen(relatedContentRef, true);
  const navigate = useNavigate();
  const { userRole } = useOutletContext<{ userRole: any }>();

  const [isOnScreen, setIsOnScreen] = useState(true);

  const isOnScreenHandler = (isOnScreen: boolean) => {
    setIsOnScreen(isOnScreen);
  };
  const [userSubscription, setUserSubscription] = useState(undefined as any);
  const [clickedBadge, setClickedBadge] = useState(undefined as any);

  useEffect(() => {
    if (userRole === "INDIVIDUAL") {
      PaymentService.getSubscription().then((subscription) => {
        if (subscription.hasError) {
          setUserSubscription({ currentPlan: { index: 0 } });
        } else {
          setUserSubscription(subscription.data);
        }
      });
    }
  }, [userRole]);

  return (
    <div>
      <BadgeDialog
        onClose={() => setClickedBadge(undefined)}
        open={clickedBadge}
        badge={clickedBadge}
        isFinish={false}
      ></BadgeDialog>
      <div className="h-[20rem] ">
        <div className="h-full w-full relative ">
          <img
            alt=""
            className="h-full w-full object-cover "
            src={`https://static.socora.com/img/${props.contentType}/large/${props.contentData._id}.jpg`}
          ></img>
          <div className="absolute h-full w-full top-0 bg-gradient-to-b from-transparent to-base-100"></div>
        </div>
      </div>
      <div className="flex w-full gap-4 sm:pl-10 pl-5 sm:pr-10 pr-5 ">
        {/* content */}
        <div className="xl:w-3/4 w-[100%] flex flex-col gap-4">
          <div
            className="xl:static sticky flex flex-col top-[4rem] 
          xl:z-0 z-30 xl:gap-4 gap-2 bg-base-100 pt-4"
          >
            <ContentButtons
              isLocked={
                userSubscription &&
                userSubscription?.currentPlan?.index <
                  props?.contentData?.plan?.index &&
                props?.contentType !== "path"
              }
              hasSubscription={userSubscription?.currentPlan?.index !== 0}
              contentType={props.contentType}
              contentId={props?.contentData?._id as string}
              isBookmarked={props?.contentData?.bookmark?.length === 1}
            />
            <article className="prose max-w-full prose-sm">
              <ContentAboutTitle
                releaseDate={props?.contentData?.info?.releaseDate}
                isOnScreenHandler={isOnScreenHandler}
                title={props?.contentData?.name as string}
              ></ContentAboutTitle>
              <div className="xl:block hidden">
                <ContentAboutTags tags={props?.contentData?.tags} />
              </div>
            </article>
          </div>
          <div className="xl:hidden block">
            <ContentAboutTags tags={props?.contentData?.tags} />
          </div>
          <ContentAboutTabs
            about={props?.contentData?.info?.description?.about}
            objectives={props?.contentData?.info?.description?.objectives}
          />

          {/* test */}
          <div className="xl:hidden block w-full">
            <div className="top-20 flex flex-col gap-2 ">
              <div className="w-full flex sm:flex-row flex-col gap-3">
                {props?.contentType === "course" ||
                props?.contentType === "lab" ? (
                  <div className="xl:w-[50%] w-[100%]">
                    <ContentAboutInfo
                      contentId={props?.contentData?._id as string}
                      rating={props?.contentData?.rating}
                      difficulty={0}
                      duration={props?.contentData?.info?.duration as number}
                      userRating={props?.userRate?.rate as number}
                      contentType={props?.contentType}
                      state={props.state}
                    />
                  </div>
                ) : (
                  <div className="sm:w-[50%] w-[100%]">
                    <ContentAboutInfo
                      contentId={props?.contentData?._id as string}
                      rating={props?.contentData?.rating}
                      difficulty={0}
                      duration={props?.contentData?.info?.duration as number}
                      userRating={props?.userRate?.rate as number}
                      contentType={props?.contentType}
                      state={props.state}
                    />
                  </div>
                )}

                {props.contentType === "path" ? (
                  <div
                    className="sm:w-[50%] w-[100%]"
                    onClick={() => setClickedBadge(props.contentData.badges[0])}
                  >
                    <Badge
                      id={props.contentData.badges[0]._id}
                      name={props.contentData.badges[0].name}
                      description={props.contentData.badges[0].description}
                    ></Badge>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* test */}

          {props?.contentType === "course" ? (
            <div className="card bg-base-200">
              <div className="card-body ">
                <CourseContentCollapse
                  subsectionClickHandler={(
                    sectionIndex: number,
                    subsectionIndex: number
                  ) =>
                    navigate(
                      userSubscription &&
                        userSubscription?.currentPlan?.index <
                          props?.contentData?.plan?.index &&
                        props?.contentType !== "path"
                        ? ""
                        : `player?module=${sectionIndex}&video=${subsectionIndex}`
                    )
                  }
                  sectionClickHandler={() => {}}
                  data={{
                    currentSubsection: {} as Subsection,
                    sections: props.contentData.sections as any,
                    state: props.state as any,
                    sectionBookmarks: props.bookmarks.sections as any,
                    subsectionBookmarks: props.bookmarks.subsections as any,
                  }}
                  courseId={props?.contentData?._id}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {props?.contentType === "lab" ? (
            <LabMachines
              machineClickHandler={(machineIndex: number) =>
                navigate(
                  userSubscription &&
                    userSubscription?.currentPlan?.index <
                      props?.contentData?.plan?.index &&
                    props?.contentType !== "path"
                    ? ""
                    : `/labs/${props.contentData._id}/workspace/${machineIndex}`
                )
              }
              machines={props.contentData.machines.filter(
                (machine: any) => machine?.type !== "STUDENT"
              )}
            ></LabMachines>
          ) : (
            ""
          )}
          {props?.contentType === "path" ? (
            <PathContent
              courses={props.contentData?.courses}
              labs={props.contentData?.labs}
            />
          ) : (
            ""
          )}
          <div className="xl:sticky static sm:top-16 sm:z-10 bg-base-100 pt-4 pb-4 ">
            <div
              ref={relatedContentRef}
              className="sm:divider none m-0 w-[100%]"
            >
              <div className="badge badge-primary w-[100%] sm:p-0 p-3">
                <p className="sm:text-sm text-xs w-[100%] text-center text-white">
                  Related Content with: {props.contentData?.name}
                </p>
              </div>
            </div>
          </div>
          {relatedContentIsOnScreen ? (
            <RelatedContent
              tags={props.contentData.tags.map((tag: any) => tag._id)}
              contentType={props.contentType}
            ></RelatedContent>
          ) : (
            ""
          )}
        </div>
        {/* difficulty */}
        <div className="xl:block hidden w-3/12">
          <div className="sticky top-20 flex flex-col gap-2 ">
            <div
              className={`flex flex-col justify-center p-3 gap-3
              ${isOnScreen ? "hidden" : ""}`}
            >
              <ContentButtons
                isLocked={
                  userSubscription &&
                  userSubscription?.currentPlan?.index <
                    props?.contentData?.plan?.index &&
                  props?.contentType !== "path"
                }
                contentType={props.contentType}
                contentId={props?.contentData?._id as string}
                isBookmarked={props?.contentData?.bookmark?.length === 1}
              />
              <article className="prose max-w-full prose-sm">
                <h1>{props?.contentData?.name}</h1>
              </article>
            </div>
            <ContentAboutInfo
              contentId={props?.contentData?._id as string}
              rating={props?.contentData?.rating}
              difficulty={0}
              duration={props?.contentData?.info?.duration as number}
              userRating={props?.userRate?.rate as number}
              contentType={props?.contentType}
              state={props.state}
            />
            {props.contentType === "path" ? (
              <div onClick={() => setClickedBadge(props.contentData.badges[0])}>
                <Badge
                  id={props.contentData.badges[0]._id}
                  name={props.contentData.badges[0].name}
                  description={props.contentData.badges[0].description}
                ></Badge>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
