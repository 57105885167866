import { XMarkIcon } from "@heroicons/react/24/outline";

export default function ErrorAlert(props: {message: string}) {
  return (
    <div className="alert alert-error">
      <div className="flex gap-2">
        <XMarkIcon className="h-6"></XMarkIcon>
        <span>{props.message}</span>
      </div>
    </div>
  )
}