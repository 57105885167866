import { useState } from "react";
import ContentListSearchAutocomplete from "./ContentListSearchAutocomplete";
import {
  useSearchAndFilterCoursesQuery,
  useSearchAndFilterPathsQuery,
} from "../api/CourseService";
import { ApolloProvider } from "@apollo/client";
import { getCourseApolloClient, getLabApolloClient } from "../util/apollo";
import { useSearchAndFilterLabsQuery } from "../api/LabService";
import { ClockIcon, TagIcon } from "@heroicons/react/24/outline";
import {
  calculateProgress,
  secondsToHoursAndMinutes,
} from "../util/convertors";
import { Link, useNavigate } from "react-router-dom";
import ContentTable from "./ContentTable";
import ContentTableSkeleton from "./ContentTableSkeleton";

function getVariables(searchString: string) {
  return {
    variables: {
      pagination: {
        limit: 2,
        page: 1,
      },
      searchString: searchString,
      filter: {},
    },
  };
}

function Paths(props: { searchString: string }) {
  const navigate = useNavigate();

  const { data, loading } = useSearchAndFilterPathsQuery(
    getVariables(props.searchString)
  );

  if (loading) {
    return <h1>LOADING PATHS</h1>;
  }

  return (
    <table className="table table-fixed w-full">
      <tbody>
        {data?.search?.docs?.map((path) => {
          return (
            <tr
              key={path?._id}
              className="hover cursor-pointer"
              onClick={() => navigate(`/paths/${path?._id}`)}
            >
              <td className="md:w-[20rem] w-[10rem]">
                <img
                  className="rounded-2xl"
                  src={`https://static.socora.com/img/path/small/${path?._id}.jpg`}
                ></img>
              </td>
              <td className="w-full">
                <div className="flex flex-col gap-4 ">
                  <div className="text-lg font-bold h-full">{path?.name}</div>
                  <div className="lg:flex hidden text-sm whitespace-normal text-accent opacity-50">
                    {path?.info?.description?.about?.substring(0, 300)}...
                  </div>
                  <div className="flex gap-4 items-center">
                    <div>
                      <div className="badge  gap-2 border-0 p-0 bg-transparent">
                        <ClockIcon className="h-4"></ClockIcon>
                        {secondsToHoursAndMinutes(
                          path?.info?.duration as number
                        )}
                      </div>
                    </div>
                    {path?.tags?.slice(0, 6).map((tag) => (
                      <div
                        key={tag?._id}
                        className="2xl:flex hidden badge gap-2 border-0 p-0 bg-transparent"
                      >
                        <TagIcon className="h-4"></TagIcon>
                        {tag?.value}
                      </div>
                    ))}
                    {path?.tags?.slice(0, 2).map((tag) => (
                      <div
                        key={tag?._id}
                        className="2xl:hidden lg:flex md:hidden sm:flex hidden badge gap-2 border-0 p-0 bg-transparent"
                      >
                        <TagIcon className="h-4"></TagIcon>
                        {tag?.value}
                      </div>
                    ))}
                  </div>

                  <progress
                    max="100"
                    className="h-[0.2rem] w-full progress progress-primary bg-white bg-opacity-10 "
                    value={calculateProgress(
                      path?.state?.finishedUnits as number,
                      path?.state?.totalUnits as number
                    )}
                  ></progress>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function SmallScreenPaths(props: { searchString: string }) {
  const { data, loading } = useSearchAndFilterPathsQuery(
    getVariables(props.searchString)
  );

  if (loading) {
    return <ContentTableSkeleton rows={2}></ContentTableSkeleton>;
  }

  return <ContentTable data={data} contentType="path" />;
}

function Courses(props: { searchString: string }) {
  const { data, loading } = useSearchAndFilterCoursesQuery(
    getVariables(props.searchString)
  );

  if (loading) {
    return <ContentTableSkeleton rows={2}></ContentTableSkeleton>;
  }

  return <ContentTable data={data} contentType="course" />;
}

function Labs(props: { searchString: string }) {
  const { data, loading } = useSearchAndFilterLabsQuery(
    getVariables(props.searchString)
  );

  if (loading) {
    return <ContentTableSkeleton rows={2}></ContentTableSkeleton>;
  }

  return <ContentTable data={data} contentType="lab" />;
}

export default function NavbarGlobalSearch() {
  const [searchString, setSearchString] = useState("");

  const onInputComplete = (input: string) => {
    setSearchString(input);
  };

  return (
    <div className="absolute w-full h-screen bg-black bg-opacity-50">
      <div className="relative top-0 w-full bg-base-200">
        <div className="divider m-0  h-0"></div>
        <div className="h-16">
          <div className="h-full w-full">
            <ContentListSearchAutocomplete
              placeHolder="Search paths, courses and labs..."
              onComplete={onInputComplete}
            ></ContentListSearchAutocomplete>
          </div>
        </div>
        {searchString !== "" ? (
          <div>
            <div className="flex lg:flex-row flex-col sm:p-6 p-1 sm:gap-6 gap-3">
              <div className="lg:w-2/3 lg:block hidden">
                <div className="pb-2 text-primary font-bold text-lg">Paths</div>
                <ApolloProvider client={getCourseApolloClient()}>
                  <Paths searchString={searchString}></Paths>
                </ApolloProvider>
              </div>
              <div className="flex flex-col lg:w-1/3 w-full">
              <div className="lg:hidden block">
                  <div className="pb-2 text-primary font-bold text-lg">
                    Paths
                  </div>
                  <ApolloProvider client={getCourseApolloClient()}>
                    <SmallScreenPaths searchString={searchString}></SmallScreenPaths>
                  </ApolloProvider>
                </div>
                <div>
                  <div className="pb-2 text-primary font-bold text-lg">
                    Courses
                  </div>
                  <ApolloProvider client={getCourseApolloClient()}>
                    <Courses searchString={searchString}></Courses>
                  </ApolloProvider>
                </div>
                <div className="lg:hidden divider divider-horizontal"></div>
                <div>
                  <div className="pb-2 lg:pt-2 text-primary font-bold text-lg">
                    Labs
                  </div>
                  <ApolloProvider client={getLabApolloClient()}>
                    <Labs searchString={searchString}></Labs>
                  </ApolloProvider>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center sm:pt-0 pt-2 pb-6">
              <Link
                to={`/search?q=${searchString}`}
                className="btn btn-primary text-white uppercase"
              >
                Load more
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
