import { ApolloProvider } from "@apollo/client";
import ContentList from "../../components/ContentList";
import { getCourseApolloClient } from "../../util/apollo";

export default function Courses() {
  return (
    <ApolloProvider client={getCourseApolloClient()}>
       <ContentList contentType="course"></ContentList>
    </ApolloProvider>
   
  )
}
