import * as Apollo from '@apollo/client';
import { SearchAndFilterCoursesDocument, SearchAndFilterCoursesQuery, SearchAndFilterCoursesQueryVariables, SearchAndFilterPathsDocument, SearchAndFilterPathsQuery, SearchAndFilterPathsQueryVariables } from "../api/CourseService";
import { SearchAndFilterLabsQuery, SearchAndFilterLabsQueryVariables, SearchAndFilterLabsDocument } from "../api/LabService";


const defaultOptions = {} as const;


const contentSearchMap = {
  "course": {
    getBaseOptions: (variables: SearchAndFilterCoursesQueryVariables) => {
      let baseOptions: Apollo.QueryHookOptions<SearchAndFilterCoursesQuery, SearchAndFilterCoursesQueryVariables> = {
        variables: variables
      }
      return baseOptions;
    },
    getDocument: () => {
      return SearchAndFilterCoursesDocument
    }
  },
  "path": {
    getBaseOptions: (variables: SearchAndFilterPathsQueryVariables) => {
      let baseOptions: Apollo.QueryHookOptions<SearchAndFilterPathsQuery, SearchAndFilterPathsQueryVariables> = {
        variables: variables
      }
      return baseOptions;
    },
    getDocument: () => {
      return SearchAndFilterPathsDocument
    }
  },
  "lab": {
    getBaseOptions: (variables: SearchAndFilterLabsQueryVariables) => {
      let baseOptions: Apollo.QueryHookOptions<SearchAndFilterLabsQuery, SearchAndFilterLabsQueryVariables> = {
        variables: variables
      }
      return baseOptions;
    },
    getDocument: () => {
      return SearchAndFilterLabsDocument
    }
  }
}


export function useGQLSearch(contentType: string, variables: SearchAndFilterCoursesQueryVariables) {
  // @ts-ignore
  let contentSearch = contentSearchMap[contentType];
  let baseOptions = contentSearch.getBaseOptions(variables);
  let document = contentSearch.getDocument();
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<any, any>(document, options);
}