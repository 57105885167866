import { useEffect, useRef, useState } from "react";
import {
  Note as NoteType,
  Sections,
  Subsection,
  UserCourseNote,
  useCreateUserCourseNoteMutation,
  useDeleteUserCourseNoteMutation,
  useUpdateUserCourseNoteMutation,
} from "../api/CourseService";
import {
  ArrowDownTrayIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import "./highlight";
import ReactQuill from "react-quill";
import {
  createNoteDom,
  noteClickHandler,
} from "../pages/courses/player/player.util";
import "../pages/labs/workspace/socora-quill.css";

export function SaveIcon() {
  return (
    <svg
      className=" light:fill-gray-700 dark:fill-gray-200 h-4"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22,4h-2v6c0,0.552-0.448,1-1,1h-9c-0.552,0-1-0.448-1-1V4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18  c1.105,0,2-0.895,2-2V8L22,4z M22,24H8v-6c0-1.105,0.895-2,2-2h10c1.105,0,2,0.895,2,2V24z" />
      <rect height="5" width="2" x="16" y="4" />
    </svg>
  );
}


function Note(props: {
  courseId: string;
  note: NoteType;
  noteIndex: number;
  deleteHandler: any;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [noteContent, setNoteContent] = useState(props.note.content as string);

  const [updateMutation] = useUpdateUserCourseNoteMutation({
    variables: {
      input: {
        content: noteContent,
        courseId: props.courseId,
        noteId: props.note.id,
      },
    },
  });

  const [deleteMutation] = useDeleteUserCourseNoteMutation({
    variables: {
      input: {
        courseId: props.courseId,
        noteId: props.note.id,
      },
    },
  });

  useEffect(() => {
    const noteElement = document.getElementById("note_" + props.note.id);
    setTimeout(() => {
      noteElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 500);
  }, []);

  const onSave = () => {
    updateMutation();
    setIsEditing(false);
  };

  const onCancel = () => {
    setNoteContent(props.note.content as string);
    setIsEditing(false);
  };

  const onDelete = () => {
    deleteMutation();
    props.deleteHandler(props.noteIndex);
  };

  const seek = () => {
    let video = document.getElementById("player") as any;
    video.currentTime = props.note.videoTime;
  };

  return (
    <div
      id={"note_" + props.note.id}
      className="bg-accent bg-opacity-5 p-2 flex flex-col rounded-2xl"
    >
      <div className="flex">
        <div
          onClick={() => seek()}
          className="ml-auto text-accent text-opacity-50 hover:text-primary cursor-pointer text-sm"
        >
          {new Date((1000 * props?.note?.videoTime!) as number)
            .toISOString()
            .substr(11, 8)}
        </div>
      </div>

      <div hidden={!isEditing}>
        <ReactQuill
          id="editable"
          modules={{
            syntax: true,
            toolbar: [
              [
                { header: [1, 2, 3, 4, 5, 6, false] },
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "code-block",
                { list: "ordered" },
                { list: "bullet" },
                { script: "sub" },
                { script: "super" },
                { color: [] },
                { background: [] },
                { align: [] },
              ],
            ],
          }}
          value={noteContent}
          onChange={setNoteContent}
        ></ReactQuill>
      </div>
      <div hidden={isEditing}>
        <ReactQuill
          id="readonly"
          modules={{
            toolbar: false,
            syntax: true,
          }}
          readOnly={true}
          value={noteContent}
        ></ReactQuill>
      </div>

      <div className="flex">
        {isEditing ? (
          <div className="flex w-full">
            <button
              onClick={onCancel}
              className="btn btn-circle btn-sm btn-ghost"
            >
              <XMarkIcon className="h-5"></XMarkIcon>
            </button>
            <button
              onClick={onSave}
              className="btn btn-circle btn-sm btn-ghost"
            >
              <SaveIcon />
            </button>
            <button
              onClick={onDelete}
              className=" ml-auto btn btn-circle btn-sm btn-ghost"
            >
              <TrashIcon className="h-5 text-red-500" />
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="btn btn-circle btn-sm btn-ghost"
          >
            <PencilIcon className="h-5"></PencilIcon>
          </button>
        )}
      </div>
    </div>
  );
}

export default function CourseNotes(props: {
  courseId: string;
  currentSection: Sections;
  userCourseNote: UserCourseNote;
  currentVideo: Subsection;
  noteClickHandler: any;
  newNoteContent?: string;
}) {
  const [newNoteContent, setNewNoteContent] = useState("");
  const [notes, setNotes] = useState([] as NoteType[]);
  const [showNewNoteEditor, setShowNewNoteEditor] = useState(false);
  const [createNoteHandler] = useCreateUserCourseNoteMutation();

  useEffect(() => {
    let filteredNotes = props?.userCourseNote?.notes?.filter(
      (note) => note?.subsectionId === props.currentVideo._id
    );
    setNotes(filteredNotes as NoteType[]);
  }, [props.currentVideo, props.currentSection]);

  useEffect(() => {
    setNewNoteContent(props.newNoteContent as string);
  }, [props.newNoteContent]);

  const deleteHandler = (noteIndex: number) => {
    let tempNotes = [...notes];

    document.getElementById(tempNotes?.[noteIndex]?.id as string)!.outerHTML =
      "";
    tempNotes.splice(noteIndex, 1);
    setNotes(tempNotes);
  };

  const createHandler = async () => {
    let video = document.getElementById("player") as any;

    const res = await createNoteHandler({
      variables: {
        input: {
          content: newNoteContent,
          courseId: props.courseId,
          sectionId: props.currentSection._id,
          subsectionId: props.currentVideo._id,
          videoTime: Math.floor(video?.currentTime),
        },
      },
    });

    let tempNotes = [] as any;

    if (notes) {
      tempNotes = [...notes];
    }

    tempNotes.push(res.data?.createUserCourseNote as NoteType);

    setNewNoteContent("");
    setNotes(tempNotes);

    createNoteDom(
      res.data?.createUserCourseNote as NoteType,
      (((res.data?.createUserCourseNote?.videoTime as number) /
        props?.currentVideo?.duration!) as number) * 100
    );

    //@ts-ignore
    document
      //@ts-ignore
      .getElementById(res.data?.createUserCourseNote?.id)
      .addEventListener("click", () => {
        noteClickHandler(
          res.data?.createUserCourseNote?.id as string,
          res.data?.createUserCourseNote?.videoTime as number
        );
        props.noteClickHandler();
      });
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow overflow-auto ">
        <div className="flex flex-col gap-2">
          {notes?.map((note, index: number) => {
            return (
              <Note
                key={note.id}
                noteIndex={index}
                deleteHandler={deleteHandler}
                courseId={props?.userCourseNote?.courseId as string}
                note={note}
              ></Note>
            );
          })}
        </div>
      </div>
      <div className="pt-5 rounded">
        {showNewNoteEditor || newNoteContent ? (
          <>
            <div className="bg-base-300 rounded-xl mb-2">
              <ReactQuill
                className="p-4"
                value={newNoteContent}
                modules={{
                  syntax: true,
                  toolbar: [
                    { header: [1, 2, 3, 4, 5, 6, false] },
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "code-block",
                    { list: "ordered" },
                    { list: "bullet" },
                    { script: "sub" },
                    { script: "super" },
                    { color: [] },
                    { background: [] },
                    { align: [] },
                  ],
                }}
                onChange={setNewNoteContent}
              ></ReactQuill>
              <div className="flex  gap-3 pl-2 pr-2 pb-2 ">
                <div className="w-full">
                  <button
                    onClick={createHandler}
                    className="btn btn-primary w-full btn-xs text-white"
                  >
                    SAVE
                  </button>
                </div>

                <div className="w-full">
                  <button
                    onClick={() => {
                      setShowNewNoteEditor(false);
                      setNewNoteContent("");
                    }}
                    className="btn  w-full   btn-xs"
                  >
                    DISCARD
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <button
            onClick={() => setShowNewNoteEditor(true)}
            className="btn btn-primary w-full btn-xs text-white"
          >
            CREATE
          </button>
        )}
      </div>
    </div>
  );
}
