export default function parseFilter(filter: any) {
  let parsedFilter = {} as any;

  if(filter.Difficulty) {
    parsedFilter["difficultyIndex"] = filter.Difficulty.map((diff:any) => diff.id)
  }

  if(filter.Plan) {
    parsedFilter["planIndex"] = filter.Plan.map((plan:any) => plan.id)
  }

  if(filter.Status) {
    if(filter.Status.find((s:any) => s.label === "Currently Watching")) parsedFilter['currentlyWatching'] = true
    if(filter.Status.find((s:any) => s.label === "Finished")) parsedFilter['finished'] = true
    
  }

  if(filter.Tags) {
    parsedFilter["tags"] = filter.Tags.map((tag:any) => tag.id)
  }

  return parsedFilter;
}