export function createNoteDom(note: any, seekPercentage: number) {
  document.getElementsByClassName("plyr__progress")[0].insertAdjacentHTML(
    "beforeend",
    `
      <span id="${note.id}" style="left: ${seekPercentage}%" class="plyr__tooltip_copy plyr__tooltip--visible_copy">
      <svg style="height: 20x; width: 20px; cursor: pointer" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"></path>
    </svg>
      <div style="margin-left: 50%; border-left: 1px solid #8a6464; height: 10px"></div>
      </span>
  `
  );
}


export function noteClickHandler(noteId: string, noteVideoTime: number) {

  let video = document.getElementById("player") as any;

  video.currentTime = noteVideoTime as number;

  const noteElement = document.getElementById("note_" + noteId);

  setTimeout(() => {
    noteElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }, 100);

  // @ts-ignore
  noteElement!.style.border = "1px solid rgba(245, 31, 111, 0.5)";

  setTimeout(() => {
    // @ts-ignore
    noteElement!.style.border = "1px solid transparent";
  }, 2000);
}
