import axios from "axios";
import { PaymentService } from "./PaymentService";

axios.defaults.withCredentials = true;

export const apiUrl = process.env.REACT_APP_API_URL + "/auth";

const { fetch: originalFetch } = window;

let isRefreshing = false;

window.fetch = async (...args) => {
  let [resource, config] = args;
  // request interceptor here
  let response = await originalFetch(resource, config);
  if (response.status === 500 || response.status === 401) {
    if (isRefreshing) return response;
    const responseBody = await response.json();
    if (
      responseBody?.errors?.find((err: any) => err.message === "UNAUTHENTICATED") || responseBody?.msg === "Unauthorized"
    ) {
      isRefreshing = true;
      let authResponse = await AuthService.refresh();

      if (!authResponse.hasError) {
        isRefreshing = false;
        return await originalFetch(resource, config);
      } else {
        // @ts-ignore
        window.location = "/auth/signin";
      }
    }
  }
  // response interceptor here
  return response;
};

export default class AuthService {
  static async getUser(tryRefresh?: boolean) {
    try {
      return await axios.get(`${apiUrl}/user`);
    } catch (error) {

      if (tryRefresh || tryRefresh === undefined) {
        const refresh = await this.refresh();
        if (!refresh.hasError) {
          return await axios.get(`${apiUrl}/user`);
        }
      }

      return {
        hasError: true,
        error: error,
      };
    }
  }
  static async signIn(username: string, password: string) {
    try {
      let response = await axios.post(`${apiUrl}/login`, {
        username: username,
        password: password,
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async forceChangePassword(newPassword: string) {
    try {
      let response = await axios.post(`${apiUrl}/login/force-change-password`, {
        newPassword: newPassword,
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async refresh() {
    try {
      let response = await axios.post(`${apiUrl}/refresh`, {});
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }
  static async signInMFA(code: string) {
    try {
      let response = await axios.post(`${apiUrl}/login/mfa`, {
        code,
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async signUp(email: string, username: string, password: string, accountType:string) {
    try {
      let response = await axios.post(`${apiUrl}/signup`, {
        email: email,
        username: username,
        password: password,
        accountType: accountType
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async verifyEmail(sub: string, code: string, accountType: string) {
    try {
      let response = await axios.post(`${apiUrl}/signup/confirm`, {
        sub,
        code,
        accountType
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async signOut() {
    try {
      let response = await axios.post(`${apiUrl}/logout`, {});
      PaymentService.clearCache();
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async requestResetPassword(username: string) {
    try {
      let response = await axios.post(`${apiUrl}/password/forgot`, {
        username,
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async resetPassword(code: string, username: string, password: string) {
    try {
      let response = await axios.post(`${apiUrl}/password/forgot/confirm`, {
        code: code,
        username: username,
        password,
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async changePassword(currentPassword: string, newPassword: string, code: string) {
    try {
      let response = await axios.post(`${apiUrl}/password/change`, {
        currentPassword,
        newPassword,
        code: parseInt(code)
      });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async mfa() {
    try {
      let response = await axios.get(`${apiUrl}/mfa`);
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async initMFA() {
    try {
      let response = await axios.post(`${apiUrl}/mfa/init`);
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async enableMFA(code: string) {
    try {
      let response = await axios.post(`${apiUrl}/mfa/enable`, { code:parseInt(code) });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async recoverMFA(username:string, password:string, recoveryCode: string) {
    try {
      let response = await axios.post(`${apiUrl}/mfa/recover`, { 
        username, password, recoveryCode
       });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async disableMFA(code: string) {
    try {
      let response = await axios.post(`${apiUrl}/mfa/disable`,  { code:parseInt(code) });
      return {
        hasError: false,
        response: response,
      };
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }
}
