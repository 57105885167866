import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export default function ThemeToggler() {
  const [theme, setTheme] = useState(() => {
    const theme = localStorage.getItem("theme");
    return theme ? theme : "socora_dark";
  });

  const toggleTheme = () => {
    let newTheme = theme === "socora_dark" ? "socora_light" : "socora_dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    (document.querySelector("html") as any).setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <label className={`swap swap-rotate mr-2 `}>
      <input onClick={toggleTheme} type="checkbox" />
      <div className="btn btn-sm btn-circle">
        {theme === "socora_dark" ? (
          <SunIcon className={`fill-current`}></SunIcon>
        ) : (
          <MoonIcon className={`fill-current`}></MoonIcon>
        )}
      </div>
    </label>
  );
}
