import { ApolloProvider } from "@apollo/client";
import HomeContentCarousel from "../../components/HomeContentCarousel";
import HomeMainCarousel from "../../components/HomeMainCarousel";
import { getLabApolloClient } from "../../util/apollo";
import { useGetUserGroupedTagsCountQuery } from "../../api/CourseService";
import { useRef } from "react";
import HomeContentCarouselSkeleton from "../../components/HomeContentCarouselSkeleton";
import useOnScreen from "../../components/useOnScreen";

export default function Home() {
  const { data, loading } = useGetUserGroupedTagsCountQuery();

  const pathsRef = useRef<HTMLDivElement>(null);
  const pathsIsOnScreen = useOnScreen(pathsRef, true);

  const labsRef = useRef<HTMLDivElement>(null);
  const labsIsOnScreen = useOnScreen(labsRef, true);

  const continueLearningRef = useRef<HTMLDivElement>(null);
  const continueLearningIsOnScreen = useOnScreen(continueLearningRef, true);

  return (
    <div className="h-full">
      <div className="h-[35rem]">
        <HomeMainCarousel></HomeMainCarousel>
      </div>

      <div className="pl-8 pr-8 pt-4 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-lg ">
            Recomended{" "}
            <a href="/courses" className="text-primary">
              Courses
            </a>
          </h1>
          <div className="pt-4">
            {!loading ? (
              <HomeContentCarousel
                tags={data?.getUserGroupedTagsCount}
                currentlyWatching={false}
                contentType="course"
              ></HomeContentCarousel>
            ) : (
              <HomeContentCarouselSkeleton />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-lg ">
            Recomended{" "}
            <a href="/paths" className="text-primary">
              Paths
            </a>
          </h1>
          <div ref={pathsRef} className="pt-4">
            {!loading && pathsIsOnScreen ? (
              <HomeContentCarousel
                currentlyWatching={false}
                tags={data?.getUserGroupedTagsCount}
                contentType="path"
              ></HomeContentCarousel>
            ) : (
              <HomeContentCarouselSkeleton />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-lg ">
            Recomended{" "}
            <a href="/labs" className="text-primary">
              Labs
            </a>
          </h1>
          <div ref={labsRef} className="pt-4">
            {!loading && labsIsOnScreen ? (
              <ApolloProvider client={getLabApolloClient()}>
                <HomeContentCarousel
                  currentlyWatching={false}
                  tags={data?.getUserGroupedTagsCount}
                  contentType="lab"
                ></HomeContentCarousel>
              </ApolloProvider>
            ) : (
              <HomeContentCarouselSkeleton />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <h1 className="font-bold text-lg ">Continue learning</h1>
          <div ref={continueLearningRef} className="pt-4">
            {!loading && continueLearningIsOnScreen ? (
              <HomeContentCarousel
                currentlyWatching={true}
                contentType="course"
              ></HomeContentCarousel>
            ) : (
              <HomeContentCarouselSkeleton />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
