import { useState } from "react"


export default function ContentTableSkeleton(props: {
  rows: number
}) {

  const [rows, setRows] = useState(() => Array.from(new Array(props.rows), (x, i) => i));

  return (
    <table className="table table-fixed w-full animate-pulse">
      <tbody>
        {rows.map((content: any, index:number) => {
          return (
            <tr
              key={index}
            >
              <td className="w-[5rem]">
                <div className="rounded-full bg-white bg-opacity-5 h-[3rem] "></div>
              </td>
              <td className="pl-0">
                <div className=" flex flex-col gap-4">
                  <div className="lg:h-4 h-2 lg:w-48 sm:w-34 w-full bg-white bg-opacity-5"></div>
                  <div className="lg:flex hidden gap-4 items-center">
                    <div className="h-3 w-10 bg-white bg-opacity-5"></div>
                    <div className="h-3 w-10 bg-white bg-opacity-5"></div>
                    <div className="h-3 w-10 bg-white bg-opacity-5"></div>
                  </div>
                  <div className="bg-white bg-opacity-5 w-full h-[0.2rem]"></div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  )
};
