import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { ValidationError } from "yup";

export default function LabeledInput(props: {
  top: string;
  name: string;
  type: string;
  yupSchema?: any;
  onInput?: any;
  onError?: any;
  value?: any;
}) {
  const [errors, setErrors] = useState(undefined as any);
  const [showPassword, setShowPassword] = useState(false);

  const validate = (input: any) => {
    try {
      props.yupSchema.validateSync(input);
      setErrors(undefined as any);
      props.onInput(input);
    } catch (error) {
      const validationError = error as ValidationError;
      setErrors(validationError.errors);
      props.onInput(input);
      // props.onError(validationError.errors)
    }
  };

  const changeShowPassword = () => {
    if (showPassword === true) {
      return setShowPassword(false);
    } else {
      return setShowPassword(true);
    }
  };

  return (
    <div className="form-control w-full">
      <label className="label pt-0">
        <span className="label-text">{props.top}</span>
      </label>
      <div
        className={
          !errors
            ? "w-full h-full flex border border-accent border-opacity-10 rounded-lg"
            : "w-full h-full flex border border-red-600 rounded-lg"
        }
      >
        {/* <div className="w-full h-full">
          <input
            name={props.name}
            id={props.name}
            type={
              props.type === "password" && showPassword === true
                ? "text"
                : props.type
            }
            value={props?.value}
            placeholder=""
            onChange={(e) => validate(e.target.value)}
            className={
              !errors
                ? "input focus:outline-none border-m border-white border-opacity-0 w-full "
                : "input focus:outline-none border-m border-red-0 w-full "
            }
          />
        </div> */}

        {/* {props.name === "password" && (
          <div className="w-2/12 flex items-center justify-center rounded-r-lg bg-base-100">
            {showPassword === false ? (
              <button
                className="btn btn-xs btn-ghost"
                type="button"
                onClick={() => changeShowPassword()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            ) : (
              <button
                className="btn btn-xs btn-ghost"
                type="button"
                onClick={() => changeShowPassword()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              </button>
            )}
          </div>
        )} */}

        <label className="input flex items-center gap-2 w-full ">
          <input
            name={props.name}
            id={props.name}
            type={
              props.type === "password" && showPassword === true
                ? "text"
                : props.type
            }
            className="grow"
            value={props?.value}
            onChange={(e) => validate(e.target.value)}
          />
          {props.name === "password" && (
            <div
              className="z-10 btn btn-xs"
              onClick={() => changeShowPassword()}
            >
              {showPassword ? (
                <EyeSlashIcon className="w-4 h-4" />
              ) : (
                <EyeIcon className="w-4 h-4" />
              )}
            </div>
          )}
        </label>
      </div>

      {errors ? (
        <div className="">
          {errors?.map((msg: any, index: number) => {
            return (
              <label key={index} className="label">
                <span className="label-text-alt text-red-600">{msg}</span>
              </label>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
