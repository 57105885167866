import axios from "axios"

let apiUrl = `${process.env.REACT_APP_API_URL}/payment`

let userSubscriptionResponse = undefined as any;

export const planItems = [
  "10 Bookmarks",
  "10 Course Notes",
  "Unlimited Access on Courses",
  "Unlimited Access on Labs",
  "Unlimited Access on ...",
];

export const plans = [
  {
    id: 1,
    name: "Basic",
    price: 10,
    focus: false,
    items: [planItems[0], planItems[1]],
  },
  {
    id: 2,
    name: "Standard",
    price: 20,
    focus: true,
    items: [planItems[0], planItems[1], planItems[2]],
  },
  {
    id: 3,
    name: "Premium",
    focus: false,
    price: 30,
    items: planItems,
  },
];

export class PaymentService {
  static async getSubscription() {

    if(userSubscriptionResponse)  {
      return userSubscriptionResponse;}

    try {
      userSubscriptionResponse = await axios.get(`${apiUrl}/subscription`);
      return userSubscriptionResponse;
    } catch (error) {
      userSubscriptionResponse = {
        hasError: true,
        error: error,
      };
      return userSubscriptionResponse;
    }
  }

  static async createSubscription(paymentMethodId: string, planIndex: string) {
    try { 
      return await axios.post(`${apiUrl}/subscription`, {
        paymentMethodId,
        planIndex
      })
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async getLastPayment() {
    try { 
      return await axios.get(`${apiUrl}/subscription/lastPayment/error`)
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async getPaymentMethod() {
    try { 
      return await axios.get(`${apiUrl}/subscription/paymentmethod`)
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async getInvoices(params: any) {
    try { 
      return await axios.get(`${apiUrl}/subscription/invoices`, {
        params: params
      })
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async updatePaymentMethod(paymentMethodId: string) {
    try { 
      return await axios.post(`${apiUrl}/subscription/paymentmethod/update`, {
        paymentMethodId,
      })
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }


  static async retryFailedPayment() {
    try { 
      return await axios.post(`${apiUrl}/subscription/lastPayment/retry`, {})
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  static async changePlan(planIndex: number) {
    try { 
      return await axios.post(`${apiUrl}/subscription/changeplan`, {
        planIndex
      })
    } catch (error) {
      return {
        hasError: true,
        error: error,
      };
    }
  }

  
  static getPlans() {
    return plans;
  }


  static clearCache() {
    userSubscriptionResponse = undefined;
  }

}