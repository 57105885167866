export function scrollIntoView(elementId: string, delay?:number) {
  setTimeout(() => {
    const subsectionElement = document.getElementById(elementId);
    subsectionElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, (delay) ? delay : 400);
}
