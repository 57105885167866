import { Link, redirect, useFetcher } from "react-router-dom";

import AuthService from "../../../api/AuthService";
import ErrorAlert from "../../../components/ErrorAlert";
import { matchToPaths } from "../../../util/convertors";
import LabeledInput from "../../../components/LabeledInput";
import { useState } from "react";
import { string } from "yup";
import Alert from "../../../components/Alert";

export async function action(data: any) {

  let formData = await data.request.formData();

  let newPassword = formData.get("newPassword");

  const response = (await AuthService.forceChangePassword(newPassword)) as any;

  if (response.hasError) {
    return {
      authFailed: true,
      message: response.error.response.data.message,
    };
  } 

  let returnURLParam = new URL(data.request.url).searchParams.get('returnURL') as string;
  
  if(returnURLParam) {
    let matched = matchToPaths(returnURLParam)
    if(matched !== null) return redirect(matched?.pathname);
  }
  
  return redirect('/home');
  
}

export const loader = async (data: any) => {

  let response = (await AuthService.getUser(false)) as any;

  if (!response.hasError) return redirect("/home");

  let returnURLParam = new URL(data.request.url).searchParams.get('returnURL') as string;

  if(returnURLParam) {
    let matched = matchToPaths(returnURLParam)
    if(matched === null) return redirect('/auth/forceChangePassword');
  }

  return {}

};

export default function ForceChangePassword() {
  
  const fetcher = useFetcher();
  
  const [newPassword, setNewPassword] = useState();

  const fieldsAreValid = () => {
    return newPassword? true : false;
  };

  return (
    <fetcher.Form method="post" className="flex flex-col gap-7">
      {fetcher.data ? (
        <ErrorAlert message={fetcher.data.message}></ErrorAlert>
      ) : (
        ""
      )}
      <Alert message="It appears that your account currently has a temporary password set up. To sign in, please create a new password." severity="alert-warning"></Alert>
      <LabeledInput
        value={newPassword}
        type="newPassword"
        name="newPassword"
        top="New Password"
        onInput={setNewPassword}
        yupSchema={string().min(1)}
      ></LabeledInput>

      {fetcher.state === "submitting" || fetcher.state === "loading" ? (
          <button
            type="submit"
            className={`btn btn-primary disabled:btn-primary `}
            disabled={true}
          >
            <span className="loading loading-spinner "></span>
            Change Password
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary text-white"
            disabled={!fieldsAreValid()}
          >
            Change Password
          </button>
      )}


      <div className="text-sm text-opacity-50 text-center">
       Already have an account? <Link className="link link-info" to={'/auth/signin'}>
        Sign In
      </Link>
      </div>
      
      {/* <div className="text-sm text-white text-opacity-50 text-center">
       You forgot your password? <Link className="link link-info" to={'/auth/resetpassword'}>
        Reset password
      </Link>
      </div> */}
    </fetcher.Form>
  );
}
