import { ClockIcon } from "@heroicons/react/24/outline";
import Rating from "./Rating";
import {
  calculateProgress,
  secondsToHoursAndMinutes,
} from "../util/convertors";
import UserRating from "./UserRating";
import { useState } from "react";
import { ExamBadge, FinalizedBadge } from "./SocoraCard";

export default function ContentAboutInfo(props: {
  rating: any;
  userRating: number;
  duration: number;
  difficulty: number;
  state: any;
  contentId: string;
  contentType: string;
}) {
  const [rate, setRate] = useState(props.rating);
  const [userCurrentRating, setUserCurrentRating] = useState(props.userRating);

  const rateChangeHandler = (newRate: any) => {
    let tempRate = { ...rate };
    tempRate[`star${newRate}`]++;
    tempRate[`star${userCurrentRating}`]--;
    setUserCurrentRating(newRate);
    setRate(tempRate);
  };

  return (
    <div className="card bg-base-200">
      <div className="card-body">
        <table className="table table-md ">
          <tbody>
            <tr className="border-base-100">
              <td>Difficulty</td>
              <td>Beginner</td>
            </tr>
            <tr className="border-base-100">
              <td>Rating</td>
              <td>
                <Rating rating={rate}></Rating>
              </td>
            </tr>
            <tr className="border-base-100">
              <td>My Rating</td>
              <td>
                <UserRating
                  contentType={props.contentType}
                  rateChangeHandler={rateChangeHandler}
                  contentId={props.contentId}
                  currentRating={props.userRating}
                ></UserRating>
              </td>
            </tr>
            <tr className="border-base-100">
              <td>Duration</td>
              <td>
                <div className="flex gap-1 text-sm items-center text-gray-500">
                  <div>
                    <ClockIcon className="h-4"></ClockIcon>
                  </div>
                  <div>
                    {props.duration
                      ? (secondsToHoursAndMinutes(props.duration) as string)
                      : "0s"}
                  </div>
                </div>
              </td>
            </tr>
            <tr className="border-base-100">
              <td>Progress</td>

              <td
                className={
                  props?.state?.finished || props?.state?.finalized
                    ? `flex flex-col gap-2`
                    : `gap-2`
                }
              >
                <progress
                  className="progress progress-primary bg-black bg-opacity-30"
                  value={calculateProgress(
                    props?.state?.finishedUnits as number,
                    props?.state?.totalUnits as number
                  )}
                  max="100"
                ></progress>
                {props.contentType === "course" ? (
                  props?.state?.finished && props?.state?.finalized ? (
                    <FinalizedBadge></FinalizedBadge>
                  ) : props?.state?.finished ? (
                    <div className="">
                      <ExamBadge></ExamBadge>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {(props.contentType === "lab" ||
                  props.contentType === "path") &&
                props?.state?.finished ? (
                  <FinalizedBadge />
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
