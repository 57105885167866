import { Link, redirect, useFetcher } from "react-router-dom";

import AuthService from "../../../api/AuthService";
import ErrorAlert from "../../../components/ErrorAlert";
import { matchToPaths } from "../../../util/convertors";
import LabeledInput from "../../../components/LabeledInput";
import { useState } from "react";
import { string } from "yup";


export async function action(data: any) {

  let formData = await data.request.formData();

  let username = formData.get("username");
  let password = formData.get("password");

  const response = (await AuthService.signIn(username, password)) as any;

  if (response.hasError) {
    return {
      authFailed: true,
      message: response.error.response.data.message,
    };
  } 

  if(response.response.data.mfa) {
    return redirect('/auth/signin/mfa');
  }

  if(response.response.data.force_change_password === true) {
    return redirect('/auth/forceChangePassword');
  }

  let returnURLParam = new URL(data.request.url).searchParams.get('returnURL') as string;
  
  if(returnURLParam) {
    let matched = matchToPaths(returnURLParam)
    if(matched !== null) return redirect(matched?.pathname);
  }
  
  return redirect('/home');
  
}

export const loader = async (data: any) => {

  let response = (await AuthService.getUser(false)) as any;

  if (!response.hasError) return redirect("/home");

  let returnURLParam = new URL(data.request.url).searchParams.get('returnURL') as string;

  if(returnURLParam) {
    let matched = matchToPaths(returnURLParam)
    if(matched === null) return redirect('/auth/signin');
  }

  return {}

};

export default function SignIn() {
  
  const fetcher = useFetcher();
  
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const fieldsAreValid = () => {
    return username && password? true : false;
  };

  return (
    <fetcher.Form method="post" className="flex flex-col gap-7">
      {fetcher.data ? (
        <ErrorAlert message={fetcher.data.message}></ErrorAlert>
      ) : (
        ""
      )}
      <LabeledInput
        value={username}
        type="text"
        name="username"
        top="Username"
        onInput={setUsername}
        yupSchema={string().min(1)}
      ></LabeledInput>
      
      <LabeledInput
        value={password}
        type="password"
        name="password"
        top="Password"
        onInput={setPassword}
        yupSchema={string().min(1)}
      ></LabeledInput>

      {fetcher.state === "submitting" || fetcher.state === "loading" ? (
          <button
            type="submit"
            className={`btn btn-primary text-white`}
            disabled={true}
          >
            <span className="loading loading-spinner "></span>
            Sign In
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary text-white"
            disabled={!fieldsAreValid()}
          >
            Sign In
          </button>
      )}


      <div className="text-sm text-opacity-50 text-center">
       You dont have an account? <Link className="link link-info" to={'/auth/signup'}>
        Sign Up
      </Link>
      </div>
      
      <div className="text-sm text-opacity-50 text-center">
       You forgot your password? <Link className="link link-info" to={'/auth/resetpassword'}>
        Reset password
      </Link>
      </div>
    </fetcher.Form>
  );
}
