import {
  BookmarkIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarGlobalSearch from "./NavbarGlobalSearch";
import CurrenltyWatchingDropdown from "./CurrentlyWatchingDropdown";
import AuthService from "../api/AuthService";
import Spinner from "./Spinner";

const routes = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "Paths",
    path: "/paths",
  },
  {
    label: "Courses",
    path: "/courses",
  },
  {
    label: "Labs",
    path: "/labs",
  },
];

function AccountDropdown() {
  const location = useLocation();
  let navigate = useNavigate();

  const [signOutLoading, setSignOutLoading] = useState(false);
  const dropDownRef = useRef(null);

  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const dropdownRefProfile = useRef<HTMLDivElement>(null);

  const [user, setUser] = useState<any>();

  const handleClickProfile = () => {
    setIsOpenProfile(!isOpenProfile);
  };

  const handleClickOutsideProfile = (event: MouseEvent) => {
    if (
      dropdownRefProfile.current &&
      !dropdownRefProfile.current.contains(event.target as Node)
    ) {
      setIsOpenProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideProfile);
    return () => {
      document.removeEventListener("click", handleClickOutsideProfile);
    };
  }, []);

  useEffect(() => {
    AuthService.getUser().then((response: any) => {
      setUser(response.data as any);
    });
  }, []);

  function handleSignOut() {
    setSignOutLoading(true);
    AuthService.signOut().then((response) => {
      if (!response.hasError) {
        navigate("/auth/signin");
      }
    });
  }

  const hadleMenuItemClick = (path: string) => {
    // @ts-ignore
    dropDownRef.current.focus();
    // @ts-ignore
    dropDownRef.current.blur();
    setIsOpenProfile(false);
    navigate(path);
  };

  return (
    <div className="flex-none ml-auto">
      <div className="dropdown dropdown-end" ref={dropdownRefProfile}>
        <label
          tabIndex={0}
          // id="drowdown_btn"
          ref={dropDownRef}
          onClick={handleClickProfile}
        >
          <div className="avatar placeholder hover:cursor-pointer mr-2">
            <div className="bg-base-200 hover:bg-base-300 rounded-full w-10 ">
              <span className="text-xl">
                {user?.["cognito:username"].charAt(0).toUpperCase()}
              </span>
            </div>
          </div>
        </label>
        {isOpenProfile && (
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-4 p-2 shadow rounded-box w-52 bg-base-200"
          >
            <li
              className={`${
                location.pathname.startsWith("/account/profile")
                  ? "text-primary"
                  : ""
              }`}
            >
              <a
                onClick={() => hadleMenuItemClick("/account/profile")}
                className="justify-between"
              >
                Profile
              </a>
            </li>
            <li
              className={`${
                location.pathname.startsWith("/settings/plan")
                  ? "text-primary"
                  : ""
              }`}
            >
              <a
                onClick={() =>
                  user?.["cognito:groups"].includes("BUSINESS_MEMBER")
                    ? hadleMenuItemClick("/settings/password")
                    : hadleMenuItemClick("/settings/plan")
                }
              >
                Settings
              </a>
            </li>
            <li
              className={`${signOutLoading ? "disabled" : ""}`}
              onClick={handleSignOut}
            >
              <div className="flex">
                <a>Logout</a>
                {signOutLoading ? (
                  <div className="ml-auto">
                    <Spinner heigt="h-4" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default function Navbar() {
  const location = useLocation();
  let navigate = useNavigate();

  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const [isOpenPage, setIsOpenPage] = useState(false);
  const dropdownRefPage = useRef<HTMLDivElement>(null);

  const handleClickPage = () => {
    setIsOpenPage(!isOpenPage);
  };

  const handleClickOutsidePage = (event: MouseEvent) => {
    if (
      dropdownRefPage.current &&
      !dropdownRefPage.current.contains(event.target as Node)
    ) {
      setIsOpenPage(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePage);
    return () => {
      document.removeEventListener("click", handleClickOutsidePage);
    };
  }, []);

  useEffect(() => {
    setSearchModalOpen(false);
  }, [location]);

  const hadleMenuItemClick = (path: string) => {
    // @ts-ignore
    dropdownRefPage.current.focus();
    // @ts-ignore
    dropdownRefPage.current.blur();
    setIsOpenPage(false);
    navigate(path);
  };

  return (
    <div className="">
      <div className="navbar shadow-xl">
        <div className="hidden lg:flex md:flex w-full">
          <a className="btn btn-ghost normal-case hover:bg-transparent">
            <img alt="me" src="/socora_logo.png" width="32" height="32" />
          </a>
          {routes.map((route) => {
            return (
              <Link key={route.path} to={route.path}>
                <div
                  className={`btn btn-ghost normal-case ${
                    location.pathname.startsWith(route.path)
                      ? "text-primary"
                      : ""
                  }`}
                >
                  {route.label}
                </div>
              </Link>
            );
          })}
        </div>
        <div className="flex-1 lg:hidden md:hidden">
          <div className="dropdown" ref={dropdownRefPage}>
            <label
              tabIndex={0}
              className="btn btn-ghost"
              onClick={handleClickPage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            {isOpenPage && (
              <ul
                tabIndex={0}
                className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-300 rounded-box w-52"
              >
                {routes.map((route) => (
                  <li
                    className={`${
                      location.pathname.startsWith(route.path)
                        ? "text-primary"
                        : ""
                    }`}
                  >
                    <a onClick={() => hadleMenuItemClick(route.path)}>
                      {route.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <Link to={"/bookmarks"}>
          <button className="btn btn-ghost ml-auto">
            <BookmarkIcon
              className={`h-4 ${
                location.pathname.startsWith("/bookmarks") ? "text-primary" : ""
              }`}
            ></BookmarkIcon>
          </button>
        </Link>
        <Link to={"/notes"}>
          <button className="btn btn-ghost ml-auto">
            <PencilSquareIcon
              className={`h-4 ${
                location.pathname.startsWith("/notes") ? "text-primary" : ""
              }`}
            />
          </button>
        </Link>
        <button
          onClick={() => {
            setSearchModalOpen(!searchModalOpen);
          }}
          className={`btn btn-ghost ml-auto ${
            searchModalOpen ? "text-primary" : ""
          }`}
        >
          <MagnifyingGlassIcon className="h-4"></MagnifyingGlassIcon>
        </button>

        <CurrenltyWatchingDropdown></CurrenltyWatchingDropdown>
        <AccountDropdown></AccountDropdown>
      </div>
      {searchModalOpen ? <NavbarGlobalSearch></NavbarGlobalSearch> : ""}
    </div>
  );
}
