import { useEffect, useState } from "react";
import { calculateStars } from "../util/convertors";

const starsArray = [1, 2, 3, 4, 5];

export default function Rating(props: { rating: any }) {
  
  const [stars, setStars] = useState(calculateStars(props.rating));

  useEffect(() => {
    setStars(calculateStars(props.rating));
  }, [props.rating])

  return (
    <div className=" rating rating-sm mt-auto gap-1">
      {starsArray.map((star) => (
        <input
          key={star} 
          type="radio"
          name={`rating-${star}`}
          disabled
          className={`mask mask-star bg-primary ${
            stars < star ? "bg-opacity-20" : ""
          }`}
        />
      ))}
    </div>
  );
}
