import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import GPTService from "../api/GPTService";
import ReactQuill from "react-quill";
import { scrollIntoView } from "../util/dom";
import AuthService from "../api/AuthService";
import { hasOnlyExpressionInitializer } from "typescript";
import { UserIcon } from "@heroicons/react/24/outline";


export default function Assistant(props: { saveAsNote: any }) {
  const [chatData, setChatData] = useState(
    [] as { user: string; assistant: string; waitingAssistant: boolean }[]
  );

  const [message, setMessage] = useState("");

  const messageHandler = async (e: any) => {
    e.preventDefault();
    setMessage("");

    let tempChatData = [...chatData];
    tempChatData.push({
      user: message,
      assistant: "",
      waitingAssistant: true,
    });
    setChatData(tempChatData);
    scrollIntoView(`scroll_anchor`, 50);

    let res = await GPTService.ask(message);

    tempChatData = [...tempChatData];

    tempChatData[tempChatData.length - 1].assistant = res.data.gptAnswer
      .replaceAll(
        "<code>",
        '<p> </p><pre class="ql-syntax" spellcheck="false">'
      )
      .replaceAll("</code>", "</pre><p> </p>");
    tempChatData[tempChatData.length - 1].waitingAssistant = false;
    setChatData(tempChatData);
    scrollIntoView(`scroll_anchor`, 50);
  };

  const saveAsNoteHandler = (index: number) => {
    props.saveAsNote(chatData[index].assistant);
  };

  useEffect(() => {
    // scrollIntoView(`scroll_anchor`, 400)
  }, []);

  return (
    <div id="chat_container" className="flex flex-col h-full gap-2">
      <div className="h-full overflow-scroll">
        <Chat saveAsNote={saveAsNoteHandler} chat={chatData}></Chat>
      </div>
      <div className="">
        <div className="">
          <form onSubmit={messageHandler} className="flex flex-col gap-2">
            <input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="textarea textarea-sm textarea-ghost bg-base-300  w-full h-full  "
              placeholder="Ask assistant..."
            ></input>
            <button
              type="submit"
              className="btn w-full rounded-lg btn-xs btn-primary text-white uppercase"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function Chat(props: {
  chat: {
    user: string;
    assistant: string;
    waitingAssistant: boolean;
  }[];
  saveAsNote: any;
}) {
  const [authenticatedUser, setAuthenticatedUser] = useState({} as any);

  useEffect(() => {
    AuthService.getUser().then((user) => {
      setAuthenticatedUser(user);
    });
  }, []);

  return (
    <div className="text-sm">
      <AssistantBubble
        index={-1}
        saveAsNote={() => {}}
        typing={false}
        isInit={true}
        text={`Hello, ${authenticatedUser?.data?.["cognito:username"]}. How may I help you?`}
      ></AssistantBubble>

      {props.chat.map((chat, index) => {
        return (
          <div key={index}>
            <UserBubble text={chat.user}></UserBubble>
            <AssistantBubble
              index={index}
              saveAsNote={props.saveAsNote}
              typing={chat.waitingAssistant}
              text={chat.assistant}
            ></AssistantBubble>
          </div>
        );
      })}
      <div id="scroll_anchor"></div>
    </div>
  );
}

function UserBubble(props: { text: string }) {
  return (
    <div className="chat chat-end">
      <div className="chat-image avatar placeholder">
        <div className="bg-gradient-to-br from-primary to-warning rounded-full w-10">
          <span className="text-lg"><UserIcon className="w-6 text-white"/></span>
        </div>
      </div>
      <div className="chat-header opacity-50">You</div>
      <div className="chat-bubble flex items-center  bg-base-300 text-black dark:text-white">
        <div className="break-words ">{props.text}</div>
      </div>
    </div>
  );
}

function AssistantBubble(props: {
  index: number;
  saveAsNote: any;
  text: string;
  typing: boolean;
  isInit?: boolean;
}) {
  return (
    <div id={`assistant_${props.index}`} className="chat chat-start ">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img src="https://static.socora.com/img/bg/assistant.png" />
        </div>
      </div>
      <div className="chat-header opacity-50">Assistant</div>
      {props.typing ? (
        <div className="chat-bubble flex items-center  bg-base-300 text-black dark:text-white">
          <div className="flex gap-2 items-center justify-center">
            <div className="">Typing...</div>
            <Spinner heigt="h-4"></Spinner>
          </div>
        </div>
      ) : (
        <div className="chat-bubble flex flex-col items-center bg-base-300 text-black dark:text-white ">
          <ReactQuill
            className=""
            value={props?.text}
            readOnly={true}
            modules={{
              // syntax: true,
              toolbar: false,
            }}
          ></ReactQuill>
          {!props.isInit ? (
            <div className="flex ml-auto ">
              <button
                onClick={() => props.saveAsNote(props.index)}
                className="btn btn-outline btn-xs"
              >
                SAVE AS NOTE
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
