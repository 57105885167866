import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  content_String_NotNull_minLength_1_maxLength_100000: any;
  courseId_String_NotNull_pattern_09afAF24: any;
  limit_Int_NotNull_min_1_max_20: any;
  noteId_String_NotNull_pattern_09af809af409af409af409af12: any;
  page_Int_NotNull_min_1: any;
  pathId_String_NotNull_pattern_09afAF24: any;
  rate_String_NotNull_pattern_15: any;
  sectionId_String_NotNull_pattern_09af809af409af409af409af12: any;
  subsectionId_String_NotNull_pattern_09af809af409af409af409af12: any;
  videoTime_Float_NotNull_max_12000: any;
};

export type AnswerInput = {
  answer: Array<InputMaybe<Scalars["String"]>>;
  nr: Scalars["Int"];
  type: Scalars["String"];
};

export type Badge = {
  __typename?: "Badge";
  _id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type BookmarkCourseInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
};

export type BookmarkCourseSectionInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  sectionId: Scalars["sectionId_String_NotNull_pattern_09af809af409af409af409af12"];
};

export type BookmarkCourseSubsectionInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  sectionId: Scalars["sectionId_String_NotNull_pattern_09af809af409af409af409af12"];
  subsectionId: Scalars["subsectionId_String_NotNull_pattern_09af809af409af409af409af12"];
};

export type BookmarkPathInput = {
  pathId: Scalars["pathId_String_NotNull_pattern_09afAF24"];
};

export type Course = {
  __typename?: "Course";
  _id?: Maybe<Scalars["String"]>;
  info?: Maybe<Info>;
  name?: Maybe<Scalars["String"]>;
  plan?: Maybe<Plan>;
  rating?: Maybe<Rating>;
  sections?: Maybe<Array<Maybe<Sections>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  userBookmark?: Maybe<Array<Maybe<CourseBookmark>>>;
  userCourseState?: Maybe<UserCourseState>;
};

export type CourseBookmark = {
  __typename?: "CourseBookmark";
  _id?: Maybe<Scalars["String"]>;
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars["String"]>;
};

export type CourseBookmarkRoot = {
  __typename?: "CourseBookmarkRoot";
  courseBookmarked?: Maybe<Scalars["Boolean"]>;
  sections?: Maybe<Array<Maybe<CourseSectionBookmark>>>;
  subsections?: Maybe<Array<Maybe<CourseSubsectionBookmark>>>;
};

export type CourseExam = {
  __typename?: "CourseExam";
  acquiredBadges?: Maybe<Array<Maybe<Badge>>>;
  courseId?: Maybe<Scalars["String"]>;
  exam?: Maybe<Array<Maybe<Question>>>;
  examCorrect?: Maybe<Scalars["Boolean"]>;
};

export type CourseFilterInput = {
  currentlyWatching?: InputMaybe<Scalars["Boolean"]>;
  difficultyIndex?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  finished?: InputMaybe<Scalars["Boolean"]>;
  homeCourses?: InputMaybe<Scalars["Boolean"]>;
  planIndex?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CourseSearch = {
  __typename?: "CourseSearch";
  _id?: Maybe<Scalars["String"]>;
  info?: Maybe<Info>;
  name?: Maybe<Scalars["String"]>;
  plan?: Maybe<Plan>;
  rating?: Maybe<Rating>;
  sections?: Maybe<Array<Maybe<Sections>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  userBookmark?: Maybe<Array<Maybe<CourseBookmark>>>;
  userCourseState?: Maybe<UserCourseState>;
};

export type CourseSearchState = {
  __typename?: "CourseSearchState";
  finishedPercentage?: Maybe<Scalars["Float"]>;
};

export type CourseSectionBookmark = {
  __typename?: "CourseSectionBookmark";
  _id?: Maybe<Scalars["String"]>;
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars["String"]>;
  sectionId?: Maybe<Scalars["String"]>;
};

export type CourseSubsectionBookmark = {
  __typename?: "CourseSubsectionBookmark";
  _id?: Maybe<Scalars["String"]>;
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars["String"]>;
  sectionId?: Maybe<Scalars["String"]>;
  subsectionId?: Maybe<Scalars["String"]>;
};

export type Description = {
  __typename?: "Description";
  about?: Maybe<Scalars["String"]>;
  objectives?: Maybe<Scalars["String"]>;
  preRequisites?: Maybe<Scalars["String"]>;
};

export type ExamSubmitInput = {
  answers: Array<InputMaybe<AnswerInput>>;
  courseId: Scalars["String"];
};

export type GroupedTags = {
  __typename?: "GroupedTags";
  _id?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Float"]>;
  tag?: Maybe<Tag>;
};

export type Info = {
  __typename?: "Info";
  description?: Maybe<Description>;
  difficulty?: Maybe<Scalars["String"]>;
  difficultyIndex?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["Int"]>;
  header?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["Float"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createCourseBookmark?: Maybe<Scalars["Boolean"]>;
  createCourseSectionBookmark?: Maybe<Scalars["Boolean"]>;
  createCourseSubsectionBookmark?: Maybe<Scalars["Boolean"]>;
  createPathBookmark?: Maybe<Scalars["Boolean"]>;
  createUserCourseNote?: Maybe<Note>;
  createUserNote?: Maybe<UserNote>;
  deleteCourseBookmark?: Maybe<Scalars["Boolean"]>;
  deleteCourseSectionBookmark?: Maybe<Scalars["Boolean"]>;
  deleteCourseSubsectionBookmark?: Maybe<Scalars["Boolean"]>;
  deletePathBookmark?: Maybe<Scalars["Boolean"]>;
  deleteUserCourseNote?: Maybe<Scalars["Boolean"]>;
  deleteUserNote?: Maybe<Scalars["Boolean"]>;
  saveUserCourseRate?: Maybe<Scalars["Boolean"]>;
  saveUserCourseState?: Maybe<UserCourseState>;
  saveUserPathRate?: Maybe<Scalars["Boolean"]>;
  submitCourseExam?: Maybe<CourseExam>;
  updateUserCourseNote?: Maybe<Scalars["Boolean"]>;
  updateUserNote?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateCourseBookmarkArgs = {
  input: BookmarkCourseInput;
};

export type MutationCreateCourseSectionBookmarkArgs = {
  input: BookmarkCourseSectionInput;
};

export type MutationCreateCourseSubsectionBookmarkArgs = {
  input: BookmarkCourseSubsectionInput;
};

export type MutationCreatePathBookmarkArgs = {
  input: BookmarkPathInput;
};

export type MutationCreateUserCourseNoteArgs = {
  input: UserCourseNoteInput;
};

export type MutationCreateUserNoteArgs = {
  input: UserNoteInput;
};

export type MutationDeleteCourseBookmarkArgs = {
  input?: InputMaybe<RemoveBookmarkInput>;
};

export type MutationDeleteCourseSectionBookmarkArgs = {
  input?: InputMaybe<RemoveBookmarkInput>;
};

export type MutationDeleteCourseSubsectionBookmarkArgs = {
  input?: InputMaybe<RemoveBookmarkInput>;
};

export type MutationDeletePathBookmarkArgs = {
  input?: InputMaybe<RemoveBookmarkInput>;
};

export type MutationDeleteUserCourseNoteArgs = {
  input: UserCourseNoteDeleteInput;
};

export type MutationDeleteUserNoteArgs = {
  id: Scalars["String"];
};

export type MutationSaveUserCourseRateArgs = {
  input: UserCourseRateInput;
};

export type MutationSaveUserCourseStateArgs = {
  userCourseStateInput: UserCourseStateInput;
};

export type MutationSaveUserPathRateArgs = {
  input: UserPathRateInput;
};

export type MutationSubmitCourseExamArgs = {
  examSubmitInput: ExamSubmitInput;
};

export type MutationUpdateUserCourseNoteArgs = {
  input: UserCourseNoteUpdateInput;
};

export type MutationUpdateUserNoteArgs = {
  input: UserNoteUpdateInput;
};

export type Note = {
  __typename?: "Note";
  content?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  subsectionId?: Maybe<Scalars["String"]>;
  subsectionTitle?: Maybe<Scalars["String"]>;
  videoTime?: Maybe<Scalars["Int"]>;
};

export type PaginatedBadges = {
  __typename?: "PaginatedBadges";
  docs?: Maybe<Array<Maybe<UserBadge>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedCourseBookmarks = {
  __typename?: "PaginatedCourseBookmarks";
  docs?: Maybe<Array<Maybe<CourseBookmark>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedCourseSearch = {
  __typename?: "PaginatedCourseSearch";
  docs?: Maybe<Array<Maybe<CourseSearch>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedCourseSectionBookmarks = {
  __typename?: "PaginatedCourseSectionBookmarks";
  docs?: Maybe<Array<Maybe<CourseSectionBookmark>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedCourseSubsectionBookmarks = {
  __typename?: "PaginatedCourseSubsectionBookmarks";
  docs?: Maybe<Array<Maybe<CourseSubsectionBookmark>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedCourses = {
  __typename?: "PaginatedCourses";
  docs?: Maybe<Array<Maybe<Course>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedNotes = {
  __typename?: "PaginatedNotes";
  docs?: Maybe<Array<Maybe<UserNote>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedPathBookmarks = {
  __typename?: "PaginatedPathBookmarks";
  docs?: Maybe<Array<Maybe<PathBookmark>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedPaths = {
  __typename?: "PaginatedPaths";
  docs?: Maybe<Array<Maybe<Path>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedTagsSearch = {
  __typename?: "PaginatedTagsSearch";
  docs?: Maybe<Array<Maybe<Tag>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedUserCourseNotes = {
  __typename?: "PaginatedUserCourseNotes";
  docs?: Maybe<Array<Maybe<UserCourseNote>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedUserCourseState = {
  __typename?: "PaginatedUserCourseState";
  docs?: Maybe<Array<Maybe<UserCourseState>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedUserPathState = {
  __typename?: "PaginatedUserPathState";
  docs?: Maybe<Array<Maybe<UserPathState>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginationInput = {
  limit: Scalars["limit_Int_NotNull_min_1_max_20"];
  page: Scalars["page_Int_NotNull_min_1"];
};

export type Path = {
  __typename?: "Path";
  _id?: Maybe<Scalars["String"]>;
  badges?: Maybe<Array<Maybe<Badge>>>;
  courses?: Maybe<Array<Maybe<Course>>>;
  info?: Maybe<Info>;
  labs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  name?: Maybe<Scalars["String"]>;
  rating?: Maybe<Rating>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  userBookmark?: Maybe<Array<Maybe<PathBookmark>>>;
  userPathState?: Maybe<UserPathState>;
};

export type PathBookmark = {
  __typename?: "PathBookmark";
  _id?: Maybe<Scalars["String"]>;
  path?: Maybe<Path>;
  pathId?: Maybe<Scalars["String"]>;
};

export type PathCourse = {
  __typename?: "PathCourse";
  _id?: Maybe<Scalars["String"]>;
  info?: Maybe<Info>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<PathCourseState>;
};

export type PathCourseState = {
  __typename?: "PathCourseState";
  finishedPercentage?: Maybe<Scalars["Float"]>;
};

export type PathCourses = {
  __typename?: "PathCourses";
  advanced?: Maybe<Array<Maybe<PathCourse>>>;
  beginner?: Maybe<Array<Maybe<PathCourse>>>;
  intermediate?: Maybe<Array<Maybe<PathCourse>>>;
};

export type PathsByCourseIdInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  limit: Scalars["limit_Int_NotNull_min_1_max_20"];
  page: Scalars["page_Int_NotNull_min_1"];
};

export type Plan = {
  __typename?: "Plan";
  index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  getAllCourseBookmarks?: Maybe<PaginatedCourseBookmarks>;
  getAllCourseSectionBookmarks?: Maybe<PaginatedCourseSectionBookmarks>;
  getAllCourseSubsectionBookmarks?: Maybe<PaginatedCourseSubsectionBookmarks>;
  getAllPathBookmarks?: Maybe<PaginatedPathBookmarks>;
  getBookmarksByCourseId?: Maybe<CourseBookmarkRoot>;
  getCourseById?: Maybe<CourseSearch>;
  getCourseExam?: Maybe<CourseExam>;
  getCourseVideoToken?: Maybe<VideoAccessToken>;
  getPathById?: Maybe<Path>;
  getPathsByCourseId?: Maybe<PaginatedPaths>;
  getUserCourseNotesByCourseId?: Maybe<UserCourseNote>;
  getUserCourseRateByCourseId?: Maybe<UserCourseRate>;
  getUserCourseStateByCourseId?: Maybe<UserCourseState>;
  getUserGroupedTagsCount?: Maybe<Array<Maybe<GroupedTags>>>;
  getUserNotes?: Maybe<PaginatedNotes>;
  getUserPathRateByPathId?: Maybe<UserPathRate>;
  getUserTopicActivity?: Maybe<Array<Maybe<UserTopicActivity>>>;
  getUserTotalWatchTime?: Maybe<Scalars["Float"]>;
  getUserViewTime?: Maybe<Array<Maybe<UserViewTime>>>;
  searchCourses?: Maybe<PaginatedCourseSearch>;
  searchPaths?: Maybe<PaginatedPaths>;
  searchTags?: Maybe<PaginatedTagsSearch>;
  userCourseNotes?: Maybe<PaginatedUserCourseNotes>;
};

export type QueryGetAllCourseBookmarksArgs = {
  input: PaginationInput;
};

export type QueryGetAllCourseSectionBookmarksArgs = {
  input: PaginationInput;
};

export type QueryGetAllCourseSubsectionBookmarksArgs = {
  input: PaginationInput;
};

export type QueryGetAllPathBookmarksArgs = {
  input: PaginationInput;
};

export type QueryGetBookmarksByCourseIdArgs = {
  courseId: Scalars["String"];
};

export type QueryGetCourseByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetCourseExamArgs = {
  courseId: Scalars["String"];
};

export type QueryGetCourseVideoTokenArgs = {
  input: VideoAccessTokenInput;
};

export type QueryGetPathByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetPathsByCourseIdArgs = {
  input?: InputMaybe<PathsByCourseIdInput>;
};

export type QueryGetUserCourseNotesByCourseIdArgs = {
  id: Scalars["String"];
};

export type QueryGetUserCourseRateByCourseIdArgs = {
  id: Scalars["String"];
};

export type QueryGetUserCourseStateByCourseIdArgs = {
  id: Scalars["String"];
};

export type QueryGetUserNotesArgs = {
  input: PaginationInput;
};

export type QueryGetUserPathRateByPathIdArgs = {
  id: Scalars["String"];
};

export type QueryGetUserViewTimeArgs = {
  from: Scalars["Float"];
  to: Scalars["Float"];
};

export type QuerySearchCoursesArgs = {
  filter?: InputMaybe<CourseFilterInput>;
  pagination: PaginationInput;
  searchString: Scalars["String"];
};

export type QuerySearchPathsArgs = {
  filter?: InputMaybe<CourseFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  searchString: Scalars["String"];
};

export type QuerySearchTagsArgs = {
  pagination: PaginationInput;
  searchString: Scalars["String"];
};

export type QueryUserCourseNotesArgs = {
  pagination: PaginationInput;
};

export type Question = {
  __typename?: "Question";
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  choiceAnswer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  correct?: Maybe<Scalars["Boolean"]>;
  nr?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Rating = {
  __typename?: "Rating";
  star1?: Maybe<Scalars["Int"]>;
  star2?: Maybe<Scalars["Int"]>;
  star3?: Maybe<Scalars["Int"]>;
  star4?: Maybe<Scalars["Int"]>;
  star5?: Maybe<Scalars["Int"]>;
};

export type RemoveBookmarkInput = {
  id: Scalars["String"];
};

export type Sections = {
  __typename?: "Sections";
  _id?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  subsections?: Maybe<Array<Maybe<Subsection>>>;
  title?: Maybe<Scalars["String"]>;
};

export type Subsection = {
  __typename?: "Subsection";
  _id?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  videoId?: Maybe<Scalars["String"]>;
};

export type Tag = {
  __typename?: "Tag";
  _id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type UserBadge = {
  __typename?: "UserBadge";
  badge?: Maybe<Badge>;
};

export type UserCourseNote = {
  __typename?: "UserCourseNote";
  _id?: Maybe<Scalars["String"]>;
  courseId?: Maybe<Scalars["String"]>;
  courseName?: Maybe<Scalars["String"]>;
  courseTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
};

export type UserCourseNoteDeleteInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  noteId: Scalars["noteId_String_NotNull_pattern_09af809af409af409af409af12"];
};

export type UserCourseNoteInput = {
  content: Scalars["content_String_NotNull_minLength_1_maxLength_100000"];
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  sectionId: Scalars["sectionId_String_NotNull_pattern_09af809af409af409af409af12"];
  subsectionId: Scalars["subsectionId_String_NotNull_pattern_09af809af409af409af409af12"];
  videoTime: Scalars["Int"];
};

export type UserCourseNoteUpdateInput = {
  content: Scalars["content_String_NotNull_minLength_1_maxLength_100000"];
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  noteId: Scalars["noteId_String_NotNull_pattern_09af809af409af409af409af12"];
};

export type UserCourseRate = {
  __typename?: "UserCourseRate";
  courseId?: Maybe<Scalars["String"]>;
  rate?: Maybe<Scalars["Int"]>;
};

export type UserCourseRateInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  rate: Scalars["rate_String_NotNull_pattern_15"];
};

export type UserCourseState = {
  __typename?: "UserCourseState";
  course?: Maybe<Course>;
  courseId?: Maybe<Scalars["String"]>;
  courseName?: Maybe<Scalars["String"]>;
  courseVideosCount?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Float"]>;
  finalized?: Maybe<Scalars["Boolean"]>;
  finished?: Maybe<Scalars["Boolean"]>;
  finishedVideos?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Float"]>;
  videosState?: Maybe<Array<Maybe<VideoState>>>;
};

export type UserCourseStateInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  finished: Scalars["Boolean"];
  subsectionId: Scalars["subsectionId_String_NotNull_pattern_09af809af409af409af409af12"];
  videoTime: Scalars["videoTime_Float_NotNull_max_12000"];
};

export type UserNote = {
  __typename?: "UserNote";
  _id?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
};

export type UserNoteInput = {
  content: Scalars["content_String_NotNull_minLength_1_maxLength_100000"];
};

export type UserNoteUpdateInput = {
  content: Scalars["content_String_NotNull_minLength_1_maxLength_100000"];
  noteId: Scalars["String"];
};

export type UserPathRate = {
  __typename?: "UserPathRate";
  courseId?: Maybe<Scalars["String"]>;
  pathId?: Maybe<Scalars["String"]>;
  rate?: Maybe<Scalars["Int"]>;
};

export type UserPathRateInput = {
  pathId: Scalars["pathId_String_NotNull_pattern_09afAF24"];
  rate: Scalars["rate_String_NotNull_pattern_15"];
};

export type UserPathState = {
  __typename?: "UserPathState";
  acquiredBadges?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdAt?: Maybe<Scalars["Float"]>;
  finished?: Maybe<Scalars["Boolean"]>;
  finishedUnits?: Maybe<Scalars["Int"]>;
  path?: Maybe<Path>;
  pathId?: Maybe<Scalars["String"]>;
  pathName?: Maybe<Scalars["String"]>;
  pathUnits?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Float"]>;
};

export type UserTopicActivity = {
  __typename?: "UserTopicActivity";
  _id?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type UserViewTime = {
  __typename?: "UserViewTime";
  x?: Maybe<Scalars["Float"]>;
  y?: Maybe<Scalars["Float"]>;
};

export type VideoAccessToken = {
  __typename?: "VideoAccessToken";
  accessToken?: Maybe<Scalars["String"]>;
};

export type VideoAccessTokenInput = {
  courseId: Scalars["courseId_String_NotNull_pattern_09afAF24"];
  videoId: Scalars["String"];
};

export type VideoState = {
  __typename?: "VideoState";
  finished?: Maybe<Scalars["Boolean"]>;
  subsectionId?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["Float"]>;
  videoTime?: Maybe<Scalars["Float"]>;
};

export type CourseAboutPageDataQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type CourseAboutPageDataQuery = {
  __typename?: "Query";
  getCourseById?: {
    __typename?: "CourseSearch";
    _id?: string | null;
    name?: string | null;
    plan?: {
      __typename?: "Plan";
      index?: number | null;
      name?: string | null;
    } | null;
    rating?: {
      __typename?: "Rating";
      star1?: number | null;
      star2?: number | null;
      star3?: number | null;
      star4?: number | null;
      star5?: number | null;
    } | null;
    sections?: Array<{
      __typename?: "Sections";
      _id?: string | null;
      title?: string | null;
      duration?: number | null;
      subsections?: Array<{
        __typename?: "Subsection";
        _id?: string | null;
        title?: string | null;
        duration?: number | null;
        videoId?: string | null;
      } | null> | null;
    } | null> | null;
    tags?: Array<{
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null> | null;
    info?: {
      __typename?: "Info";
      header?: string | null;
      releaseDate?: number | null;
      difficulty?: string | null;
      difficultyIndex?: number | null;
      duration?: number | null;
      description?: {
        __typename?: "Description";
        about?: string | null;
        objectives?: string | null;
      } | null;
    } | null;
    state?: {
      __typename?: "UserCourseState";
      finished?: boolean | null;
      finalized?: boolean | null;
      finishedUnits?: number | null;
      totalUnits?: number | null;
      videosState?: Array<{
        __typename?: "VideoState";
        subsectionId?: string | null;
        finished?: boolean | null;
      } | null> | null;
    } | null;
    bookmark?: Array<{
      __typename?: "CourseBookmark";
      courseId?: string | null;
    } | null> | null;
  } | null;
  getBookmarksByCourseId?: {
    __typename?: "CourseBookmarkRoot";
    sections?: Array<{
      __typename?: "CourseSectionBookmark";
      sectionId?: string | null;
    } | null> | null;
    subsections?: Array<{
      __typename?: "CourseSubsectionBookmark";
      subsectionId?: string | null;
    } | null> | null;
  } | null;
  userRate?: {
    __typename?: "UserCourseRate";
    courseId?: string | null;
    rate?: number | null;
  } | null;
};

export type SearchAndFilterCoursesQueryVariables = Exact<{
  searchString: Scalars["String"];
  filter?: InputMaybe<CourseFilterInput>;
  pagination: PaginationInput;
}>;

export type SearchAndFilterCoursesQuery = {
  __typename?: "Query";
  search?: {
    __typename?: "PaginatedCourseSearch";
    totalDocs?: number | null;
    totalPages?: number | null;
    page?: number | null;
    docs?: Array<{
      __typename?: "CourseSearch";
      _id?: string | null;
      name?: string | null;
      plan?: {
        __typename?: "Plan";
        index?: number | null;
        name?: string | null;
      } | null;
      rating?: {
        __typename?: "Rating";
        star1?: number | null;
        star2?: number | null;
        star3?: number | null;
        star4?: number | null;
        star5?: number | null;
      } | null;
      tags?: Array<{
        __typename?: "Tag";
        _id?: string | null;
        value?: string | null;
      } | null> | null;
      info?: {
        __typename?: "Info";
        header?: string | null;
        releaseDate?: number | null;
        difficulty?: string | null;
        difficultyIndex?: number | null;
        duration?: number | null;
        description?: {
          __typename?: "Description";
          about?: string | null;
        } | null;
      } | null;
      state?: {
        __typename?: "UserCourseState";
        finished?: boolean | null;
        finalized?: boolean | null;
        updatedAt?: number | null;
        finishedUnits?: number | null;
        totalUnits?: number | null;
      } | null;
      bookmark?: Array<{
        __typename?: "CourseBookmark";
        courseId?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GlobalSearchAutocompleteQueryVariables = Exact<{
  searchString: Scalars["String"];
  filter?: InputMaybe<CourseFilterInput>;
  page: Scalars["page_Int_NotNull_min_1"];
  limit: Scalars["limit_Int_NotNull_min_1_max_20"];
}>;

export type GlobalSearchAutocompleteQuery = {
  __typename?: "Query";
  searchCourses?: {
    __typename?: "PaginatedCourseSearch";
    totalDocs?: number | null;
    totalPages?: number | null;
    page?: number | null;
    docs?: Array<{
      __typename?: "CourseSearch";
      _id?: string | null;
      name?: string | null;
      plan?: {
        __typename?: "Plan";
        index?: number | null;
        name?: string | null;
      } | null;
      rating?: {
        __typename?: "Rating";
        star1?: number | null;
        star2?: number | null;
        star3?: number | null;
        star4?: number | null;
        star5?: number | null;
      } | null;
      tags?: Array<{
        __typename?: "Tag";
        _id?: string | null;
        value?: string | null;
      } | null> | null;
      info?: {
        __typename?: "Info";
        releaseDate?: number | null;
        difficulty?: string | null;
        difficultyIndex?: number | null;
        duration?: number | null;
        description?: {
          __typename?: "Description";
          about?: string | null;
        } | null;
      } | null;
      state?: {
        __typename?: "UserCourseState";
        finished?: boolean | null;
        finalized?: boolean | null;
        finishedUnits?: number | null;
        totalUnits?: number | null;
      } | null;
      bookmark?: Array<{
        __typename?: "CourseBookmark";
        courseId?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  searchPaths?: {
    __typename?: "PaginatedPaths";
    totalDocs?: number | null;
    totalPages?: number | null;
    page?: number | null;
    docs?: Array<{
      __typename?: "Path";
      _id?: string | null;
      name?: string | null;
      rating?: {
        __typename?: "Rating";
        star1?: number | null;
        star2?: number | null;
        star3?: number | null;
        star4?: number | null;
        star5?: number | null;
      } | null;
      tags?: Array<{
        __typename?: "Tag";
        _id?: string | null;
        value?: string | null;
      } | null> | null;
      info?: {
        __typename?: "Info";
        releaseDate?: number | null;
        difficulty?: string | null;
        difficultyIndex?: number | null;
        duration?: number | null;
        description?: {
          __typename?: "Description";
          about?: string | null;
        } | null;
      } | null;
      state?: {
        __typename?: "UserPathState";
        finished?: boolean | null;
        finishedUnits?: number | null;
        totalUnits?: number | null;
      } | null;
      bookmark?: Array<{
        __typename?: "PathBookmark";
        pathId?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SearchAndFilterPathsQueryVariables = Exact<{
  searchString: Scalars["String"];
  filter?: InputMaybe<CourseFilterInput>;
  pagination: PaginationInput;
}>;

export type SearchAndFilterPathsQuery = {
  __typename?: "Query";
  search?: {
    __typename?: "PaginatedPaths";
    totalDocs?: number | null;
    totalPages?: number | null;
    page?: number | null;
    docs?: Array<{
      __typename?: "Path";
      _id?: string | null;
      name?: string | null;
      rating?: {
        __typename?: "Rating";
        star1?: number | null;
        star2?: number | null;
        star3?: number | null;
        star4?: number | null;
        star5?: number | null;
      } | null;
      tags?: Array<{
        __typename?: "Tag";
        _id?: string | null;
        value?: string | null;
      } | null> | null;
      info?: {
        __typename?: "Info";
        releaseDate?: number | null;
        difficulty?: string | null;
        difficultyIndex?: number | null;
        duration?: number | null;
        description?: {
          __typename?: "Description";
          about?: string | null;
        } | null;
      } | null;
      state?: {
        __typename?: "UserPathState";
        finished?: boolean | null;
        finishedUnits?: number | null;
        totalUnits?: number | null;
      } | null;
      bookmark?: Array<{
        __typename?: "PathBookmark";
        pathId?: string | null;
      } | null> | null;
      badges?: Array<{
        __typename?: "Badge";
        _id?: string | null;
        name?: string | null;
        description?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type PathAboutPageDataQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type PathAboutPageDataQuery = {
  __typename?: "Query";
  getPathById?: {
    __typename?: "Path";
    _id?: string | null;
    name?: string | null;
    labs?: Array<string | null> | null;
    rating?: {
      __typename?: "Rating";
      star5?: number | null;
      star4?: number | null;
      star3?: number | null;
      star2?: number | null;
      star1?: number | null;
    } | null;
    tags?: Array<{
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null> | null;
    info?: {
      __typename?: "Info";
      header?: string | null;
      releaseDate?: number | null;
      duration?: number | null;
      description?: {
        __typename?: "Description";
        about?: string | null;
        objectives?: string | null;
        preRequisites?: string | null;
      } | null;
    } | null;
    badges?: Array<{
      __typename?: "Badge";
      _id?: string | null;
      name?: string | null;
      description?: string | null;
    } | null> | null;
    state?: {
      __typename?: "UserPathState";
      finished?: boolean | null;
      finishedUnits?: number | null;
      totalUnits?: number | null;
    } | null;
    bookmark?: Array<{
      __typename?: "PathBookmark";
      pathId?: string | null;
    } | null> | null;
    courses?: Array<{
      __typename?: "Course";
      _id?: string | null;
      name?: string | null;
      tags?: Array<{
        __typename?: "Tag";
        _id?: string | null;
        value?: string | null;
      } | null> | null;
      info?: {
        __typename?: "Info";
        header?: string | null;
        difficulty?: string | null;
        duration?: number | null;
        releaseDate?: number | null;
      } | null;
      plan?: {
        __typename?: "Plan";
        index?: number | null;
        name?: string | null;
      } | null;
      rating?: {
        __typename?: "Rating";
        star5?: number | null;
        star4?: number | null;
        star3?: number | null;
        star2?: number | null;
        star1?: number | null;
      } | null;
      state?: {
        __typename?: "UserCourseState";
        finished?: boolean | null;
        finalized?: boolean | null;
        finishedUnits?: number | null;
        totalUnits?: number | null;
      } | null;
    } | null> | null;
  } | null;
  userRate?: {
    __typename?: "UserPathRate";
    pathId?: string | null;
    rate?: number | null;
  } | null;
};

export type CoursePlayerPageDataQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type CoursePlayerPageDataQuery = {
  __typename?: "Query";
  getCourseById?: {
    __typename?: "CourseSearch";
    _id?: string | null;
    name?: string | null;
    sections?: Array<{
      __typename?: "Sections";
      _id?: string | null;
      title?: string | null;
      duration?: number | null;
      subsections?: Array<{
        __typename?: "Subsection";
        _id?: string | null;
        title?: string | null;
        duration?: number | null;
        videoId?: string | null;
      } | null> | null;
    } | null> | null;
    tags?: Array<{
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null> | null;
    plan?: {
      __typename?: "Plan";
      index?: number | null;
      name?: string | null;
    } | null;
  } | null;
  getUserCourseStateByCourseId?: {
    __typename?: "UserCourseState";
    courseId?: string | null;
    finishedVideos?: number | null;
    courseVideosCount?: number | null;
    finished?: boolean | null;
    finalized?: boolean | null;
    videosState?: Array<{
      __typename?: "VideoState";
      subsectionId?: string | null;
      videoTime?: number | null;
      finished?: boolean | null;
      timestamp?: number | null;
    } | null> | null;
  } | null;
  getUserCourseNotesByCourseId?: {
    __typename?: "UserCourseNote";
    _id?: string | null;
    courseId?: string | null;
    courseName?: string | null;
    notes?: Array<{
      __typename?: "Note";
      id?: string | null;
      subsectionId?: string | null;
      subsectionTitle?: string | null;
      content?: string | null;
      videoTime?: number | null;
    } | null> | null;
  } | null;
  getBookmarksByCourseId?: {
    __typename?: "CourseBookmarkRoot";
    sections?: Array<{
      __typename?: "CourseSectionBookmark";
      sectionId?: string | null;
    } | null> | null;
    subsections?: Array<{
      __typename?: "CourseSubsectionBookmark";
      subsectionId?: string | null;
    } | null> | null;
  } | null;
};

export type GenerateCourseVideoTokenQueryVariables = Exact<{
  input: VideoAccessTokenInput;
}>;

export type GenerateCourseVideoTokenQuery = {
  __typename?: "Query";
  getCourseVideoToken?: {
    __typename?: "VideoAccessToken";
    accessToken?: string | null;
  } | null;
};

export type SaveUserCourseStateMutationVariables = Exact<{
  userCourseStateInput: UserCourseStateInput;
}>;

export type SaveUserCourseStateMutation = {
  __typename?: "Mutation";
  saveUserCourseState?: {
    __typename?: "UserCourseState";
    finished?: boolean | null;
    finalized?: boolean | null;
    videosState?: Array<{
      __typename?: "VideoState";
      finished?: boolean | null;
      subsectionId?: string | null;
      videoTime?: number | null;
      timestamp?: number | null;
    } | null> | null;
  } | null;
};

export type CreateUserCourseNoteMutationVariables = Exact<{
  input: UserCourseNoteInput;
}>;

export type CreateUserCourseNoteMutation = {
  __typename?: "Mutation";
  createUserCourseNote?: {
    __typename?: "Note";
    id?: string | null;
    subsectionId?: string | null;
    subsectionTitle?: string | null;
    content?: string | null;
    videoTime?: number | null;
  } | null;
};

export type UpdateUserCourseNoteMutationVariables = Exact<{
  input: UserCourseNoteUpdateInput;
}>;

export type UpdateUserCourseNoteMutation = {
  __typename?: "Mutation";
  updateUserCourseNote?: boolean | null;
};

export type DeleteUserCourseNoteMutationVariables = Exact<{
  input: UserCourseNoteDeleteInput;
}>;

export type DeleteUserCourseNoteMutation = {
  __typename?: "Mutation";
  deleteUserCourseNote?: boolean | null;
};

export type SubmitCourseExamMutationVariables = Exact<{
  courseId: Scalars["String"];
  answers: Array<InputMaybe<AnswerInput>> | InputMaybe<AnswerInput>;
}>;

export type SubmitCourseExamMutation = {
  __typename?: "Mutation";
  submitCourseExam?: {
    __typename?: "CourseExam";
    courseId?: string | null;
    examCorrect?: boolean | null;
    exam?: Array<{
      __typename?: "Question";
      nr?: number | null;
      type?: string | null;
      answer?: Array<string | null> | null;
      question?: string | null;
      choiceAnswer?: Array<string | null> | null;
      correct?: boolean | null;
    } | null> | null;
    acquiredBadges?: Array<{
      __typename?: "Badge";
      _id?: string | null;
      name?: string | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type GetCourseExamQueryVariables = Exact<{
  courseId: Scalars["String"];
}>;

export type GetCourseExamQuery = {
  __typename?: "Query";
  getCourseExam?: {
    __typename?: "CourseExam";
    courseId?: string | null;
    exam?: Array<{
      __typename?: "Question";
      nr?: number | null;
      type?: string | null;
      answer?: Array<string | null> | null;
      question?: string | null;
      choiceAnswer?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type GetUserViewTimeQueryVariables = Exact<{
  from: Scalars["Float"];
  to: Scalars["Float"];
}>;

export type GetUserViewTimeQuery = {
  __typename?: "Query";
  getUserViewTime?: Array<{
    __typename?: "UserViewTime";
    x?: number | null;
    y?: number | null;
  } | null> | null;
};

export type TotalWatchTimeQueryVariables = Exact<{ [key: string]: never }>;

export type TotalWatchTimeQuery = {
  __typename?: "Query";
  getUserTotalWatchTime?: number | null;
};

export type TopicActivityQueryVariables = Exact<{ [key: string]: never }>;

export type TopicActivityQuery = {
  __typename?: "Query";
  getUserTopicActivity?: Array<{
    __typename?: "UserTopicActivity";
    _id?: string | null;
    total?: number | null;
  } | null> | null;
};

export type UserStatsQueryVariables = Exact<{
  months: Scalars["Int"];
}>;

export type UserStatsQuery = {
  __typename?: "Query";
  getUserTotalWatchTime?: number | null;
  getUserTopicActivity?: Array<{
    __typename?: "UserTopicActivity";
    _id?: string | null;
    total?: number | null;
  } | null> | null;
};

export type SaveUserCourseRateMutationVariables = Exact<{
  input: UserCourseRateInput;
}>;

export type SaveUserCourseRateMutation = {
  __typename?: "Mutation";
  saveUserCourseRate?: boolean | null;
};

export type GetUserGroupedTagsCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserGroupedTagsCountQuery = {
  __typename?: "Query";
  getUserGroupedTagsCount?: Array<{
    __typename?: "GroupedTags";
    _id?: string | null;
    count?: number | null;
    tag?: {
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null;
  } | null> | null;
};

export type CreatePathBookmarkMutationVariables = Exact<{
  input: BookmarkPathInput;
}>;

export type CreatePathBookmarkMutation = {
  __typename?: "Mutation";
  createPathBookmark?: boolean | null;
};

export type DeletePathBookmarkMutationVariables = Exact<{
  input?: InputMaybe<RemoveBookmarkInput>;
}>;

export type DeletePathBookmarkMutation = {
  __typename?: "Mutation";
  deletePathBookmark?: boolean | null;
};

export type GetAllPathBookmarksQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type GetAllPathBookmarksQuery = {
  __typename?: "Query";
  getAllPathBookmarks?: {
    __typename?: "PaginatedPathBookmarks";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "PathBookmark";
      _id?: string | null;
      path?: {
        __typename?: "Path";
        name?: string | null;
        _id?: string | null;
        info?: {
          __typename?: "Info";
          difficulty?: string | null;
          difficultyIndex?: number | null;
          releaseDate?: number | null;
          duration?: number | null;
        } | null;
        tags?: Array<{
          __typename?: "Tag";
          _id?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SaveUserPathRateMutationVariables = Exact<{
  input: UserPathRateInput;
}>;

export type SaveUserPathRateMutation = {
  __typename?: "Mutation";
  saveUserPathRate?: boolean | null;
};

export type UserCourseNotesQueryVariables = Exact<{
  pagination: PaginationInput;
}>;

export type UserCourseNotesQuery = {
  __typename?: "Query";
  userCourseNotes?: {
    __typename?: "PaginatedUserCourseNotes";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "UserCourseNote";
      _id?: string | null;
      courseId?: string | null;
      courseName?: string | null;
      courseTags?: Array<string | null> | null;
      notes?: Array<{
        __typename?: "Note";
        id?: string | null;
        subsectionId?: string | null;
        subsectionTitle?: string | null;
        content?: string | null;
        videoTime?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateCourseBookmarkMutationVariables = Exact<{
  input: BookmarkCourseInput;
}>;

export type CreateCourseBookmarkMutation = {
  __typename?: "Mutation";
  createCourseBookmark?: boolean | null;
};

export type CreateCourseSectionBookmarkMutationVariables = Exact<{
  input: BookmarkCourseSectionInput;
}>;

export type CreateCourseSectionBookmarkMutation = {
  __typename?: "Mutation";
  createCourseSectionBookmark?: boolean | null;
};

export type CreateCourseSubsectionBookmarkMutationVariables = Exact<{
  input: BookmarkCourseSubsectionInput;
}>;

export type CreateCourseSubsectionBookmarkMutation = {
  __typename?: "Mutation";
  createCourseSubsectionBookmark?: boolean | null;
};

export type DeleteCourseBookmarkMutationVariables = Exact<{
  input?: InputMaybe<RemoveBookmarkInput>;
}>;

export type DeleteCourseBookmarkMutation = {
  __typename?: "Mutation";
  deleteCourseBookmark?: boolean | null;
};

export type DeleteCourseSectionBookmarkMutationVariables = Exact<{
  input?: InputMaybe<RemoveBookmarkInput>;
}>;

export type DeleteCourseSectionBookmarkMutation = {
  __typename?: "Mutation";
  deleteCourseSectionBookmark?: boolean | null;
};

export type DeleteCourseSubsectionBookmarkMutationVariables = Exact<{
  input?: InputMaybe<RemoveBookmarkInput>;
}>;

export type DeleteCourseSubsectionBookmarkMutation = {
  __typename?: "Mutation";
  deleteCourseSubsectionBookmark?: boolean | null;
};

export type GetAllCourseBookmarksQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type GetAllCourseBookmarksQuery = {
  __typename?: "Query";
  getAllCourseBookmarks?: {
    __typename?: "PaginatedCourseBookmarks";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "CourseBookmark";
      _id?: string | null;
      course?: {
        __typename?: "Course";
        name?: string | null;
        _id?: string | null;
        info?: {
          __typename?: "Info";
          difficulty?: string | null;
          difficultyIndex?: number | null;
          releaseDate?: number | null;
          duration?: number | null;
        } | null;
        tags?: Array<{
          __typename?: "Tag";
          _id?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetAllCourseSectionBookmarksQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type GetAllCourseSectionBookmarksQuery = {
  __typename?: "Query";
  getAllCourseSectionBookmarks?: {
    __typename?: "PaginatedCourseSectionBookmarks";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "CourseSectionBookmark";
      _id?: string | null;
      course?: {
        __typename?: "Course";
        name?: string | null;
        _id?: string | null;
        info?: {
          __typename?: "Info";
          difficulty?: string | null;
          difficultyIndex?: number | null;
          releaseDate?: number | null;
          duration?: number | null;
        } | null;
        tags?: Array<{
          __typename?: "Tag";
          _id?: string | null;
          value?: string | null;
        } | null> | null;
        sections?: Array<{
          __typename?: "Sections";
          _id?: string | null;
          title?: string | null;
          duration?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetAllCourseSubsectionBookmarksQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type GetAllCourseSubsectionBookmarksQuery = {
  __typename?: "Query";
  getAllCourseSubsectionBookmarks?: {
    __typename?: "PaginatedCourseSubsectionBookmarks";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "CourseSubsectionBookmark";
      _id?: string | null;
      course?: {
        __typename?: "Course";
        name?: string | null;
        _id?: string | null;
        info?: {
          __typename?: "Info";
          difficulty?: string | null;
          difficultyIndex?: number | null;
          releaseDate?: number | null;
          duration?: number | null;
        } | null;
        tags?: Array<{
          __typename?: "Tag";
          _id?: string | null;
          value?: string | null;
        } | null> | null;
        sections?: Array<{
          __typename?: "Sections";
          _id?: string | null;
          title?: string | null;
          duration?: number | null;
          subsections?: Array<{
            __typename?: "Subsection";
            _id?: string | null;
            title?: string | null;
            duration?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type BookmarksCountQueryVariables = Exact<{ [key: string]: never }>;

export type BookmarksCountQuery = {
  __typename?: "Query";
  getAllPathBookmarks?: {
    __typename?: "PaginatedPathBookmarks";
    totalDocs?: number | null;
  } | null;
  getAllCourseBookmarks?: {
    __typename?: "PaginatedCourseBookmarks";
    totalDocs?: number | null;
  } | null;
  getAllCourseSubsectionBookmarks?: {
    __typename?: "PaginatedCourseSubsectionBookmarks";
    totalDocs?: number | null;
  } | null;
  getAllCourseSectionBookmarks?: {
    __typename?: "PaginatedCourseSectionBookmarks";
    totalDocs?: number | null;
  } | null;
};

export type SearchTagsQueryVariables = Exact<{
  searchString: Scalars["String"];
  pagination: PaginationInput;
}>;

export type SearchTagsQuery = {
  __typename?: "Query";
  searchTags?: {
    __typename?: "PaginatedTagsSearch";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export const CourseAboutPageDataDocument = gql`
  query courseAboutPageData($id: String!) {
    getCourseById(id: $id) {
      _id
      name
      plan {
        index
        name
      }
      rating {
        star1
        star2
        star3
        star4
        star5
      }
      sections {
        _id
        title
        duration
        subsections {
          _id
          title
          duration
          videoId
        }
      }
      tags {
        _id
        value
      }
      info {
        description {
          about
          objectives
        }
        header
        releaseDate
        difficulty
        difficultyIndex
        duration
      }
      state: userCourseState {
        finished
        finalized
        finishedUnits: finishedVideos
        totalUnits: courseVideosCount
        videosState {
          subsectionId
          finished
        }
      }
      bookmark: userBookmark {
        courseId
      }
    }
    getBookmarksByCourseId(courseId: $id) {
      sections {
        sectionId
      }
      subsections {
        subsectionId
      }
    }
    userRate: getUserCourseRateByCourseId(id: $id) {
      courseId
      rate
    }
  }
`;

/**
 * __useCourseAboutPageDataQuery__
 *
 * To run a query within a React component, call `useCourseAboutPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAboutPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAboutPageDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseAboutPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseAboutPageDataQuery,
    CourseAboutPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseAboutPageDataQuery,
    CourseAboutPageDataQueryVariables
  >(CourseAboutPageDataDocument, options);
}
export function useCourseAboutPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseAboutPageDataQuery,
    CourseAboutPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseAboutPageDataQuery,
    CourseAboutPageDataQueryVariables
  >(CourseAboutPageDataDocument, options);
}
export type CourseAboutPageDataQueryHookResult = ReturnType<
  typeof useCourseAboutPageDataQuery
>;
export type CourseAboutPageDataLazyQueryHookResult = ReturnType<
  typeof useCourseAboutPageDataLazyQuery
>;
export type CourseAboutPageDataQueryResult = Apollo.QueryResult<
  CourseAboutPageDataQuery,
  CourseAboutPageDataQueryVariables
>;
export const SearchAndFilterCoursesDocument = gql`
  query searchAndFilterCourses(
    $searchString: String!
    $filter: CourseFilterInput
    $pagination: PaginationInput!
  ) {
    search: searchCourses(
      searchString: $searchString
      filter: $filter
      pagination: $pagination
    ) {
      docs {
        _id
        name
        plan {
          index
          name
        }
        rating {
          star1
          star2
          star3
          star4
          star5
        }
        tags {
          _id
          value
        }
        info {
          header
          description {
            about
          }
          releaseDate
          difficulty
          difficultyIndex
          duration
        }
        state: userCourseState {
          finished
          finalized
          updatedAt
          finishedUnits: finishedVideos
          totalUnits: courseVideosCount
        }
        bookmark: userBookmark {
          courseId
        }
      }
      totalDocs
      totalPages
      page
    }
  }
`;

/**
 * __useSearchAndFilterCoursesQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterCoursesQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchAndFilterCoursesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAndFilterCoursesQuery,
    SearchAndFilterCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAndFilterCoursesQuery,
    SearchAndFilterCoursesQueryVariables
  >(SearchAndFilterCoursesDocument, options);
}
export function useSearchAndFilterCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAndFilterCoursesQuery,
    SearchAndFilterCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAndFilterCoursesQuery,
    SearchAndFilterCoursesQueryVariables
  >(SearchAndFilterCoursesDocument, options);
}
export type SearchAndFilterCoursesQueryHookResult = ReturnType<
  typeof useSearchAndFilterCoursesQuery
>;
export type SearchAndFilterCoursesLazyQueryHookResult = ReturnType<
  typeof useSearchAndFilterCoursesLazyQuery
>;
export type SearchAndFilterCoursesQueryResult = Apollo.QueryResult<
  SearchAndFilterCoursesQuery,
  SearchAndFilterCoursesQueryVariables
>;
export const GlobalSearchAutocompleteDocument = gql`
  query globalSearchAutocomplete(
    $searchString: String!
    $filter: CourseFilterInput
    $page: page_Int_NotNull_min_1!
    $limit: limit_Int_NotNull_min_1_max_20!
  ) {
    searchCourses(
      searchString: $searchString
      filter: $filter
      pagination: { page: $page, limit: $limit }
    ) {
      docs {
        _id
        name
        plan {
          index
          name
        }
        rating {
          star1
          star2
          star3
          star4
          star5
        }
        tags {
          _id
          value
        }
        info {
          description {
            about
          }
          releaseDate
          difficulty
          difficultyIndex
          duration
        }
        state: userCourseState {
          finished
          finalized
          finishedUnits: finishedVideos
          totalUnits: courseVideosCount
        }
        bookmark: userBookmark {
          courseId
        }
      }
      totalDocs
      totalPages
      page
    }
    searchPaths(
      searchString: $searchString
      filter: $filter
      pagination: { page: $page, limit: $limit }
    ) {
      docs {
        _id
        name
        rating {
          star1
          star2
          star3
          star4
          star5
        }
        tags {
          _id
          value
        }
        info {
          description {
            about
          }
          releaseDate
          difficulty
          difficultyIndex
          duration
        }
        state: userPathState {
          finished
          finishedUnits
          totalUnits: pathUnits
        }
        bookmark: userBookmark {
          pathId
        }
      }
      totalDocs
      totalPages
      page
    }
  }
`;

/**
 * __useGlobalSearchAutocompleteQuery__
 *
 * To run a query within a React component, call `useGlobalSearchAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchAutocompleteQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGlobalSearchAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GlobalSearchAutocompleteQuery,
    GlobalSearchAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GlobalSearchAutocompleteQuery,
    GlobalSearchAutocompleteQueryVariables
  >(GlobalSearchAutocompleteDocument, options);
}
export function useGlobalSearchAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalSearchAutocompleteQuery,
    GlobalSearchAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GlobalSearchAutocompleteQuery,
    GlobalSearchAutocompleteQueryVariables
  >(GlobalSearchAutocompleteDocument, options);
}
export type GlobalSearchAutocompleteQueryHookResult = ReturnType<
  typeof useGlobalSearchAutocompleteQuery
>;
export type GlobalSearchAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGlobalSearchAutocompleteLazyQuery
>;
export type GlobalSearchAutocompleteQueryResult = Apollo.QueryResult<
  GlobalSearchAutocompleteQuery,
  GlobalSearchAutocompleteQueryVariables
>;
export const SearchAndFilterPathsDocument = gql`
  query searchAndFilterPaths(
    $searchString: String!
    $filter: CourseFilterInput
    $pagination: PaginationInput!
  ) {
    search: searchPaths(
      searchString: $searchString
      filter: $filter
      pagination: $pagination
    ) {
      docs {
        _id
        name
        rating {
          star1
          star2
          star3
          star4
          star5
        }
        tags {
          _id
          value
        }
        info {
          description {
            about
          }
          releaseDate
          difficulty
          difficultyIndex
          duration
        }
        state: userPathState {
          finished
          finishedUnits
          totalUnits: pathUnits
        }
        bookmark: userBookmark {
          pathId
        }
        badges: badges {
          _id
          name
          description
        }
      }
      totalDocs
      totalPages
      page
    }
  }
`;

/**
 * __useSearchAndFilterPathsQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterPathsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchAndFilterPathsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAndFilterPathsQuery,
    SearchAndFilterPathsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAndFilterPathsQuery,
    SearchAndFilterPathsQueryVariables
  >(SearchAndFilterPathsDocument, options);
}
export function useSearchAndFilterPathsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAndFilterPathsQuery,
    SearchAndFilterPathsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAndFilterPathsQuery,
    SearchAndFilterPathsQueryVariables
  >(SearchAndFilterPathsDocument, options);
}
export type SearchAndFilterPathsQueryHookResult = ReturnType<
  typeof useSearchAndFilterPathsQuery
>;
export type SearchAndFilterPathsLazyQueryHookResult = ReturnType<
  typeof useSearchAndFilterPathsLazyQuery
>;
export type SearchAndFilterPathsQueryResult = Apollo.QueryResult<
  SearchAndFilterPathsQuery,
  SearchAndFilterPathsQueryVariables
>;
export const PathAboutPageDataDocument = gql`
  query pathAboutPageData($id: String!) {
    getPathById(id: $id) {
      _id
      name
      rating {
        star5
        star4
        star3
        star2
        star1
      }
      tags {
        _id
        value
      }
      info {
        header
        releaseDate
        duration
        description {
          about
          objectives
          preRequisites
        }
      }
      badges: badges {
        _id
        name
        description
      }
      labs
      state: userPathState {
        finished
        finishedUnits: finishedUnits
        totalUnits: pathUnits
      }
      bookmark: userBookmark {
        pathId
      }
      courses {
        _id
        name
        tags {
          _id
          value
        }
        info {
          header
          difficulty
          duration
          releaseDate
        }
        plan {
          index
          name
        }
        rating {
          star5
          star4
          star3
          star2
          star1
        }
        state: userCourseState {
          finished
          finalized
          finishedUnits: finishedVideos
          totalUnits: courseVideosCount
        }
      }
    }
    userRate: getUserPathRateByPathId(id: $id) {
      pathId
      rate
    }
  }
`;

/**
 * __usePathAboutPageDataQuery__
 *
 * To run a query within a React component, call `usePathAboutPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathAboutPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathAboutPageDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePathAboutPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PathAboutPageDataQuery,
    PathAboutPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PathAboutPageDataQuery,
    PathAboutPageDataQueryVariables
  >(PathAboutPageDataDocument, options);
}
export function usePathAboutPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PathAboutPageDataQuery,
    PathAboutPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PathAboutPageDataQuery,
    PathAboutPageDataQueryVariables
  >(PathAboutPageDataDocument, options);
}
export type PathAboutPageDataQueryHookResult = ReturnType<
  typeof usePathAboutPageDataQuery
>;
export type PathAboutPageDataLazyQueryHookResult = ReturnType<
  typeof usePathAboutPageDataLazyQuery
>;
export type PathAboutPageDataQueryResult = Apollo.QueryResult<
  PathAboutPageDataQuery,
  PathAboutPageDataQueryVariables
>;
export const CoursePlayerPageDataDocument = gql`
  query coursePlayerPageData($id: String!) {
    getCourseById(id: $id) {
      _id
      name
      sections {
        _id
        title
        duration
        subsections {
          _id
          title
          duration
          videoId
        }
      }
      plan {
        index
        name
      }
      tags {
        _id
        value
      }
    }
    getUserCourseStateByCourseId(id: $id) {
      courseId
      finishedVideos
      courseVideosCount
      finished
      finalized
      videosState {
        subsectionId
        videoTime
        finished
        timestamp
      }
    }
    getUserCourseNotesByCourseId(id: $id) {
      _id
      courseId
      courseName
      notes {
        id
        subsectionId
        subsectionTitle
        content
        videoTime
      }
    }
    getBookmarksByCourseId(courseId: $id) {
      sections {
        sectionId
      }
      subsections {
        subsectionId
      }
    }
  }
`;

/**
 * __useCoursePlayerPageDataQuery__
 *
 * To run a query within a React component, call `useCoursePlayerPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursePlayerPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursePlayerPageDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCoursePlayerPageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoursePlayerPageDataQuery,
    CoursePlayerPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CoursePlayerPageDataQuery,
    CoursePlayerPageDataQueryVariables
  >(CoursePlayerPageDataDocument, options);
}
export function useCoursePlayerPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursePlayerPageDataQuery,
    CoursePlayerPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CoursePlayerPageDataQuery,
    CoursePlayerPageDataQueryVariables
  >(CoursePlayerPageDataDocument, options);
}
export type CoursePlayerPageDataQueryHookResult = ReturnType<
  typeof useCoursePlayerPageDataQuery
>;
export type CoursePlayerPageDataLazyQueryHookResult = ReturnType<
  typeof useCoursePlayerPageDataLazyQuery
>;
export type CoursePlayerPageDataQueryResult = Apollo.QueryResult<
  CoursePlayerPageDataQuery,
  CoursePlayerPageDataQueryVariables
>;
export const GenerateCourseVideoTokenDocument = gql`
  query generateCourseVideoToken($input: VideoAccessTokenInput!) {
    getCourseVideoToken(input: $input) {
      accessToken
    }
  }
`;

/**
 * __useGenerateCourseVideoTokenQuery__
 *
 * To run a query within a React component, call `useGenerateCourseVideoTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateCourseVideoTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateCourseVideoTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCourseVideoTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerateCourseVideoTokenQuery,
    GenerateCourseVideoTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenerateCourseVideoTokenQuery,
    GenerateCourseVideoTokenQueryVariables
  >(GenerateCourseVideoTokenDocument, options);
}
export function useGenerateCourseVideoTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateCourseVideoTokenQuery,
    GenerateCourseVideoTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenerateCourseVideoTokenQuery,
    GenerateCourseVideoTokenQueryVariables
  >(GenerateCourseVideoTokenDocument, options);
}
export type GenerateCourseVideoTokenQueryHookResult = ReturnType<
  typeof useGenerateCourseVideoTokenQuery
>;
export type GenerateCourseVideoTokenLazyQueryHookResult = ReturnType<
  typeof useGenerateCourseVideoTokenLazyQuery
>;
export type GenerateCourseVideoTokenQueryResult = Apollo.QueryResult<
  GenerateCourseVideoTokenQuery,
  GenerateCourseVideoTokenQueryVariables
>;
export const SaveUserCourseStateDocument = gql`
  mutation saveUserCourseState($userCourseStateInput: UserCourseStateInput!) {
    saveUserCourseState(userCourseStateInput: $userCourseStateInput) {
      finished
      finalized
      videosState {
        finished
        subsectionId
        videoTime
        timestamp
      }
    }
  }
`;
export type SaveUserCourseStateMutationFn = Apollo.MutationFunction<
  SaveUserCourseStateMutation,
  SaveUserCourseStateMutationVariables
>;

/**
 * __useSaveUserCourseStateMutation__
 *
 * To run a mutation, you first call `useSaveUserCourseStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserCourseStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserCourseStateMutation, { data, loading, error }] = useSaveUserCourseStateMutation({
 *   variables: {
 *      userCourseStateInput: // value for 'userCourseStateInput'
 *   },
 * });
 */
export function useSaveUserCourseStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserCourseStateMutation,
    SaveUserCourseStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserCourseStateMutation,
    SaveUserCourseStateMutationVariables
  >(SaveUserCourseStateDocument, options);
}
export type SaveUserCourseStateMutationHookResult = ReturnType<
  typeof useSaveUserCourseStateMutation
>;
export type SaveUserCourseStateMutationResult =
  Apollo.MutationResult<SaveUserCourseStateMutation>;
export type SaveUserCourseStateMutationOptions = Apollo.BaseMutationOptions<
  SaveUserCourseStateMutation,
  SaveUserCourseStateMutationVariables
>;
export const CreateUserCourseNoteDocument = gql`
  mutation createUserCourseNote($input: UserCourseNoteInput!) {
    createUserCourseNote(input: $input) {
      id
      subsectionId
      subsectionTitle
      content
      videoTime
    }
  }
`;
export type CreateUserCourseNoteMutationFn = Apollo.MutationFunction<
  CreateUserCourseNoteMutation,
  CreateUserCourseNoteMutationVariables
>;

/**
 * __useCreateUserCourseNoteMutation__
 *
 * To run a mutation, you first call `useCreateUserCourseNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCourseNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCourseNoteMutation, { data, loading, error }] = useCreateUserCourseNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserCourseNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCourseNoteMutation,
    CreateUserCourseNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserCourseNoteMutation,
    CreateUserCourseNoteMutationVariables
  >(CreateUserCourseNoteDocument, options);
}
export type CreateUserCourseNoteMutationHookResult = ReturnType<
  typeof useCreateUserCourseNoteMutation
>;
export type CreateUserCourseNoteMutationResult =
  Apollo.MutationResult<CreateUserCourseNoteMutation>;
export type CreateUserCourseNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateUserCourseNoteMutation,
  CreateUserCourseNoteMutationVariables
>;
export const UpdateUserCourseNoteDocument = gql`
  mutation updateUserCourseNote($input: UserCourseNoteUpdateInput!) {
    updateUserCourseNote(input: $input)
  }
`;
export type UpdateUserCourseNoteMutationFn = Apollo.MutationFunction<
  UpdateUserCourseNoteMutation,
  UpdateUserCourseNoteMutationVariables
>;

/**
 * __useUpdateUserCourseNoteMutation__
 *
 * To run a mutation, you first call `useUpdateUserCourseNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCourseNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCourseNoteMutation, { data, loading, error }] = useUpdateUserCourseNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserCourseNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserCourseNoteMutation,
    UpdateUserCourseNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserCourseNoteMutation,
    UpdateUserCourseNoteMutationVariables
  >(UpdateUserCourseNoteDocument, options);
}
export type UpdateUserCourseNoteMutationHookResult = ReturnType<
  typeof useUpdateUserCourseNoteMutation
>;
export type UpdateUserCourseNoteMutationResult =
  Apollo.MutationResult<UpdateUserCourseNoteMutation>;
export type UpdateUserCourseNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserCourseNoteMutation,
  UpdateUserCourseNoteMutationVariables
>;
export const DeleteUserCourseNoteDocument = gql`
  mutation deleteUserCourseNote($input: UserCourseNoteDeleteInput!) {
    deleteUserCourseNote(input: $input)
  }
`;
export type DeleteUserCourseNoteMutationFn = Apollo.MutationFunction<
  DeleteUserCourseNoteMutation,
  DeleteUserCourseNoteMutationVariables
>;

/**
 * __useDeleteUserCourseNoteMutation__
 *
 * To run a mutation, you first call `useDeleteUserCourseNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCourseNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCourseNoteMutation, { data, loading, error }] = useDeleteUserCourseNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserCourseNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserCourseNoteMutation,
    DeleteUserCourseNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserCourseNoteMutation,
    DeleteUserCourseNoteMutationVariables
  >(DeleteUserCourseNoteDocument, options);
}
export type DeleteUserCourseNoteMutationHookResult = ReturnType<
  typeof useDeleteUserCourseNoteMutation
>;
export type DeleteUserCourseNoteMutationResult =
  Apollo.MutationResult<DeleteUserCourseNoteMutation>;
export type DeleteUserCourseNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserCourseNoteMutation,
  DeleteUserCourseNoteMutationVariables
>;
export const SubmitCourseExamDocument = gql`
  mutation submitCourseExam($courseId: String!, $answers: [AnswerInput]!) {
    submitCourseExam(
      examSubmitInput: { courseId: $courseId, answers: $answers }
    ) {
      courseId
      examCorrect
      exam {
        nr
        type
        answer
        question
        choiceAnswer
        correct
      }
      acquiredBadges {
        _id
        name
        description
      }
    }
  }
`;
export type SubmitCourseExamMutationFn = Apollo.MutationFunction<
  SubmitCourseExamMutation,
  SubmitCourseExamMutationVariables
>;

/**
 * __useSubmitCourseExamMutation__
 *
 * To run a mutation, you first call `useSubmitCourseExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCourseExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCourseExamMutation, { data, loading, error }] = useSubmitCourseExamMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useSubmitCourseExamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitCourseExamMutation,
    SubmitCourseExamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitCourseExamMutation,
    SubmitCourseExamMutationVariables
  >(SubmitCourseExamDocument, options);
}
export type SubmitCourseExamMutationHookResult = ReturnType<
  typeof useSubmitCourseExamMutation
>;
export type SubmitCourseExamMutationResult =
  Apollo.MutationResult<SubmitCourseExamMutation>;
export type SubmitCourseExamMutationOptions = Apollo.BaseMutationOptions<
  SubmitCourseExamMutation,
  SubmitCourseExamMutationVariables
>;
export const GetCourseExamDocument = gql`
  query getCourseExam($courseId: String!) {
    getCourseExam(courseId: $courseId) {
      courseId
      exam {
        nr
        type
        answer
        question
        choiceAnswer
      }
    }
  }
`;

/**
 * __useGetCourseExamQuery__
 *
 * To run a query within a React component, call `useGetCourseExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseExamQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseExamQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseExamQuery,
    GetCourseExamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseExamQuery, GetCourseExamQueryVariables>(
    GetCourseExamDocument,
    options
  );
}
export function useGetCourseExamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseExamQuery,
    GetCourseExamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseExamQuery, GetCourseExamQueryVariables>(
    GetCourseExamDocument,
    options
  );
}
export type GetCourseExamQueryHookResult = ReturnType<
  typeof useGetCourseExamQuery
>;
export type GetCourseExamLazyQueryHookResult = ReturnType<
  typeof useGetCourseExamLazyQuery
>;
export type GetCourseExamQueryResult = Apollo.QueryResult<
  GetCourseExamQuery,
  GetCourseExamQueryVariables
>;
export const GetUserViewTimeDocument = gql`
  query getUserViewTime($from: Float!, $to: Float!) {
    getUserViewTime(from: $from, to: $to) {
      x
      y
    }
  }
`;

/**
 * __useGetUserViewTimeQuery__
 *
 * To run a query within a React component, call `useGetUserViewTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserViewTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserViewTimeQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetUserViewTimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserViewTimeQuery,
    GetUserViewTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserViewTimeQuery, GetUserViewTimeQueryVariables>(
    GetUserViewTimeDocument,
    options
  );
}
export function useGetUserViewTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserViewTimeQuery,
    GetUserViewTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserViewTimeQuery,
    GetUserViewTimeQueryVariables
  >(GetUserViewTimeDocument, options);
}
export type GetUserViewTimeQueryHookResult = ReturnType<
  typeof useGetUserViewTimeQuery
>;
export type GetUserViewTimeLazyQueryHookResult = ReturnType<
  typeof useGetUserViewTimeLazyQuery
>;
export type GetUserViewTimeQueryResult = Apollo.QueryResult<
  GetUserViewTimeQuery,
  GetUserViewTimeQueryVariables
>;
export const TotalWatchTimeDocument = gql`
  query totalWatchTime {
    getUserTotalWatchTime
  }
`;

/**
 * __useTotalWatchTimeQuery__
 *
 * To run a query within a React component, call `useTotalWatchTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalWatchTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalWatchTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalWatchTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TotalWatchTimeQuery,
    TotalWatchTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalWatchTimeQuery, TotalWatchTimeQueryVariables>(
    TotalWatchTimeDocument,
    options
  );
}
export function useTotalWatchTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalWatchTimeQuery,
    TotalWatchTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalWatchTimeQuery, TotalWatchTimeQueryVariables>(
    TotalWatchTimeDocument,
    options
  );
}
export type TotalWatchTimeQueryHookResult = ReturnType<
  typeof useTotalWatchTimeQuery
>;
export type TotalWatchTimeLazyQueryHookResult = ReturnType<
  typeof useTotalWatchTimeLazyQuery
>;
export type TotalWatchTimeQueryResult = Apollo.QueryResult<
  TotalWatchTimeQuery,
  TotalWatchTimeQueryVariables
>;
export const TopicActivityDocument = gql`
  query topicActivity {
    getUserTopicActivity {
      _id
      total
    }
  }
`;

/**
 * __useTopicActivityQuery__
 *
 * To run a query within a React component, call `useTopicActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopicActivityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TopicActivityQuery,
    TopicActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopicActivityQuery, TopicActivityQueryVariables>(
    TopicActivityDocument,
    options
  );
}
export function useTopicActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopicActivityQuery,
    TopicActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopicActivityQuery, TopicActivityQueryVariables>(
    TopicActivityDocument,
    options
  );
}
export type TopicActivityQueryHookResult = ReturnType<
  typeof useTopicActivityQuery
>;
export type TopicActivityLazyQueryHookResult = ReturnType<
  typeof useTopicActivityLazyQuery
>;
export type TopicActivityQueryResult = Apollo.QueryResult<
  TopicActivityQuery,
  TopicActivityQueryVariables
>;
export const UserStatsDocument = gql`
  query userStats($months: Int!) {
    getUserTopicActivity {
      _id
      total
    }
    getUserTotalWatchTime
  }
`;

/**
 * __useUserStatsQuery__
 *
 * To run a query within a React component, call `useUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatsQuery({
 *   variables: {
 *      months: // value for 'months'
 *   },
 * });
 */
export function useUserStatsQuery(
  baseOptions: Apollo.QueryHookOptions<UserStatsQuery, UserStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserStatsQuery, UserStatsQueryVariables>(
    UserStatsDocument,
    options
  );
}
export function useUserStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserStatsQuery,
    UserStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserStatsQuery, UserStatsQueryVariables>(
    UserStatsDocument,
    options
  );
}
export type UserStatsQueryHookResult = ReturnType<typeof useUserStatsQuery>;
export type UserStatsLazyQueryHookResult = ReturnType<
  typeof useUserStatsLazyQuery
>;
export type UserStatsQueryResult = Apollo.QueryResult<
  UserStatsQuery,
  UserStatsQueryVariables
>;
export const SaveUserCourseRateDocument = gql`
  mutation saveUserCourseRate($input: UserCourseRateInput!) {
    saveUserCourseRate(input: $input)
  }
`;
export type SaveUserCourseRateMutationFn = Apollo.MutationFunction<
  SaveUserCourseRateMutation,
  SaveUserCourseRateMutationVariables
>;

/**
 * __useSaveUserCourseRateMutation__
 *
 * To run a mutation, you first call `useSaveUserCourseRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserCourseRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserCourseRateMutation, { data, loading, error }] = useSaveUserCourseRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserCourseRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserCourseRateMutation,
    SaveUserCourseRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserCourseRateMutation,
    SaveUserCourseRateMutationVariables
  >(SaveUserCourseRateDocument, options);
}
export type SaveUserCourseRateMutationHookResult = ReturnType<
  typeof useSaveUserCourseRateMutation
>;
export type SaveUserCourseRateMutationResult =
  Apollo.MutationResult<SaveUserCourseRateMutation>;
export type SaveUserCourseRateMutationOptions = Apollo.BaseMutationOptions<
  SaveUserCourseRateMutation,
  SaveUserCourseRateMutationVariables
>;
export const GetUserGroupedTagsCountDocument = gql`
  query getUserGroupedTagsCount {
    getUserGroupedTagsCount {
      _id
      count
      tag {
        _id
        value
      }
    }
  }
`;

/**
 * __useGetUserGroupedTagsCountQuery__
 *
 * To run a query within a React component, call `useGetUserGroupedTagsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGroupedTagsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGroupedTagsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserGroupedTagsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserGroupedTagsCountQuery,
    GetUserGroupedTagsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserGroupedTagsCountQuery,
    GetUserGroupedTagsCountQueryVariables
  >(GetUserGroupedTagsCountDocument, options);
}
export function useGetUserGroupedTagsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserGroupedTagsCountQuery,
    GetUserGroupedTagsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserGroupedTagsCountQuery,
    GetUserGroupedTagsCountQueryVariables
  >(GetUserGroupedTagsCountDocument, options);
}
export type GetUserGroupedTagsCountQueryHookResult = ReturnType<
  typeof useGetUserGroupedTagsCountQuery
>;
export type GetUserGroupedTagsCountLazyQueryHookResult = ReturnType<
  typeof useGetUserGroupedTagsCountLazyQuery
>;
export type GetUserGroupedTagsCountQueryResult = Apollo.QueryResult<
  GetUserGroupedTagsCountQuery,
  GetUserGroupedTagsCountQueryVariables
>;
export const CreatePathBookmarkDocument = gql`
  mutation createPathBookmark($input: BookmarkPathInput!) {
    createPathBookmark(input: $input)
  }
`;
export type CreatePathBookmarkMutationFn = Apollo.MutationFunction<
  CreatePathBookmarkMutation,
  CreatePathBookmarkMutationVariables
>;

/**
 * __useCreatePathBookmarkMutation__
 *
 * To run a mutation, you first call `useCreatePathBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePathBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPathBookmarkMutation, { data, loading, error }] = useCreatePathBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePathBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePathBookmarkMutation,
    CreatePathBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePathBookmarkMutation,
    CreatePathBookmarkMutationVariables
  >(CreatePathBookmarkDocument, options);
}
export type CreatePathBookmarkMutationHookResult = ReturnType<
  typeof useCreatePathBookmarkMutation
>;
export type CreatePathBookmarkMutationResult =
  Apollo.MutationResult<CreatePathBookmarkMutation>;
export type CreatePathBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CreatePathBookmarkMutation,
  CreatePathBookmarkMutationVariables
>;
export const DeletePathBookmarkDocument = gql`
  mutation deletePathBookmark($input: RemoveBookmarkInput) {
    deletePathBookmark(input: $input)
  }
`;
export type DeletePathBookmarkMutationFn = Apollo.MutationFunction<
  DeletePathBookmarkMutation,
  DeletePathBookmarkMutationVariables
>;

/**
 * __useDeletePathBookmarkMutation__
 *
 * To run a mutation, you first call `useDeletePathBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePathBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePathBookmarkMutation, { data, loading, error }] = useDeletePathBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePathBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePathBookmarkMutation,
    DeletePathBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePathBookmarkMutation,
    DeletePathBookmarkMutationVariables
  >(DeletePathBookmarkDocument, options);
}
export type DeletePathBookmarkMutationHookResult = ReturnType<
  typeof useDeletePathBookmarkMutation
>;
export type DeletePathBookmarkMutationResult =
  Apollo.MutationResult<DeletePathBookmarkMutation>;
export type DeletePathBookmarkMutationOptions = Apollo.BaseMutationOptions<
  DeletePathBookmarkMutation,
  DeletePathBookmarkMutationVariables
>;
export const GetAllPathBookmarksDocument = gql`
  query getAllPathBookmarks($input: PaginationInput!) {
    getAllPathBookmarks(input: $input) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        path {
          name
          _id
          info {
            difficulty
            difficultyIndex
            releaseDate
            duration
          }
          tags {
            _id
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllPathBookmarksQuery__
 *
 * To run a query within a React component, call `useGetAllPathBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPathBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPathBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllPathBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPathBookmarksQuery,
    GetAllPathBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPathBookmarksQuery,
    GetAllPathBookmarksQueryVariables
  >(GetAllPathBookmarksDocument, options);
}
export function useGetAllPathBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPathBookmarksQuery,
    GetAllPathBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPathBookmarksQuery,
    GetAllPathBookmarksQueryVariables
  >(GetAllPathBookmarksDocument, options);
}
export type GetAllPathBookmarksQueryHookResult = ReturnType<
  typeof useGetAllPathBookmarksQuery
>;
export type GetAllPathBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetAllPathBookmarksLazyQuery
>;
export type GetAllPathBookmarksQueryResult = Apollo.QueryResult<
  GetAllPathBookmarksQuery,
  GetAllPathBookmarksQueryVariables
>;
export const SaveUserPathRateDocument = gql`
  mutation saveUserPathRate($input: UserPathRateInput!) {
    saveUserPathRate(input: $input)
  }
`;
export type SaveUserPathRateMutationFn = Apollo.MutationFunction<
  SaveUserPathRateMutation,
  SaveUserPathRateMutationVariables
>;

/**
 * __useSaveUserPathRateMutation__
 *
 * To run a mutation, you first call `useSaveUserPathRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserPathRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserPathRateMutation, { data, loading, error }] = useSaveUserPathRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserPathRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserPathRateMutation,
    SaveUserPathRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserPathRateMutation,
    SaveUserPathRateMutationVariables
  >(SaveUserPathRateDocument, options);
}
export type SaveUserPathRateMutationHookResult = ReturnType<
  typeof useSaveUserPathRateMutation
>;
export type SaveUserPathRateMutationResult =
  Apollo.MutationResult<SaveUserPathRateMutation>;
export type SaveUserPathRateMutationOptions = Apollo.BaseMutationOptions<
  SaveUserPathRateMutation,
  SaveUserPathRateMutationVariables
>;
export const UserCourseNotesDocument = gql`
  query userCourseNotes($pagination: PaginationInput!) {
    userCourseNotes(pagination: $pagination) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        courseId
        courseName
        courseTags
        notes {
          id
          subsectionId
          subsectionTitle
          content
          videoTime
        }
      }
    }
  }
`;

/**
 * __useUserCourseNotesQuery__
 *
 * To run a query within a React component, call `useUserCourseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCourseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCourseNotesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserCourseNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCourseNotesQuery,
    UserCourseNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCourseNotesQuery, UserCourseNotesQueryVariables>(
    UserCourseNotesDocument,
    options
  );
}
export function useUserCourseNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCourseNotesQuery,
    UserCourseNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserCourseNotesQuery,
    UserCourseNotesQueryVariables
  >(UserCourseNotesDocument, options);
}
export type UserCourseNotesQueryHookResult = ReturnType<
  typeof useUserCourseNotesQuery
>;
export type UserCourseNotesLazyQueryHookResult = ReturnType<
  typeof useUserCourseNotesLazyQuery
>;
export type UserCourseNotesQueryResult = Apollo.QueryResult<
  UserCourseNotesQuery,
  UserCourseNotesQueryVariables
>;
export const CreateCourseBookmarkDocument = gql`
  mutation createCourseBookmark($input: BookmarkCourseInput!) {
    createCourseBookmark(input: $input)
  }
`;
export type CreateCourseBookmarkMutationFn = Apollo.MutationFunction<
  CreateCourseBookmarkMutation,
  CreateCourseBookmarkMutationVariables
>;

/**
 * __useCreateCourseBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateCourseBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseBookmarkMutation, { data, loading, error }] = useCreateCourseBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseBookmarkMutation,
    CreateCourseBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseBookmarkMutation,
    CreateCourseBookmarkMutationVariables
  >(CreateCourseBookmarkDocument, options);
}
export type CreateCourseBookmarkMutationHookResult = ReturnType<
  typeof useCreateCourseBookmarkMutation
>;
export type CreateCourseBookmarkMutationResult =
  Apollo.MutationResult<CreateCourseBookmarkMutation>;
export type CreateCourseBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseBookmarkMutation,
  CreateCourseBookmarkMutationVariables
>;
export const CreateCourseSectionBookmarkDocument = gql`
  mutation createCourseSectionBookmark($input: BookmarkCourseSectionInput!) {
    createCourseSectionBookmark(input: $input)
  }
`;
export type CreateCourseSectionBookmarkMutationFn = Apollo.MutationFunction<
  CreateCourseSectionBookmarkMutation,
  CreateCourseSectionBookmarkMutationVariables
>;

/**
 * __useCreateCourseSectionBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateCourseSectionBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseSectionBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseSectionBookmarkMutation, { data, loading, error }] = useCreateCourseSectionBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseSectionBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseSectionBookmarkMutation,
    CreateCourseSectionBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseSectionBookmarkMutation,
    CreateCourseSectionBookmarkMutationVariables
  >(CreateCourseSectionBookmarkDocument, options);
}
export type CreateCourseSectionBookmarkMutationHookResult = ReturnType<
  typeof useCreateCourseSectionBookmarkMutation
>;
export type CreateCourseSectionBookmarkMutationResult =
  Apollo.MutationResult<CreateCourseSectionBookmarkMutation>;
export type CreateCourseSectionBookmarkMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCourseSectionBookmarkMutation,
    CreateCourseSectionBookmarkMutationVariables
  >;
export const CreateCourseSubsectionBookmarkDocument = gql`
  mutation createCourseSubsectionBookmark(
    $input: BookmarkCourseSubsectionInput!
  ) {
    createCourseSubsectionBookmark(input: $input)
  }
`;
export type CreateCourseSubsectionBookmarkMutationFn = Apollo.MutationFunction<
  CreateCourseSubsectionBookmarkMutation,
  CreateCourseSubsectionBookmarkMutationVariables
>;

/**
 * __useCreateCourseSubsectionBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateCourseSubsectionBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseSubsectionBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseSubsectionBookmarkMutation, { data, loading, error }] = useCreateCourseSubsectionBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseSubsectionBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseSubsectionBookmarkMutation,
    CreateCourseSubsectionBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseSubsectionBookmarkMutation,
    CreateCourseSubsectionBookmarkMutationVariables
  >(CreateCourseSubsectionBookmarkDocument, options);
}
export type CreateCourseSubsectionBookmarkMutationHookResult = ReturnType<
  typeof useCreateCourseSubsectionBookmarkMutation
>;
export type CreateCourseSubsectionBookmarkMutationResult =
  Apollo.MutationResult<CreateCourseSubsectionBookmarkMutation>;
export type CreateCourseSubsectionBookmarkMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCourseSubsectionBookmarkMutation,
    CreateCourseSubsectionBookmarkMutationVariables
  >;
export const DeleteCourseBookmarkDocument = gql`
  mutation deleteCourseBookmark($input: RemoveBookmarkInput) {
    deleteCourseBookmark(input: $input)
  }
`;
export type DeleteCourseBookmarkMutationFn = Apollo.MutationFunction<
  DeleteCourseBookmarkMutation,
  DeleteCourseBookmarkMutationVariables
>;

/**
 * __useDeleteCourseBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteCourseBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseBookmarkMutation, { data, loading, error }] = useDeleteCourseBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCourseBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourseBookmarkMutation,
    DeleteCourseBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCourseBookmarkMutation,
    DeleteCourseBookmarkMutationVariables
  >(DeleteCourseBookmarkDocument, options);
}
export type DeleteCourseBookmarkMutationHookResult = ReturnType<
  typeof useDeleteCourseBookmarkMutation
>;
export type DeleteCourseBookmarkMutationResult =
  Apollo.MutationResult<DeleteCourseBookmarkMutation>;
export type DeleteCourseBookmarkMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseBookmarkMutation,
  DeleteCourseBookmarkMutationVariables
>;
export const DeleteCourseSectionBookmarkDocument = gql`
  mutation deleteCourseSectionBookmark($input: RemoveBookmarkInput) {
    deleteCourseSectionBookmark(input: $input)
  }
`;
export type DeleteCourseSectionBookmarkMutationFn = Apollo.MutationFunction<
  DeleteCourseSectionBookmarkMutation,
  DeleteCourseSectionBookmarkMutationVariables
>;

/**
 * __useDeleteCourseSectionBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteCourseSectionBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseSectionBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseSectionBookmarkMutation, { data, loading, error }] = useDeleteCourseSectionBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCourseSectionBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourseSectionBookmarkMutation,
    DeleteCourseSectionBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCourseSectionBookmarkMutation,
    DeleteCourseSectionBookmarkMutationVariables
  >(DeleteCourseSectionBookmarkDocument, options);
}
export type DeleteCourseSectionBookmarkMutationHookResult = ReturnType<
  typeof useDeleteCourseSectionBookmarkMutation
>;
export type DeleteCourseSectionBookmarkMutationResult =
  Apollo.MutationResult<DeleteCourseSectionBookmarkMutation>;
export type DeleteCourseSectionBookmarkMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCourseSectionBookmarkMutation,
    DeleteCourseSectionBookmarkMutationVariables
  >;
export const DeleteCourseSubsectionBookmarkDocument = gql`
  mutation deleteCourseSubsectionBookmark($input: RemoveBookmarkInput) {
    deleteCourseSubsectionBookmark(input: $input)
  }
`;
export type DeleteCourseSubsectionBookmarkMutationFn = Apollo.MutationFunction<
  DeleteCourseSubsectionBookmarkMutation,
  DeleteCourseSubsectionBookmarkMutationVariables
>;

/**
 * __useDeleteCourseSubsectionBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteCourseSubsectionBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseSubsectionBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseSubsectionBookmarkMutation, { data, loading, error }] = useDeleteCourseSubsectionBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCourseSubsectionBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourseSubsectionBookmarkMutation,
    DeleteCourseSubsectionBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCourseSubsectionBookmarkMutation,
    DeleteCourseSubsectionBookmarkMutationVariables
  >(DeleteCourseSubsectionBookmarkDocument, options);
}
export type DeleteCourseSubsectionBookmarkMutationHookResult = ReturnType<
  typeof useDeleteCourseSubsectionBookmarkMutation
>;
export type DeleteCourseSubsectionBookmarkMutationResult =
  Apollo.MutationResult<DeleteCourseSubsectionBookmarkMutation>;
export type DeleteCourseSubsectionBookmarkMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCourseSubsectionBookmarkMutation,
    DeleteCourseSubsectionBookmarkMutationVariables
  >;
export const GetAllCourseBookmarksDocument = gql`
  query getAllCourseBookmarks($input: PaginationInput!) {
    getAllCourseBookmarks(input: $input) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        course {
          name
          _id
          info {
            difficulty
            difficultyIndex
            releaseDate
            duration
          }
          tags {
            _id
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCourseBookmarksQuery__
 *
 * To run a query within a React component, call `useGetAllCourseBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCourseBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCourseBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllCourseBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCourseBookmarksQuery,
    GetAllCourseBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCourseBookmarksQuery,
    GetAllCourseBookmarksQueryVariables
  >(GetAllCourseBookmarksDocument, options);
}
export function useGetAllCourseBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCourseBookmarksQuery,
    GetAllCourseBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCourseBookmarksQuery,
    GetAllCourseBookmarksQueryVariables
  >(GetAllCourseBookmarksDocument, options);
}
export type GetAllCourseBookmarksQueryHookResult = ReturnType<
  typeof useGetAllCourseBookmarksQuery
>;
export type GetAllCourseBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetAllCourseBookmarksLazyQuery
>;
export type GetAllCourseBookmarksQueryResult = Apollo.QueryResult<
  GetAllCourseBookmarksQuery,
  GetAllCourseBookmarksQueryVariables
>;
export const GetAllCourseSectionBookmarksDocument = gql`
  query getAllCourseSectionBookmarks($input: PaginationInput!) {
    getAllCourseSectionBookmarks(input: $input) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        course {
          name
          _id
          info {
            difficulty
            difficultyIndex
            releaseDate
            duration
          }
          tags {
            _id
            value
          }
          sections {
            _id
            title
            duration
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCourseSectionBookmarksQuery__
 *
 * To run a query within a React component, call `useGetAllCourseSectionBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCourseSectionBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCourseSectionBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllCourseSectionBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCourseSectionBookmarksQuery,
    GetAllCourseSectionBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCourseSectionBookmarksQuery,
    GetAllCourseSectionBookmarksQueryVariables
  >(GetAllCourseSectionBookmarksDocument, options);
}
export function useGetAllCourseSectionBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCourseSectionBookmarksQuery,
    GetAllCourseSectionBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCourseSectionBookmarksQuery,
    GetAllCourseSectionBookmarksQueryVariables
  >(GetAllCourseSectionBookmarksDocument, options);
}
export type GetAllCourseSectionBookmarksQueryHookResult = ReturnType<
  typeof useGetAllCourseSectionBookmarksQuery
>;
export type GetAllCourseSectionBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetAllCourseSectionBookmarksLazyQuery
>;
export type GetAllCourseSectionBookmarksQueryResult = Apollo.QueryResult<
  GetAllCourseSectionBookmarksQuery,
  GetAllCourseSectionBookmarksQueryVariables
>;
export const GetAllCourseSubsectionBookmarksDocument = gql`
  query getAllCourseSubsectionBookmarks($input: PaginationInput!) {
    getAllCourseSubsectionBookmarks(input: $input) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        course {
          name
          _id
          info {
            difficulty
            difficultyIndex
            releaseDate
            duration
          }
          tags {
            _id
            value
          }
          sections {
            _id
            title
            duration
            subsections {
              _id
              title
              duration
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCourseSubsectionBookmarksQuery__
 *
 * To run a query within a React component, call `useGetAllCourseSubsectionBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCourseSubsectionBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCourseSubsectionBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllCourseSubsectionBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCourseSubsectionBookmarksQuery,
    GetAllCourseSubsectionBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCourseSubsectionBookmarksQuery,
    GetAllCourseSubsectionBookmarksQueryVariables
  >(GetAllCourseSubsectionBookmarksDocument, options);
}
export function useGetAllCourseSubsectionBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCourseSubsectionBookmarksQuery,
    GetAllCourseSubsectionBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCourseSubsectionBookmarksQuery,
    GetAllCourseSubsectionBookmarksQueryVariables
  >(GetAllCourseSubsectionBookmarksDocument, options);
}
export type GetAllCourseSubsectionBookmarksQueryHookResult = ReturnType<
  typeof useGetAllCourseSubsectionBookmarksQuery
>;
export type GetAllCourseSubsectionBookmarksLazyQueryHookResult = ReturnType<
  typeof useGetAllCourseSubsectionBookmarksLazyQuery
>;
export type GetAllCourseSubsectionBookmarksQueryResult = Apollo.QueryResult<
  GetAllCourseSubsectionBookmarksQuery,
  GetAllCourseSubsectionBookmarksQueryVariables
>;
export const BookmarksCountDocument = gql`
  query bookmarksCount {
    getAllPathBookmarks(input: { page: 1, limit: 1 }) {
      totalDocs
    }
    getAllCourseBookmarks(input: { page: 1, limit: 1 }) {
      totalDocs
    }
    getAllCourseSubsectionBookmarks(input: { page: 1, limit: 1 }) {
      totalDocs
    }
    getAllCourseSectionBookmarks(input: { page: 1, limit: 1 }) {
      totalDocs
    }
  }
`;

/**
 * __useBookmarksCountQuery__
 *
 * To run a query within a React component, call `useBookmarksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarksCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookmarksCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BookmarksCountQuery,
    BookmarksCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookmarksCountQuery, BookmarksCountQueryVariables>(
    BookmarksCountDocument,
    options
  );
}
export function useBookmarksCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookmarksCountQuery,
    BookmarksCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookmarksCountQuery, BookmarksCountQueryVariables>(
    BookmarksCountDocument,
    options
  );
}
export type BookmarksCountQueryHookResult = ReturnType<
  typeof useBookmarksCountQuery
>;
export type BookmarksCountLazyQueryHookResult = ReturnType<
  typeof useBookmarksCountLazyQuery
>;
export type BookmarksCountQueryResult = Apollo.QueryResult<
  BookmarksCountQuery,
  BookmarksCountQueryVariables
>;
export const SearchTagsDocument = gql`
  query searchTags($searchString: String!, $pagination: PaginationInput!) {
    searchTags(searchString: $searchString, pagination: $pagination) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        value
      }
    }
  }
`;

/**
 * __useSearchTagsQuery__
 *
 * To run a query within a React component, call `useSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTagsQuery,
    SearchTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTagsQuery, SearchTagsQueryVariables>(
    SearchTagsDocument,
    options
  );
}
export function useSearchTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTagsQuery,
    SearchTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTagsQuery, SearchTagsQueryVariables>(
    SearchTagsDocument,
    options
  );
}
export type SearchTagsQueryHookResult = ReturnType<typeof useSearchTagsQuery>;
export type SearchTagsLazyQueryHookResult = ReturnType<
  typeof useSearchTagsLazyQuery
>;
export type SearchTagsQueryResult = Apollo.QueryResult<
  SearchTagsQuery,
  SearchTagsQueryVariables
>;
