export default function BadgeDialog(props: {
  badge: any,
  open: boolean,
  isFinish?: boolean
  onClose?: any;
}) {
  return (
    <dialog
        id="my_modal_1"
        open={
          props.open
        }
        className="modal bg-black bg-opacity-50"
      >
        <form method="dialog" className="modal-box">
          <div className="flex flex-col gap-2">
            {
              props.isFinish ?
               (
                <div className="flex gap-4">
              <div>🎉</div>
              <div className="stat-value text-lg">
                Congrats you have earned a new badge!
              </div>
            </div>
               ): ('')
            }

            <div className="flex items-center justify-center">
              <img
                className="h-56"
                src={`https://static.socora.com/img/badge/${props?.badge?._id}.png`}
              ></img>
            </div>

            <p className="stat-value text-lg">{props?.badge?.name}</p>

            <p className="text-accent text-opacity-50 text-sm">{props?.badge?.description}</p>
          </div>

          <div className="modal-action">
            <button onClick={() => props.onClose()} className="btn">Close</button>
          </div>
        </form>
      </dialog>
  )
};
