import { useEffect, useState } from "react";
import { Question } from "../api/CourseService";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import {
  useRetakeLabMachineFlagExamMutation,
  useSubmitLabMachineFlagExamMutation,
} from "../api/LabService";
import Spinner from "./Spinner";
import BadgeDialog from "./BadgeDialog";
import Alert from "./Alert";

const choices = ["A", "B", "C", "D"];

export default function Exam(props: {
  questions: Question[];
  flagHash: string;
  labId: string;
  onSuccess: any;
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [submitAnswerHandler, { data, loading }] =
    useSubmitLabMachineFlagExamMutation();

  const [retakeExam] = useRetakeLabMachineFlagExamMutation();

  const [examSubmitted, setExamSubmitted] = useState(false);

  const [answers, setAnswers] = useState(
    [] as {
      answer: string[];
      nr: number;
    }[]
  );

  useEffect(() => {
    if(examSubmitted && !loading && data?.submitLabMachineFlagExam?.examCorrect) {
      props.onSuccess();
    }
  }, [data]);

  const getAnswer = (question: number) => {
    let answer = answers[question];
    return answer ? answer.answer : [];
  };

  const next = () => {
    if (examSubmitted && currentQuestionIndex + 1 === props.questions.length) {
      
      // @ts-ignore
      [...document.getElementsByClassName('checkbox-primary')].forEach( el => el.checked = false );

      retakeExam({
        variables: {
          flagHash: props.flagHash,
          labId: props.labId,
        },
      });
      setAnswers([]);
      setCurrentQuestionIndex(0);
      setExamSubmitted(false);
      return;
    } else if (examSubmitted) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      return;
    }

    submitAnswerHandler({
      variables: {
        submitLabMachineFlagExamInput: {
          answer: {
            ...answers[currentQuestionIndex],
            type: "",
          },
          flagHash: props.flagHash,
          labId: props.labId,
        },
      },
    });

    if (currentQuestionIndex + 1 === props.questions.length) {
      setExamSubmitted(true);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const prev = () => {
    if(!examSubmitted) {
      submitAnswerHandler({
        variables: {
          submitLabMachineFlagExamInput: {
            answer: {
              ...answers[currentQuestionIndex],
              type: "",
            },
            flagHash: props.flagHash,
            labId: props.labId,
          },
        },
      });
    }

    setCurrentQuestionIndex(currentQuestionIndex - 1);
    
  };

  const answerHandler = (choice: string) => {
    const answer = answers[currentQuestionIndex];
    let tempAnswers = [...answers];
    if (answer) {
      const answerChoiseIndex = answer.answer.findIndex((a) => a === choice);
      if (answerChoiseIndex !== -1) {
        tempAnswers[currentQuestionIndex].answer.splice(answerChoiseIndex, 1);
      } else {
        tempAnswers[currentQuestionIndex].answer.push(choice);
      }
    } else {
      tempAnswers.push({
        nr: currentQuestionIndex,
        answer: [choice],
      });
    }
    setAnswers(tempAnswers);
  };

  if (examSubmitted && loading) {
    return (
      <div className="flex flex-col gap-2 items-center justify-center">
        <div>Fetching result</div>
        <div>
          <Spinner heigt="h-10"></Spinner>
        </div>
      </div>
    );
  }

  

  return (
    <div className="text-accent">
      <div hidden={!data?.submitLabMachineFlagExam?.examCorrect}>
        <Alert
          message="Congrats! The exam was correct and the machine is now finished."
          severity="alert-success"
        ></Alert>
      </div>

      <BadgeDialog
      isFinish={true} 
        badge={data?.submitLabMachineFlagExam?.acquiredBadges?.[0] as any}
        open={data?.submitLabMachineFlagExam?.examCorrect as any && data?.submitLabMachineFlagExam?.acquiredBadges?.length !== 0}
      ></BadgeDialog>
      
      {props.questions.map((question, index) => (
        <div key={index} hidden={index !== currentQuestionIndex}>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1 justify-center items-center">
              {examSubmitted && !data?.submitLabMachineFlagExam?.examCorrect ? (
                <div className="badge badge-error">Exam Failed</div>
              ) : (
                ""
              )}
            </div>

            <div className="font-bold">
              {(question.nr as number) + 1}/{props.questions.length}
              {examSubmitted ? (
                data?.submitLabMachineFlagExam?.exam?.[currentQuestionIndex]
                  ?.correct ? (
                  <div className="badge badge-success badge-xs font-normal p-2 ml-2">
                    Correct
                  </div>
                ) : (
                  <div className="badge badge-error  badge-xs font-normal p-2 ml-2">
                    Wrong
                  </div>
                )
              ) : (
                ""
              )}
            </div>

            <div className="font-bold">{question.question}?</div>
            <div className="flex flex-col gap-2">
              {choices.map((choice, index) => (
                <label key={index} className="label cursor-pointer gap-2">
                  <input
                    onChange={() => answerHandler(choice)}
                    defaultChecked={getAnswer(question?.nr as number)?.includes(
                      choice
                    )}
                    type="checkbox"
                    className="checkbox checkbox-primary checkbox-sm"
                  />
                  <span className="mr-auto">
                    {question?.choiceAnswer?.[index] as string}
                  </span>
                </label>
              ))}
            </div>
            <div className="flex  ">
              <button
                disabled={currentQuestionIndex === 0}
                className="btn btn-ghost btn-circle btn-sm"
              >
                <ArrowLeftIcon
                  onClick={() =>
                    setCurrentQuestionIndex(currentQuestionIndex - 1)
                  }
                  className="h-5"
                ></ArrowLeftIcon>
              </button>
              {currentQuestionIndex + 1 !== props.questions.length ? (
                <button
                  disabled={!answers[currentQuestionIndex]}
                  className="btn btn-ghost btn-circle btn-sm ml-auto"
                >
                  <ArrowRightIcon
                    onClick={() => next()}
                    className="h-5"
                  ></ArrowRightIcon>
                </button>
              ) : (
                <button
                  onClick={() => next()}
                  className="btn btn-sm ml-auto btn-primary text-white uppercase"
                >
                  {examSubmitted ? "Retake Exam" : "Submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
