import { useParams } from "react-router-dom";
import { useCourseAboutPageDataQuery } from "../../../api/CourseService";
import ContentAbout from "../../../components/ContentAbout";
import Spinner from "../../../components/Spinner";

export default function Course() {

  let { courseId } = useParams();

  const { data, loading } = useCourseAboutPageDataQuery({
    variables: {
      id: courseId as string,
    },
    errorPolicy: "all" 
  });

  if (loading) {
    return (
      <div className="h-[80vh] flex items-center justify-center">
        <Spinner heigt={"h-10"} />
      </div>
    );
  }

  return (
    <ContentAbout
      state={data?.getCourseById?.state}
      bookmarks={data?.getBookmarksByCourseId}
      contentType="course"
      userRate={data?.userRate}
      contentData={data?.getCourseById}
    />
  );
}
