import { useEffect, useState } from "react";
import {
  Question,
  useGetCourseExamQuery,
  useSubmitCourseExamMutation,
} from "../api/CourseService";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import {
  useRetakeLabMachineFlagExamMutation,
  useSubmitLabMachineFlagExamMutation,
} from "../api/LabService";
import Spinner from "./Spinner";
import Alert from "./Alert";
import BadgeDialog from "./BadgeDialog";

const choices = ["A", "B", "C", "D"];

const badgeMock = {
  _id: "64b7bbf5a382ae3851185d8d",
  name: "Hacker Man Badge",
  description:
    'You have earned the prestigious .NET Security Sentinel badge for successfully completing the "Secure Coding in .NET" learning path! This badge represents your exceptional expertise and dedication in the realm of secure programming within the .NET framework',
};

export default function CourseExam(props: { courseId: string }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [examSubmitted, setExamSubmitted] = useState(false);
  const [badge, setBadge] = useState(undefined as any);

  const [answers, setAnswers] = useState(
    [] as {
      answer: string[];
      nr: number;
      type: string;
    }[]
  );

  const { data, loading, error } = useGetCourseExamQuery({
    variables: {
      courseId: props.courseId,
    },
  });

  const [
    examSubmitHandler,
    { data: examSubmitData, loading: examSubmitLoading },
  ] = useSubmitCourseExamMutation();

  const getAnswer = (question: number) => {
    let answer = answers[question];
    return answer ? answer.answer : [];
  };

  const next = async () => {
    if (
      examSubmitted &&
      currentQuestionIndex + 1 === data?.getCourseExam?.exam?.length
    ) {
      // @ts-ignore
      [...document.getElementsByClassName("checkbox-primary")].forEach(
        (el) => (el.checked = false)
      );

      setAnswers([]);
      setCurrentQuestionIndex(0);
      setExamSubmitted(false);
      return;
    } else if (examSubmitted) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      return;
    }

    if (currentQuestionIndex + 1 === data?.getCourseExam?.exam?.length) {
      setExamSubmitted(true);

      examSubmitHandler({
        variables: {
          courseId: props.courseId,
          answers: answers as any,
        },
      });
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const prev = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const answerHandler = (choice: string) => {
    const answer = answers[currentQuestionIndex];
    let tempAnswers = [...answers];
    if (answer) {
      const answerChoiseIndex = answer.answer.findIndex((a) => a === choice);
      if (answerChoiseIndex !== -1) {
        tempAnswers[currentQuestionIndex].answer.splice(answerChoiseIndex, 1);
      } else {
        tempAnswers[currentQuestionIndex].answer.push(choice);
      }
    } else {
      tempAnswers.push({
        nr: currentQuestionIndex,
        answer: [choice],
        type: "CHOICE",
      });
    }
    setAnswers(tempAnswers);
  };


  return (
    <div className="flex flex-col gap-4">
      <div hidden={!examSubmitData?.submitCourseExam?.examCorrect}>
        <Alert
          message="Congrats! The exam was correct and course is now finalized."
          severity="alert-success"
        ></Alert>
      </div>
      <div className="text-xs text-accent text-opacity-50">
      Successfully passing the exam is essential for the finalization of this course!
      </div>

      <BadgeDialog 
        isFinish={true}
        badge={examSubmitData?.submitCourseExam?.acquiredBadges?.[0] as any}
        open={examSubmitData?.submitCourseExam?.examCorrect as any && examSubmitData?.submitCourseExam?.acquiredBadges?.length !== 0}
      ></BadgeDialog>

      <div hidden={examSubmitted && examSubmitLoading} className="text-accent">
        {data?.getCourseExam?.exam?.map((question, index) => (
          <div key={index} hidden={index !== currentQuestionIndex}>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1 justify-center items-center">
                {examSubmitted &&
                !examSubmitData?.submitCourseExam?.examCorrect ? (
                  <div className="badge badge-error">Exam Failed</div>
                ) : (
                  ""
                )}
              </div>

              <div className="font-bold">
                {(index as number) + 1}/{data?.getCourseExam?.exam?.length}
                {examSubmitted ? (
                  examSubmitData?.submitCourseExam?.exam?.[currentQuestionIndex]
                    ?.correct ? (
                    <div className="badge badge-success badge-xs font-normal p-2 ml-2">
                      Correct
                    </div>
                  ) : (
                    <div className="badge badge-error  badge-xs font-normal p-2 ml-2">
                      Wrong
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="font-bold">{question?.question}?</div>
              <div className="flex flex-col gap-2">
                {choices.map((choice, index) => (
                  <label key={index} className="label cursor-pointer gap-2">
                    <input
                      onChange={() => answerHandler(choice)}
                      defaultChecked={getAnswer(
                        question?.nr as number
                      )?.includes(choice)}
                      type="checkbox"
                      className="checkbox checkbox-primary checkbox-sm"
                    />
                    <span className="mr-auto">
                      {question?.choiceAnswer?.[index] as string}
                    </span>
                  </label>
                ))}
              </div>
              <div className="flex  ">
                <button
                  disabled={currentQuestionIndex === 0}
                  className="btn btn-ghost btn-circle btn-sm"
                >
                  <ArrowLeftIcon
                    onClick={() =>
                      setCurrentQuestionIndex(currentQuestionIndex - 1)
                    }
                    className="h-5"
                  ></ArrowLeftIcon>
                </button>
                {currentQuestionIndex + 1 !==
                data?.getCourseExam?.exam?.length ? (
                  <button
                    disabled={!answers[currentQuestionIndex]}
                    className="btn btn-ghost btn-circle btn-sm ml-auto"
                  >
                    <ArrowRightIcon
                      onClick={() => next()}
                      className="h-5"
                    ></ArrowRightIcon>
                  </button>
                ) : (
                  <button
                    onClick={() => next()}
                    className="btn btn-sm ml-auto btn-primary text-white uppercase"
                  >
                    {examSubmitted ? "Retake Exam" : "Submit"}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div hidden={!examSubmitLoading}>
        <div className="flex flex-col gap-2 items-center justify-center">
          <div>Fetching result</div>
          <div>
            <Spinner heigt="h-10"></Spinner>
          </div>
        </div>
      </div>
    </div>
  );
}
