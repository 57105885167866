import { useEffect, useState, useRef, RefObject } from 'react';

export default function useOnScreen(ref: RefObject<HTMLElement>, disconnectOnScreen?: boolean) {
  const observerRef = useRef<IntersectionObserver | null>(null) as any;
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
     {
      setIsOnScreen(entry.isIntersecting)
      if(entry.isIntersecting && disconnectOnScreen) {
        observerRef.current.disconnect();
      }
     }
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref?.current);
    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen
}