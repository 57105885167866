import { Link, useLocation, useParams } from "react-router-dom";
import {
  Note,
  Sections,
  Subsection,
  UserCourseNote,
  UserCourseState,
  VideoState,
  useCoursePlayerPageDataQuery,
  useGetCourseExamQuery,
  useSaveUserCourseStateMutation,
} from "../../../api/CourseService";
import CourseContentCollapse from "../../../components/CourseContentCollapse";
import { useEffect, useState } from "react";

import CourseContentCollapseSkeleton from "../../../components/CourseContentCollapseSkeleton";
import CourseNotes from "../../../components/CourseNotes";
import { scrollIntoView } from "../../../util/dom";
import SocoraPlyr from "./SocoraPlyr";
import Assistant from "../../../components/Assistant";
import CourseExam from "../../../components/CourseExam";
import "./plyr.css";
import Spinner from "../../../components/Spinner";

const tabs = [
  {
    label: "Videos",
  },
  {
    label: "Notes",
  },
  {
    label: "Assistant",
  },
];

function getLatestState(sections: any, videosState: any) {
  const sortedState = videosState
    ?.map((state: any) => state?.subsectionId)
    ?.sort()
    ?.reverse();

  if (sortedState) {
    for (const subsectionId of sortedState as string[]) {
      let latestState = undefined;
      let subsectionIndex = -1;

      for (let i = 0; i < sections.length; i++) {
        subsectionIndex = sections[i].subsections?.findIndex(
          (subsection: any) => subsection?._id === subsectionId
        ) as number;

        if (subsectionIndex !== -1) {
          latestState = {
            section: sections[i] as Sections,
            sectionIndex: i,
            subsection: sections[i].subsections?.[
              subsectionIndex
            ] as Subsection,
            subsectionIndex: subsectionIndex,
          };

          break;
        }
      }
      if (latestState) return latestState;
    }
  }
}

function PlayerPageLoading() {
  return (
    // <div className="flex lg:flex-row flex-col h-screen">
    //   <div className="lg:w-[75%] lg:h-screen sm:h-[45rem] h-[24rem] w-full">
    //     <div className="h-full w-full bg-black"></div>
    //   </div>
    //   <div className="lg:w-[25%] w-full overflow-scroll">
    //     <div className="prose-sm p-4">
    //       <div className="h-7 lg:w-1/2 w-full bg-white bg-opacity-5 rounded-2xl"></div>
    //     </div>
    // <div className="tabs z-10">
    //   {tabs.map((tab) => (
    //     <div
    //       key={tab.label}
    //       onClick={() => ""}
    //       className={`tab tab-bordered grow`}
    //     >
    //       {tab.label}
    //     </div>
    //   ))}
    // </div>
    //     <div className="p-4">
    //       <CourseContentCollapseSkeleton></CourseContentCollapseSkeleton>
    //     </div>
    //   </div>
    // </div>
    <div className="flex lg:flex-row flex-col h-full lg:pr-5 lg:pl-5 w-full gap-5">
      <div className="flex flex-col lg:w-[70%] w-full h-full lg:pt-10 pt-4">

        <div className="flex flex-col gap-2 lg:pr-0 pr-5 lg:pl-0 pl-5">
          <div className="mr-auto">
            <Link className="" to={"/"}>
              <img
                className="h-4 dark:hidden "
                src="/socora_logo_text_light.png"
              ></img>
              <img
                className="h-4 dark:block hidden "
                src="/socora_logo_text.png"
              ></img>
            </Link>
          </div>
          <div className=" flex items-center pb-5 gap-1 text-sm font-bold  ">
            <Link
              className="hover:underline hover:text-primary hover:opacity-100 opacity-50"
              to={"/courses"}
            >
              <a>Courses</a>
            </Link>
            <span className="opacity-50">/</span>
            <span className="opacity-50">
              <Spinner heigt="h-5"></Spinner>
            </span>
          </div>
        </div>

        <div className="flex-grow w-full">
          <div className="skeleton w-full lg:h-[40rem] sm:h-[30rem] h-[15rem]"></div>
        </div>

        <div className="pt-5 flex flex-col gap-2 lg:pr-0 pr-5 lg:pl-0 pl-5">
          <div className="skeleton w-[20rem] h-[2rem]"></div>
          <div className="skeleton w-[15rem] h-[2rem]"></div>
          <div className="flex gap-2">
            <div className="skeleton w-[7rem] h-[1.5rem]"></div>
            <div className="skeleton w-[7rem] h-[1.5rem]"></div>
          </div>
        </div>
      </div>

      <div className="lg:w-[30%] w-full h-full flex flex-col pt-5">
        <div className="tabs tabs-xs h-full tabs-boxed bg-base-300 rounded-br-none rounded-bl-none ">
          {tabs.map((tab, index) => (
            <div
              key={tab.label}
              id={`tab_${index}`}
              className={`tab tab-bordered grow h-full p-2`}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className="">
          <div className="skeleton w-full lg:h-[54rem] h-[23rem] rounded-none shadow-md"></div>
        </div>
      </div>
    </div>
  );
}

function setWindowHistory(
  courseId: string,
  sectionIndex: number,
  subsectionIndex: number
) {
  window.history.replaceState(
    null,
    "",
    `/courses/${courseId}/player?module=${sectionIndex}&video=${subsectionIndex}`
  );
}

export default function Player() {
  let { courseId } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const { search } = useLocation();
  const [playerRef, setPlayerRef] = useState(null as any);

  const { data, loading } = useCoursePlayerPageDataQuery({
    variables: {
      id: courseId as string,
    },
  });

  const courseExamQuery = useGetCourseExamQuery({
    variables: {
      courseId: courseId as string,
    },
  });

  const [saveUserStateHandler] = useSaveUserCourseStateMutation();
  const [playerData, setPlayerData] = useState({
    currentSection: {} as Sections,
    currentSubsection: {} as Subsection,
    initFinished: false,
  });

  const [userState, setUserState] = useState({
    videosState: [],
  } as UserCourseState);

  const [newNoteContent, setNewNoteContent] = useState("");

  const videoFinishHandler = async (subsectionId: string) => {
    let response = await saveUserStateHandler({
      variables: {
        userCourseStateInput: {
          courseId: courseId,
          finished: true,
          subsectionId: subsectionId,
          videoTime: 0,
        },
      },
    });
    if (response.data?.saveUserCourseState?.finished) {
      if (!data?.getUserCourseStateByCourseId?.finished) {
        tabs.push({
          label: "Exam",
        });
        setCurrentTab(3);
      }
    }

    let tempPlayerData = { ...playerData };
    let tempUserState = { ...userState };
    tempUserState.videosState = response.data?.saveUserCourseState?.videosState;
    setUserState(tempUserState);

    for (
      let sectionIndex = 0;
      sectionIndex < data!.getCourseById!.sections!.length;
      sectionIndex++
    ) {
      const section = data!.getCourseById!.sections![sectionIndex];

      for (
        let subsectionIndex = 0;
        subsectionIndex < section!.subsections!.length;
        subsectionIndex++
      ) {
        const subsection = section!.subsections![subsectionIndex];
        let sectionEnd = false;
        let courseEnd = false;

        if (subsection?._id === subsectionId) {
          if (subsectionIndex + 1 === section?.subsections?.length) {
            // Last Video
            sectionEnd = true;
            if (sectionIndex + 1 !== data?.getCourseById?.sections?.length) {
              // HAS Sections
              let nextSection =
                data!.getCourseById?.sections![sectionIndex + 1];
              tempPlayerData.currentSection = nextSection as Sections;
              tempPlayerData.currentSubsection = nextSection
                ?.subsections?.[0] as Subsection;
            } else {
              courseEnd = true;
              tempPlayerData.currentSection = section;
              tempPlayerData.currentSubsection = subsection;
            }
          } else {
            tempPlayerData.currentSubsection = section!.subsections![
              subsectionIndex + 1
            ] as Subsection;
          }

          if (sectionEnd) {
            document
              .getElementById(
                `section_${data?.getCourseById?.sections?.[sectionIndex]?._id}_input`
              )
              ?.removeAttribute("checked");
            document
              .getElementById(
                `section_${tempPlayerData.currentSection._id}_input`
              )
              ?.setAttribute("checked", "true");
            scrollIntoView(`section_${tempPlayerData.currentSection._id}`);
          }

          if (!courseEnd) {
            setWindowHistory(
              data?.getCourseById?._id as string,
              sectionEnd ? sectionIndex + 1 : sectionIndex,
              sectionEnd ? 0 : subsectionIndex + 1
            );
          }

          setPlayerData(tempPlayerData);

          break;
        }
      }
    }
  };

  useEffect(() => {
    if (!loading && !playerData.initFinished) {
      const query = new URLSearchParams(search);

      let sectionIndex = 0;
      let subsectionIndex = 0;

      if (query.get("module") && query.get("video") && !query.get("bkm")) {
        sectionIndex = parseInt(query.get("module") as string);
        subsectionIndex = parseInt(query.get("video") as string);
      } else if (query.get("bkm")) {
        if (query.get("module")) {
          sectionIndex = data?.getCourseById?.sections?.findIndex(
            (section) => section?._id === query.get("module")
          ) as number;
          sectionIndex = sectionIndex === -1 ? 0 : sectionIndex;
        }

        if (query.get("video")) {
          subsectionIndex = data?.getCourseById?.sections?.[
            sectionIndex
          ]?.subsections?.findIndex(
            (subsection) => subsection?._id === query.get("video")
          ) as number;
          subsectionIndex = subsectionIndex === -1 ? 0 : subsectionIndex;
        }
      } else {
        const latestState = getLatestState(
          data?.getCourseById?.sections,
          data?.getUserCourseStateByCourseId?.videosState
        );
        if (latestState) {
          sectionIndex = latestState?.sectionIndex as number;
          subsectionIndex = latestState?.subsectionIndex as number;
        }
      }

      let section = data?.getCourseById?.sections?.[sectionIndex] as Sections;
      let subsection = section?.subsections?.[subsectionIndex] as Subsection;

      document
        .getElementById(`section_${section._id}_input`)
        ?.setAttribute("checked", "true");
      scrollIntoView(`section_${section._id}`);

      let tempPlayerData = { ...playerData };
      tempPlayerData.currentSection = section;
      tempPlayerData.currentSubsection = subsection;

      if (data?.getUserCourseStateByCourseId) {
        setUserState(data?.getUserCourseStateByCourseId as UserCourseState);

        if (
          data?.getUserCourseStateByCourseId.finished &&
          !data?.getUserCourseStateByCourseId.finalized
        ) {
          tabs.push({ label: "Exam" });
        }
      }

      tempPlayerData.initFinished = true;
      setPlayerData(tempPlayerData);

      setWindowHistory(courseId as string, sectionIndex, subsectionIndex);
    }
  }, [loading, userState]);

  const subsectionClickHandler = (
    sectionIndex: number,
    subsectionIndex: number
  ) => {
    let section = data?.getCourseById?.sections?.[sectionIndex] as Sections;
    let subsection = section?.subsections?.[subsectionIndex] as Subsection;

    if (playerData.currentSubsection._id === subsection._id) return;

    let video;

    if (playerRef) {
      video = playerRef.current;
    } else {
      video = document.querySelector("#player") as any;
    }

    let prevSubsection = { ...playerData.currentSubsection };

    saveUserStateHandler({
      variables: {
        userCourseStateInput: {
          courseId: courseId,
          finished: false,
          subsectionId: prevSubsection._id,
          videoTime: Math.floor(video?.currentTime),
        },
      },
    });

    let tempUserState = { ...userState } as any;

    let videoStateIndex = tempUserState?.videosState?.findIndex(
      (state: VideoState) => state?.subsectionId === prevSubsection._id
    ) as number;

    if (videoStateIndex === -1) {
      tempUserState.videosState.push({
        finished: false,
        subsectionId: prevSubsection._id,
        timestamp: 123,
        videoTime: Math.floor(video.currentTime),
      } as VideoState);
    } else {
      tempUserState!.videosState[videoStateIndex]!.videoTime = Math.floor(
        video?.currentTime
      );
    }
    setUserState(tempUserState);

    document!.getElementById("seek")!.innerText = Math.floor(
      video.currentTime
    ).toString();

    let tempPlayerData = { ...playerData };
    tempPlayerData.currentSection = section;
    tempPlayerData.currentSubsection = subsection;

    setPlayerData(tempPlayerData);

    setWindowHistory(courseId as string, sectionIndex, subsectionIndex);
  };

  const saveAsNoteHandler = (content: string) => {
    setNewNoteContent(content);
    setCurrentTab(1);
  };

  if (loading) {
    return <PlayerPageLoading></PlayerPageLoading>;
  }

  return (
    <div className="lg:pr-4 lg:pl-4">
      <div className="flex lg:flex-row flex-col h-screen">

        <div className="lg:w-[70%] w-full h-full">
          <div className="flex items-center h-full">
            <div className="flex flex-col h-full lg:pt-10 pt-4 pb-10 w-full">
              <div className="flex flex-col gap-2 pr-5 pl-5">
                <div className="mr-auto">
                  <Link className="" to={"/"}>
                    <img
                      className="h-4 dark:hidden "
                      src="/socora_logo_text_light.png"
                    ></img>
                    <img
                      className="h-4 dark:block hidden "
                      src="/socora_logo_text.png"
                    ></img>
                  </Link>
                </div>
                <div className=" flex items-center pb-5 gap-1 text-sm font-bold  ">
                  <Link
                    className="hover:underline hover:text-primary hover:opacity-100 opacity-50"
                    to={"/courses"}
                  >
                    <a>Courses</a>
                  </Link>
                  <span className="opacity-50">/</span>
                  <span className="opacity-50">
                    {data?.getCourseById?.name}
                  </span>
                </div>
              </div>
              <div className="flex-grow w-full">
                <SocoraPlyr
                  seek={
                    userState?.videosState?.find(
                      (state) =>
                        state?.subsectionId === playerData.currentSubsection._id
                    )?.videoTime as number
                  }
                  noteClickHandler={() => setCurrentTab(1)}
                  videoDuration={
                    playerData.currentSubsection.duration as number
                  }
                  notes={
                    data?.getUserCourseNotesByCourseId?.notes?.filter(
                      (note) =>
                        note?.subsectionId === playerData.currentSubsection?._id
                    ) as Note[]
                  }
                  courseId={courseId as string}
                  subsection={playerData.currentSubsection}
                  onVideoFinish={videoFinishHandler}
                  onStreamStartRef={setPlayerRef}
                ></SocoraPlyr>
              </div>
              <div className="pt-5 flex items-center pr-5 pl-5">
                <div className="flex flex-col">
                  <span className="text-lg font-bold">
                    {playerData?.currentSubsection?.title}
                  </span>
                  <h1 className="opacity-50">
                    Module: {playerData?.currentSection?.title}
                  </h1>
                  <div className="flex pt-2 gap-2">
                    {data?.getCourseById?.tags?.map((tag) => (
                      <span className="badge badge-sm bg-base-300 font-semibold opacity-50">
                        {tag?.value}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-[30%] w-full lg:h-full min-h-[40rem] h-[40rem] lg:pt-5 lg:pb-5 lg:pl-5">
          <div className="lg:h-[4%] h-[6%]">
            <div className="tabs tabs-xs h-full tabs-boxed bg-base-300 rounded-br-none rounded-bl-none ">
              {tabs.map((tab, index) => (
                <div
                  key={tab.label}
                  id={`tab_${index}`}
                  onClick={() => setCurrentTab(index)}
                  className={`tab tab-bordered grow h-full ${
                    currentTab === index ? "text-white bg-primary" : ""
                  } `}
                >
                  {tab.label}
                  {index === 3 ? (
                    <span
                      className={`ml-2 rounded-full h-2 w-2 bg-primary opacity-75 ${
                        currentTab !== 3 ? "animate-ping" : ""
                      }`}
                    ></span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="bg-base-200 h-[96%] w-full overflow-scroll pl-5 pr-5 pb-5 rounded-b-lg shadow-xl">
            <div
              id={"videos_tab_container"}
              hidden={currentTab !== 0}
              className="w-full "
            >
              <CourseContentCollapse
                courseId={courseId as string}
                sectionClickHandler={() => ""}
                subsectionClickHandler={subsectionClickHandler}
                data={{
                  currentSubsection: playerData.currentSubsection,
                  sectionBookmarks: data?.getBookmarksByCourseId
                    ?.sections as any,
                  subsectionBookmarks: data?.getBookmarksByCourseId
                    ?.subsections as any,
                  state: userState as any,
                  sections: data?.getCourseById?.sections as any,
                }}
              />
            </div>

            <div
              id={"notes_tab_container"}
              className="h-full pt-5"
              hidden={currentTab !== 1}
            >
              <CourseNotes
                noteClickHandler={() => setCurrentTab(1)}
                courseId={courseId as string}
                currentSection={playerData.currentSection}
                currentVideo={playerData.currentSubsection}
                userCourseNote={
                  data?.getUserCourseNotesByCourseId as UserCourseNote
                }
                newNoteContent={newNoteContent}
              />
            </div>

            <div
              id={"assistant_tab_container"}
              className="h-full pt-5"
              hidden={currentTab !== 2}
            >
              <Assistant saveAsNote={saveAsNoteHandler}></Assistant>
            </div>
            {tabs.length > 3 ? (
              <div
                id={"exam_tab_container"}
                className="p-4"
                hidden={currentTab !== 3}
              >
                <div className=" bg-base-200 p-4 rounded-xl">
                  <CourseExam courseId={courseId as string}></CourseExam>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

      </div>
    </div>
  );
}
