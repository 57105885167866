import {
  Link,
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import AuthService from "./api/AuthService";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { PaymentService } from "./api/PaymentService";
import { capitalizeFirstLetter } from "./util/misc";
import Spinner from "./components/Spinner";

function App() {
  const location = useLocation();
  const loaderData = useLoaderData() as any;
  const [message, setMessage] = useState(undefined as any);

  useEffect(() => {
    window.scrollTo(0, 0);

    document.title =
      "Socora | " + capitalizeFirstLetter(location.pathname.split("/")[1]);

    if (loaderData["cognito:groups"].includes("INDIVIDUAL")) {
      PaymentService.getSubscription().then((subscription) => {
        if (location.pathname === "/subscribe") {
          setMessage(undefined);
          return;
        }

        if (subscription.hasError && location.pathname !== "/subscribe") {
          setMessage({
            severity: "alert-error",
            message:
              "Looks like you are not subscribed to Socora! Please subscribe in order to take courses and labs.",
            path: "/subscribe",
            label: "Subscribe",
          });
          return;
        }

        if (
          subscription.data.subscription.status !== "active" &&
          location.pathname !== "/settings/card"
        ) {
          setMessage({
            severity: "alert-error",
            message:
              "An error occurred while processing your subscription. Please update your payment method on settings",
            path: "/settings/card",
            label: "Settings",
          });
        } else {
          setMessage(undefined);
        }
      });
    }
  }, [location]);

  if (!loaderData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div>
          <Spinner heigt="h-10"></Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      {loaderData["cognito:groups"].includes("INDIVIDUAL") &&
        (message ? (
          <div className="toast z-10">
            <div className={`alert ${message.severity} w-[20rem] flex`}>
              <div className="flex flex-col gap-2 whitespace-normal w-full">
                <ExclamationCircleIcon className="h-7"></ExclamationCircleIcon>
                <div className="font-bold text-sm">{message.message}</div>
                <Link to={message.path} className="btn btn-ghost ">
                  {message.label}
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        ))}

      <div className="sticky top-0 z-50 bg-base-100 bg-opacity-95">
        <Navbar></Navbar>
      </div>
      <div
        className="ml-auto mr-auto min-h-screen"
        style={{ maxWidth: "1800px" }}
      >
        <Outlet
          context={{
            userRole: loaderData["cognito:groups"][0],
          }}
        />
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
