import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import Courses from "./pages/courses/courses";
import Course from "./pages/courses/course/course";
import {
  action as signInAction,
  loader as signInLoader,
} from "./pages/auth/signin/signin";
import {
  action as forceChangePasswordAction,
  loader as forceChangePasswordLoader,
} from "./pages/auth/forceChangePassword/forceChangePassword";
import {
  action as signupAction,
  loader as signupLoader,
} from "./pages/auth/signup/signup";
import {
  action as recoverMFAAction,
  loader as recoverMFAALoader,
} from "./pages/auth/recoverMFA/recoverMFA";
import Auth from "./pages/auth/auth";
import Home from "./pages/home/home";
import Path from "./pages/paths/path";
import Lab from "./pages/labs/lab";
import { ApolloProvider } from "@apollo/client";
import { getCourseApolloClient, getLabApolloClient } from "./util/apollo";
import Player from "./pages/courses/player/player";
import AppErrorBoundary from "./components/AppErrorBoundary";
import { action as resetPasswordAction } from "./pages/auth/reset/resetPassword";
import LabWorkspace from "./pages/labs/workspace/labWorkspace";
import Settings from "./pages/settings/settings";
import { loader as appLoader } from "./App.loader";
import { loaderUserRole } from "./UserRole.loader";

export const routes = [
  {
    path: "/",
    element: <App></App>,
    loader: appLoader,
    errorElement: <AppErrorBoundary></AppErrorBoundary>,
    children: [
      {
        path: "/",
        element: (
          <ApolloProvider client={getCourseApolloClient()}>
            <Home></Home>
          </ApolloProvider>
        ),
      },
      {
        path: "/home",
        element: (
          <ApolloProvider client={getCourseApolloClient()}>
            <Home></Home>
          </ApolloProvider>
        ),
      },
      {
        path: "/search",
        lazy: () => import("./pages/search/search"),
      },
      {
        path: "/subscribe",
        lazy: () => import("./pages/subscribe/subscribe"),
      },
      {
        path: "/subscribe/change-plan",
        lazy: () => import("./pages/subscribe/changePlan"),
      },
      {
        path: "/bookmarks",
        lazy: async () => {
          let Bookmarks = await import("./pages/bookmarks/bookmarks");
          return { Component: Bookmarks.default };
        },
      },
      {
        path: "/courses",
        element: <Courses></Courses>,
        lazy: () => import("./pages/courses/courses"),
      },
      {
        path: "/courses/:courseId",
        element: (
          <ApolloProvider client={getCourseApolloClient()}>
            <Course />
          </ApolloProvider>
        ),
      },
      {
        path: "/paths",
        lazy: async () => {
          let Paths = await import("./pages/paths/paths");
          return { Component: Paths.default };
        },
      },
      {
        path: "/paths/:pathId",
        element: (
          <ApolloProvider client={getCourseApolloClient()}>
            <Path />
          </ApolloProvider>
        ),
      },
      {
        path: "notes",
        lazy: async () => {
          let Notes = await import("./pages/notes/notes");
          return { Component: Notes.default };
        },
      },

      {
        path: "/labs",
        lazy: async () => {
          let Labs = await import("./pages/labs/labs");
          return { Component: Labs.default };
        },
      },
      {
        path: "/labs/:labId",
        element: (
          <ApolloProvider client={getLabApolloClient()}>
            <Lab />
          </ApolloProvider>
        ),
      },
      {
        path: "/settings",
        element: <Settings></Settings>,
        children: [
          {
            path: "plan",
            loader: loaderUserRole,
            lazy: async () => {
              let PlanSettings = await import("./pages/settings/PlanSettings");
              return { Component: PlanSettings.default };
            },
          },
          {
            path: "card",
            loader: loaderUserRole,
            lazy: async () => {
              let PlanSettings = await import("./pages/settings/CardSettings");
              return { Component: PlanSettings.default };
            },
          },
          {
            path: "invoices",
            loader: loaderUserRole,
            lazy: async () => {
              let Invoices = await import("./pages/settings/Invoices");
              return { Component: Invoices.default };
            },
          },
          {
            path: "password",
            lazy: async () => {
              let ChangePasswordSettings = await import(
                "./pages/settings/ChangePasswordSettings"
              );
              return { Component: ChangePasswordSettings.default };
            },
          },
          {
            path: "mfa",
            lazy: async () => {
              let MFASettings = await import("./pages/settings/MFASettings");
              return { Component: MFASettings.default };
            },
          },
          {
            path: "theme",
            lazy: async () => {
              let ThemeSettings = await import(
                "./pages/settings/ThemeSettings"
              );
              return { Component: ThemeSettings.default };
            },
          },
        ],
      },
      {
        path: "account/profile",
        lazy: async () => {
          let profile = await import("./pages/profile/profile");
          return { Component: profile.default };
        },
      },
    ],
  },
  {
    path: "/courses/:courseId/player",
    loader: appLoader,
    errorElement: <AppErrorBoundary></AppErrorBoundary>,
    element: (
      <ApolloProvider client={getCourseApolloClient()}>
        <Player />
      </ApolloProvider>
    ),
  },
  {
    path: "/labs/:labId/workspace/:machineIndex?",
    element: (
      <ApolloProvider client={getLabApolloClient()}>
        <LabWorkspace />
      </ApolloProvider>
    ),
  },
  {
    path: "/auth",
    element: <Auth></Auth>,
    children: [
      {
        path: "signin",
        action: signInAction,
        loader: signInLoader,
        lazy: async () => {
          let signin = await import("./pages/auth/signin/signin");
          return { Component: signin.default };
        },
      },
      {
        path: "forceChangePassword",
        action: forceChangePasswordAction,
        loader: forceChangePasswordLoader,
        lazy: async () => {
          let forceChangePassword = await import(
            "./pages/auth/forceChangePassword/forceChangePassword"
          );
          return { Component: forceChangePassword.default };
        },
      },
      {
        path: "signin/mfa",
        lazy: async () => {
          let signinMFA = await import("./pages/auth/signin/signinMFA");
          return { Component: signinMFA.default };
        },
      },
      {
        path: "resetpassword",
        action: resetPasswordAction,
        lazy: async () => {
          let resetPassword = await import("./pages/auth/reset/resetPassword");
          return { Component: resetPassword.default };
        },
      },
      {
        path: "signup",
        action: signupAction,
        loader: signupLoader,
        lazy: async () => {
          let signup = await import("./pages/auth/signup/signup");
          return { Component: signup.default };
        },
      },
      {
        path: "recoverMFA",
        action: recoverMFAAction,
        loader: recoverMFAALoader,
        lazy: async () => {
          let recoverMFA = await import("./pages/auth/recoverMFA/recoverMFA");
          return { Component: recoverMFA.default };
        },
      },
    ],
  },
] as any;

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
