import moment from "moment";

export function isNewContent(createdAt: number) {
  let diff = moment().diff(moment(createdAt), 'days');
  return diff <= 7;
}

export function capitalizeFirstLetter(string:string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
