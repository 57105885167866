import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

export default function CarouselArrow(arrowProps: any) {
  const { carouselState, children, direction, ...restArrowProps } = arrowProps;
  return (
    <div
      {...restArrowProps}
      className={`absolute top-0  h-full flex items-center ${direction === "right"? 'right-0': 'left-0'}  p-2 hover:bg-zinc-950 hover:bg-opacity-50 cursor-pointer`}
    >
      {
        (direction === "left") ?
        <ArrowLeftIcon className=" text-primary-400 h-5 " />: <ArrowRightIcon className=" text-primary-400 h-5 " />
      }
    </div>
  );
};