import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  content_String_NotNull_minLength_1_maxLength_100000: any;
  labId_String_NotNull_pattern_09afAF24: any;
  limit_Int_NotNull_min_1_max_20: any;
  noteId_String_NotNull_pattern_09af809af409af409af409af12: any;
  page_Int_NotNull_min_1: any;
  rate_String_NotNull_pattern_15: any;
};

export type Answer = {
  __typename?: "Answer";
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  nr?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type AnswerInput = {
  answer: Array<InputMaybe<Scalars["String"]>>;
  nr: Scalars["Int"];
  type: Scalars["String"];
};

export type Badge = {
  __typename?: "Badge";
  _id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Description = {
  __typename?: "Description";
  about?: Maybe<Scalars["String"]>;
  objectives?: Maybe<Scalars["String"]>;
  preRequisites?: Maybe<Scalars["String"]>;
};

export type Info = {
  __typename?: "Info";
  description?: Maybe<Description>;
  difficulty?: Maybe<Scalars["String"]>;
  difficultyIndex?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  header?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["Float"]>;
};

export type Lab = {
  __typename?: "Lab";
  _id?: Maybe<Scalars["String"]>;
  info?: Maybe<Info>;
  machines?: Maybe<Array<Maybe<Machine>>>;
  name?: Maybe<Scalars["String"]>;
  plan?: Maybe<Plan>;
  properties?: Maybe<Properties>;
  rating?: Maybe<Rating>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  userLabBookmark?: Maybe<Array<Maybe<LabBookmark>>>;
  userLabState?: Maybe<UserLabState>;
};

export type LabBookmark = {
  __typename?: "LabBookmark";
  _id?: Maybe<Scalars["String"]>;
  lab?: Maybe<Lab>;
  labId?: Maybe<Scalars["String"]>;
};

export type LabBookmarkInput = {
  labId: Scalars["labId_String_NotNull_pattern_09afAF24"];
};

export type LabFilterInput = {
  currentlyWatching?: InputMaybe<Scalars["Boolean"]>;
  difficultyIndex?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  finished?: InputMaybe<Scalars["Boolean"]>;
  planIndex?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type LabMachineFlag = {
  __typename?: "LabMachineFlag";
  acquiredBadges?: Maybe<Array<Maybe<Badge>>>;
  exam?: Maybe<Array<Maybe<Question>>>;
  examCorrect?: Maybe<Scalars["Boolean"]>;
  examFinished?: Maybe<Scalars["Boolean"]>;
  examState?: Maybe<Array<Maybe<Answer>>>;
  flagHash?: Maybe<Scalars["String"]>;
  flagName?: Maybe<Scalars["String"]>;
  labId?: Maybe<Scalars["String"]>;
  machineAcquired?: Maybe<Scalars["Boolean"]>;
  machineId?: Maybe<Scalars["String"]>;
};

export type LabMachineWalkthrough = {
  __typename?: "LabMachineWalkthrough";
  labId?: Maybe<Scalars["String"]>;
  machineId?: Maybe<Scalars["String"]>;
  walkthrough?: Maybe<Scalars["String"]>;
};

export type Machine = {
  __typename?: "Machine";
  _id?: Maybe<Scalars["String"]>;
  alias?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  imageTag?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MachineHint = {
  __typename?: "MachineHint";
  hint?: Maybe<Scalars["String"]>;
  labId?: Maybe<Scalars["String"]>;
  machineId?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  bookmarkLab?: Maybe<Scalars["Boolean"]>;
  createNote?: Maybe<Note>;
  deleteLabBookmark?: Maybe<Scalars["Boolean"]>;
  deleteNote?: Maybe<Scalars["Boolean"]>;
  retakeLabMachineFlagExam?: Maybe<LabMachineFlag>;
  saveUserLabRate?: Maybe<Scalars["Boolean"]>;
  startLab?: Maybe<Scalars["String"]>;
  stopLab?: Maybe<Scalars["String"]>;
  submitLabMachineFlag?: Maybe<LabMachineFlag>;
  submitLabMachineFlagExam?: Maybe<LabMachineFlag>;
  unlockMachineHint?: Maybe<MachineHint>;
  updateNote?: Maybe<Scalars["Boolean"]>;
};

export type MutationBookmarkLabArgs = {
  input: LabBookmarkInput;
};

export type MutationCreateNoteArgs = {
  input: UserLabNoteInput;
};

export type MutationDeleteLabBookmarkArgs = {
  input: LabBookmarkInput;
};

export type MutationDeleteNoteArgs = {
  input: UserLabNoteDeleteInput;
};

export type MutationRetakeLabMachineFlagExamArgs = {
  retakeLabMachineFlagExamInput: RetakeLabMachineFlagExam;
};

export type MutationSaveUserLabRateArgs = {
  input: UserLabRateInput;
};

export type MutationStartLabArgs = {
  labId: Scalars["String"];
};

export type MutationStopLabArgs = {
  labId: Scalars["String"];
};

export type MutationSubmitLabMachineFlagArgs = {
  submitLabMachineFlagInput: SubmitLabMachineFlag;
};

export type MutationSubmitLabMachineFlagExamArgs = {
  submitLabMachineFlagExamInput: SubmitLabMachineFlagExam;
};

export type MutationUnlockMachineHintArgs = {
  labId?: InputMaybe<Scalars["String"]>;
  machineId?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateNoteArgs = {
  input: UserLabNoteUpdateInput;
};

export type Note = {
  __typename?: "Note";
  content?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type PaginatedLabBookmarks = {
  __typename?: "PaginatedLabBookmarks";
  docs?: Maybe<Array<Maybe<LabBookmark>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedLabs = {
  __typename?: "PaginatedLabs";
  docs?: Maybe<Array<Maybe<Lab>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedUserLabNotes = {
  __typename?: "PaginatedUserLabNotes";
  docs?: Maybe<Array<Maybe<UserLabNote>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginatedUserLabState = {
  __typename?: "PaginatedUserLabState";
  docs?: Maybe<Array<Maybe<UserLabState>>>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  hasPrevPage?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  page?: Maybe<Scalars["Int"]>;
  totalDocs?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PaginationInput = {
  limit: Scalars["limit_Int_NotNull_min_1_max_20"];
  page: Scalars["page_Int_NotNull_min_1"];
};

export type Plan = {
  __typename?: "Plan";
  index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Properties = {
  __typename?: "Properties";
  hasDesktop?: Maybe<Scalars["Boolean"]>;
  showOnHome?: Maybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  finishedLabs?: Maybe<PaginatedUserLabState>;
  getLabById?: Maybe<Lab>;
  getLabMachineFlagState?: Maybe<Array<Maybe<LabMachineFlag>>>;
  getLabMachineWalkthrough?: Maybe<LabMachineWalkthrough>;
  getLabsByIds?: Maybe<Array<Maybe<Lab>>>;
  getUnlockedMachineHints?: Maybe<UnlockedMachineHint>;
  getUserLabRateByLabId?: Maybe<UserLabRate>;
  getUserLabStateByLabId?: Maybe<UserLabState>;
  labBookmarks?: Maybe<PaginatedLabBookmarks>;
  labNotes?: Maybe<UserLabNote>;
  notes?: Maybe<PaginatedUserLabNotes>;
  searchLabs?: Maybe<PaginatedLabs>;
  userLabs?: Maybe<PaginatedUserLabState>;
};

export type QueryFinishedLabsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryGetLabByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetLabMachineFlagStateArgs = {
  labId: Scalars["String"];
};

export type QueryGetLabMachineWalkthroughArgs = {
  labId: Scalars["String"];
  machineId: Scalars["String"];
};

export type QueryGetLabsByIdsArgs = {
  labIds: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetUnlockedMachineHintsArgs = {
  labId: Scalars["String"];
};

export type QueryGetUserLabRateByLabIdArgs = {
  id: Scalars["String"];
};

export type QueryGetUserLabStateByLabIdArgs = {
  id: Scalars["String"];
};

export type QueryLabBookmarksArgs = {
  pagination: PaginationInput;
};

export type QueryLabNotesArgs = {
  id: Scalars["String"];
};

export type QueryNotesArgs = {
  pagination: PaginationInput;
};

export type QuerySearchLabsArgs = {
  filter?: InputMaybe<LabFilterInput>;
  pagination: PaginationInput;
  searchString: Scalars["String"];
};

export type QueryUserLabsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Question = {
  __typename?: "Question";
  answer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  choiceAnswer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  correct?: Maybe<Scalars["Boolean"]>;
  nr?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Rating = {
  __typename?: "Rating";
  star1?: Maybe<Scalars["Int"]>;
  star2?: Maybe<Scalars["Int"]>;
  star3?: Maybe<Scalars["Int"]>;
  star4?: Maybe<Scalars["Int"]>;
  star5?: Maybe<Scalars["Int"]>;
};

export type RetakeLabMachineFlagExam = {
  flagHash: Scalars["String"];
  labId: Scalars["String"];
};

export type SubmitLabMachineFlag = {
  flagHash?: InputMaybe<Scalars["String"]>;
  labId?: InputMaybe<Scalars["String"]>;
};

export type SubmitLabMachineFlagExam = {
  answer: AnswerInput;
  flagHash: Scalars["String"];
  labId: Scalars["String"];
};

export type Tag = {
  __typename?: "Tag";
  _id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type UnlockedMachineHint = {
  __typename?: "UnlockedMachineHint";
  labId?: Maybe<Scalars["String"]>;
  unlockedHints?: Maybe<Array<Maybe<MachineHint>>>;
};

export type UserLabNote = {
  __typename?: "UserLabNote";
  _id?: Maybe<Scalars["String"]>;
  labId?: Maybe<Scalars["String"]>;
  labName?: Maybe<Scalars["String"]>;
  notes?: Maybe<Array<Maybe<Note>>>;
};

export type UserLabNoteDeleteInput = {
  labId: Scalars["labId_String_NotNull_pattern_09afAF24"];
  noteId: Scalars["noteId_String_NotNull_pattern_09af809af409af409af409af12"];
};

export type UserLabNoteInput = {
  content: Scalars["content_String_NotNull_minLength_1_maxLength_100000"];
  labId: Scalars["labId_String_NotNull_pattern_09afAF24"];
};

export type UserLabNoteUpdateInput = {
  content: Scalars["content_String_NotNull_minLength_1_maxLength_100000"];
  labId: Scalars["labId_String_NotNull_pattern_09afAF24"];
  noteId: Scalars["noteId_String_NotNull_pattern_09af809af409af409af409af12"];
};

export type UserLabRate = {
  __typename?: "UserLabRate";
  labId?: Maybe<Scalars["String"]>;
  rate?: Maybe<Scalars["Int"]>;
};

export type UserLabRateInput = {
  labId: Scalars["labId_String_NotNull_pattern_09afAF24"];
  rate: Scalars["rate_String_NotNull_pattern_15"];
};

export type UserLabState = {
  __typename?: "UserLabState";
  createdAt?: Maybe<Scalars["Float"]>;
  finished?: Maybe<Scalars["Boolean"]>;
  finishedCount?: Maybe<Scalars["Int"]>;
  labId?: Maybe<Scalars["String"]>;
  labName?: Maybe<Scalars["String"]>;
  machinesCount?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Float"]>;
};

export type SearchAndFilterLabsQueryVariables = Exact<{
  searchString: Scalars["String"];
  filter?: InputMaybe<LabFilterInput>;
  pagination: PaginationInput;
}>;

export type SearchAndFilterLabsQuery = {
  __typename?: "Query";
  search?: {
    __typename?: "PaginatedLabs";
    totalDocs?: number | null;
    totalPages?: number | null;
    page?: number | null;
    docs?: Array<{
      __typename?: "Lab";
      _id?: string | null;
      name?: string | null;
      rating?: {
        __typename?: "Rating";
        star1?: number | null;
        star2?: number | null;
        star3?: number | null;
        star4?: number | null;
        star5?: number | null;
      } | null;
      plan?: {
        __typename?: "Plan";
        index?: number | null;
        name?: string | null;
      } | null;
      properties?: {
        __typename?: "Properties";
        showOnHome?: boolean | null;
        hasDesktop?: boolean | null;
      } | null;
      tags?: Array<{
        __typename?: "Tag";
        _id?: string | null;
        value?: string | null;
      } | null> | null;
      info?: {
        __typename?: "Info";
        header?: string | null;
        releaseDate?: number | null;
        difficulty?: string | null;
        difficultyIndex?: string | null;
        duration?: number | null;
      } | null;
      bookmark?: Array<{
        __typename?: "LabBookmark";
        labId?: string | null;
      } | null> | null;
      state?: {
        __typename?: "UserLabState";
        labId?: string | null;
        labName?: string | null;
        finished?: boolean | null;
        updatedAt?: number | null;
        createdAt?: number | null;
        finishedUnits?: number | null;
        totalUnits?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetLabsByIdsQueryVariables = Exact<{
  labIds: Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>;
}>;

export type GetLabsByIdsQuery = {
  __typename?: "Query";
  getLabsByIds?: Array<{
    __typename?: "Lab";
    _id?: string | null;
    name?: string | null;
    plan?: {
      __typename?: "Plan";
      index?: number | null;
      name?: string | null;
    } | null;
    properties?: {
      __typename?: "Properties";
      showOnHome?: boolean | null;
      hasDesktop?: boolean | null;
    } | null;
    rating?: {
      __typename?: "Rating";
      star5?: number | null;
      star4?: number | null;
      star3?: number | null;
      star2?: number | null;
      star1?: number | null;
    } | null;
    tags?: Array<{
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null> | null;
    info?: {
      __typename?: "Info";
      header?: string | null;
      difficultyIndex?: string | null;
      difficulty?: string | null;
      releaseDate?: number | null;
      description?: {
        __typename?: "Description";
        about?: string | null;
        objectives?: string | null;
        preRequisites?: string | null;
      } | null;
    } | null;
    state?: {
      __typename?: "UserLabState";
      labId?: string | null;
      labName?: string | null;
      state?: string | null;
      finished?: boolean | null;
      totalUnits?: number | null;
      finishedUnits?: number | null;
    } | null;
    machines?: Array<{
      __typename?: "Machine";
      _id?: string | null;
      description?: string | null;
      alias?: string | null;
      name?: string | null;
      hidden?: boolean | null;
      imageTag?: string | null;
      type?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetLabByIdQueryVariables = Exact<{
  labId: Scalars["String"];
}>;

export type GetLabByIdQuery = {
  __typename?: "Query";
  getUserLabRateByLabId?: {
    __typename?: "UserLabRate";
    labId?: string | null;
    rate?: number | null;
  } | null;
  getLabById?: {
    __typename?: "Lab";
    _id?: string | null;
    name?: string | null;
    plan?: {
      __typename?: "Plan";
      index?: number | null;
      name?: string | null;
    } | null;
    properties?: {
      __typename?: "Properties";
      showOnHome?: boolean | null;
      hasDesktop?: boolean | null;
    } | null;
    rating?: {
      __typename?: "Rating";
      star5?: number | null;
      star4?: number | null;
      star3?: number | null;
      star2?: number | null;
      star1?: number | null;
    } | null;
    tags?: Array<{
      __typename?: "Tag";
      _id?: string | null;
      value?: string | null;
    } | null> | null;
    info?: {
      __typename?: "Info";
      header?: string | null;
      difficultyIndex?: string | null;
      difficulty?: string | null;
      releaseDate?: number | null;
      duration?: number | null;
      description?: {
        __typename?: "Description";
        about?: string | null;
        objectives?: string | null;
        preRequisites?: string | null;
      } | null;
    } | null;
    state?: {
      __typename?: "UserLabState";
      labId?: string | null;
      labName?: string | null;
      state?: string | null;
      finished?: boolean | null;
      finishedUnits?: number | null;
      totalUnits?: number | null;
    } | null;
    bookmark?: Array<{
      __typename?: "LabBookmark";
      labId?: string | null;
    } | null> | null;
    machines?: Array<{
      __typename?: "Machine";
      _id?: string | null;
      description?: string | null;
      alias?: string | null;
      name?: string | null;
      hidden?: boolean | null;
      imageTag?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export type GetLabWorkspaceDataQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetLabWorkspaceDataQuery = {
  __typename?: "Query";
  getLabById?: {
    __typename?: "Lab";
    _id?: string | null;
    name?: string | null;
    properties?: {
      __typename?: "Properties";
      hasDesktop?: boolean | null;
    } | null;
    machines?: Array<{
      __typename?: "Machine";
      _id?: string | null;
      description?: string | null;
      alias?: string | null;
      name?: string | null;
      hidden?: boolean | null;
      imageTag?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
  getLabMachineFlagState?: Array<{
    __typename?: "LabMachineFlag";
    machineId?: string | null;
    machineAcquired?: boolean | null;
    flagHash?: string | null;
  } | null> | null;
  getUnlockedMachineHints?: {
    __typename?: "UnlockedMachineHint";
    labId?: string | null;
    unlockedHints?: Array<{
      __typename?: "MachineHint";
      machineId?: string | null;
      hint?: string | null;
    } | null> | null;
  } | null;
  getUserLabStateByLabId?: {
    __typename?: "UserLabState";
    state?: string | null;
  } | null;
};

export type SubmitLabMachineFlagMutationVariables = Exact<{
  submitLabMachineFlagInput: SubmitLabMachineFlag;
}>;

export type SubmitLabMachineFlagMutation = {
  __typename?: "Mutation";
  submitLabMachineFlag?: {
    __typename?: "LabMachineFlag";
    labId?: string | null;
    machineId?: string | null;
    flagName?: string | null;
    exam?: Array<{
      __typename?: "Question";
      nr?: number | null;
      question?: string | null;
      type?: string | null;
      choiceAnswer?: Array<string | null> | null;
    } | null> | null;
    examState?: Array<{
      __typename?: "Answer";
      nr?: number | null;
      type?: string | null;
      answer?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type UnlockMachineHintMutationVariables = Exact<{
  labId: Scalars["String"];
  machineId: Scalars["String"];
}>;

export type UnlockMachineHintMutation = {
  __typename?: "Mutation";
  unlockMachineHint?: {
    __typename?: "MachineHint";
    machineId?: string | null;
    hint?: string | null;
  } | null;
};

export type SubmitLabMachineFlagExamMutationVariables = Exact<{
  submitLabMachineFlagExamInput: SubmitLabMachineFlagExam;
}>;

export type SubmitLabMachineFlagExamMutation = {
  __typename?: "Mutation";
  submitLabMachineFlagExam?: {
    __typename?: "LabMachineFlag";
    examFinished?: boolean | null;
    examCorrect?: boolean | null;
    machineAcquired?: boolean | null;
    exam?: Array<{
      __typename?: "Question";
      type?: string | null;
      correct?: boolean | null;
      answer?: Array<string | null> | null;
      question?: string | null;
    } | null> | null;
    acquiredBadges?: Array<{
      __typename?: "Badge";
      name?: string | null;
      description?: string | null;
      img?: string | null;
    } | null> | null;
  } | null;
};

export type RetakeLabMachineFlagExamMutationVariables = Exact<{
  labId: Scalars["String"];
  flagHash: Scalars["String"];
}>;

export type RetakeLabMachineFlagExamMutation = {
  __typename?: "Mutation";
  retakeLabMachineFlagExam?: {
    __typename?: "LabMachineFlag";
    exam?: Array<{
      __typename?: "Question";
      nr?: number | null;
      question?: string | null;
      type?: string | null;
      choiceAnswer?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type StartLabMutationVariables = Exact<{
  labId: Scalars["String"];
}>;

export type StartLabMutation = {
  __typename?: "Mutation";
  startLab?: string | null;
};

export type GetLabMachineWalkthroughQueryVariables = Exact<{
  labId: Scalars["String"];
  machineId: Scalars["String"];
}>;

export type GetLabMachineWalkthroughQuery = {
  __typename?: "Query";
  getLabMachineWalkthrough?: {
    __typename?: "LabMachineWalkthrough";
    labId?: string | null;
    machineId?: string | null;
    walkthrough?: string | null;
  } | null;
};

export type UserLabsQueryVariables = Exact<{
  pagination: PaginationInput;
}>;

export type UserLabsQuery = {
  __typename?: "Query";
  userLabs?: {
    __typename?: "PaginatedUserLabState";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "UserLabState";
      state?: string | null;
      machinesCount?: number | null;
      finishedCount?: number | null;
      finished?: boolean | null;
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type NotesQueryVariables = Exact<{
  pagination: PaginationInput;
}>;

export type NotesQuery = {
  __typename?: "Query";
  notes?: {
    __typename?: "PaginatedUserLabNotes";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "UserLabNote";
      _id?: string | null;
      labName?: string | null;
      labId?: string | null;
      notes?: Array<{
        __typename?: "Note";
        id?: string | null;
        content?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type LabNotesQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type LabNotesQuery = {
  __typename?: "Query";
  labNotes?: {
    __typename?: "UserLabNote";
    _id?: string | null;
    notes?: Array<{
      __typename?: "Note";
      id?: string | null;
      content?: string | null;
    } | null> | null;
  } | null;
};

export type CreateNoteMutationVariables = Exact<{
  input: UserLabNoteInput;
}>;

export type CreateNoteMutation = {
  __typename?: "Mutation";
  createNote?: {
    __typename?: "Note";
    id?: string | null;
    content?: string | null;
  } | null;
};

export type UpdateNoteMutationVariables = Exact<{
  input: UserLabNoteUpdateInput;
}>;

export type UpdateNoteMutation = {
  __typename?: "Mutation";
  updateNote?: boolean | null;
};

export type DeleteNoteMutationVariables = Exact<{
  input: UserLabNoteDeleteInput;
}>;

export type DeleteNoteMutation = {
  __typename?: "Mutation";
  deleteNote?: boolean | null;
};

export type BookmarkLabMutationVariables = Exact<{
  input: LabBookmarkInput;
}>;

export type BookmarkLabMutation = {
  __typename?: "Mutation";
  bookmarkLab?: boolean | null;
};

export type DeleteLabBookmarkMutationVariables = Exact<{
  input: LabBookmarkInput;
}>;

export type DeleteLabBookmarkMutation = {
  __typename?: "Mutation";
  deleteLabBookmark?: boolean | null;
};

export type SaveUserLabRateMutationVariables = Exact<{
  input: UserLabRateInput;
}>;

export type SaveUserLabRateMutation = {
  __typename?: "Mutation";
  saveUserLabRate?: boolean | null;
};

export type GetUserLabRateByLabIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetUserLabRateByLabIdQuery = {
  __typename?: "Query";
  getUserLabRateByLabId?: {
    __typename?: "UserLabRate";
    labId?: string | null;
    rate?: number | null;
  } | null;
};

export type LabBookmarksQueryVariables = Exact<{
  input: PaginationInput;
}>;

export type LabBookmarksQuery = {
  __typename?: "Query";
  labBookmarks?: {
    __typename?: "PaginatedLabBookmarks";
    totalDocs?: number | null;
    limit?: number | null;
    page?: number | null;
    hasPrevPage?: boolean | null;
    hasNextPage?: boolean | null;
    totalPages?: number | null;
    docs?: Array<{
      __typename?: "LabBookmark";
      _id?: string | null;
      lab?: {
        __typename?: "Lab";
        name?: string | null;
        _id?: string | null;
        info?: {
          __typename?: "Info";
          difficulty?: string | null;
          difficultyIndex?: string | null;
          releaseDate?: number | null;
          duration?: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export const SearchAndFilterLabsDocument = gql`
  query searchAndFilterLabs(
    $searchString: String!
    $filter: LabFilterInput
    $pagination: PaginationInput!
  ) {
    search: searchLabs(
      searchString: $searchString
      filter: $filter
      pagination: $pagination
    ) {
      docs {
        _id
        name
        rating {
          star1
          star2
          star3
          star4
          star5
        }
        plan {
          index
          name
        }
        properties {
          showOnHome
          hasDesktop
        }
        tags {
          _id
          value
        }
        info {
          header
          releaseDate
          difficulty
          difficultyIndex
          duration
        }
        bookmark: userLabBookmark {
          labId
        }
        state: userLabState {
          labId
          labName
          finished
          updatedAt
          createdAt
          finishedUnits: finishedCount
          totalUnits: machinesCount
        }
      }
      totalDocs
      totalPages
      page
    }
  }
`;

/**
 * __useSearchAndFilterLabsQuery__
 *
 * To run a query within a React component, call `useSearchAndFilterLabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAndFilterLabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAndFilterLabsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchAndFilterLabsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAndFilterLabsQuery,
    SearchAndFilterLabsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAndFilterLabsQuery,
    SearchAndFilterLabsQueryVariables
  >(SearchAndFilterLabsDocument, options);
}
export function useSearchAndFilterLabsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAndFilterLabsQuery,
    SearchAndFilterLabsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAndFilterLabsQuery,
    SearchAndFilterLabsQueryVariables
  >(SearchAndFilterLabsDocument, options);
}
export type SearchAndFilterLabsQueryHookResult = ReturnType<
  typeof useSearchAndFilterLabsQuery
>;
export type SearchAndFilterLabsLazyQueryHookResult = ReturnType<
  typeof useSearchAndFilterLabsLazyQuery
>;
export type SearchAndFilterLabsQueryResult = Apollo.QueryResult<
  SearchAndFilterLabsQuery,
  SearchAndFilterLabsQueryVariables
>;
export const GetLabsByIdsDocument = gql`
  query getLabsByIds($labIds: [String]!) {
    getLabsByIds(labIds: $labIds) {
      _id
      name
      plan {
        index
        name
      }
      properties {
        showOnHome
        hasDesktop
      }
      rating {
        star5
        star4
        star3
        star2
        star1
      }
      tags {
        _id
        value
      }
      info {
        header
        difficultyIndex
        difficulty
        releaseDate
        description {
          about
          objectives
          preRequisites
        }
      }
      state: userLabState {
        labId
        labName
        state
        totalUnits: machinesCount
        finishedUnits: finishedCount
        finished
      }
      machines {
        _id
        description
        alias
        name
        hidden
        imageTag
        type
      }
    }
  }
`;

/**
 * __useGetLabsByIdsQuery__
 *
 * To run a query within a React component, call `useGetLabsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabsByIdsQuery({
 *   variables: {
 *      labIds: // value for 'labIds'
 *   },
 * });
 */
export function useGetLabsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabsByIdsQuery,
    GetLabsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabsByIdsQuery, GetLabsByIdsQueryVariables>(
    GetLabsByIdsDocument,
    options
  );
}
export function useGetLabsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabsByIdsQuery,
    GetLabsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabsByIdsQuery, GetLabsByIdsQueryVariables>(
    GetLabsByIdsDocument,
    options
  );
}
export type GetLabsByIdsQueryHookResult = ReturnType<
  typeof useGetLabsByIdsQuery
>;
export type GetLabsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetLabsByIdsLazyQuery
>;
export type GetLabsByIdsQueryResult = Apollo.QueryResult<
  GetLabsByIdsQuery,
  GetLabsByIdsQueryVariables
>;
export const GetLabByIdDocument = gql`
  query getLabById($labId: String!) {
    getUserLabRateByLabId(id: $labId) {
      labId
      rate
    }
    getLabById(id: $labId) {
      _id
      name
      plan {
        index
        name
      }
      properties {
        showOnHome
        hasDesktop
      }
      rating {
        star5
        star4
        star3
        star2
        star1
      }
      tags {
        _id
        value
      }
      info {
        header
        difficultyIndex
        difficulty
        releaseDate
        duration
        description {
          about
          objectives
          preRequisites
        }
      }
      properties {
        hasDesktop
      }
      state: userLabState {
        labId
        labName
        state
        finishedUnits: finishedCount
        totalUnits: machinesCount
        finished
      }
      bookmark: userLabBookmark {
        labId
      }
      machines {
        _id
        description
        alias
        name
        hidden
        imageTag
        type
      }
    }
  }
`;

/**
 * __useGetLabByIdQuery__
 *
 * To run a query within a React component, call `useGetLabByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabByIdQuery({
 *   variables: {
 *      labId: // value for 'labId'
 *   },
 * });
 */
export function useGetLabByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabByIdQuery,
    GetLabByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabByIdQuery, GetLabByIdQueryVariables>(
    GetLabByIdDocument,
    options
  );
}
export function useGetLabByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabByIdQuery,
    GetLabByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabByIdQuery, GetLabByIdQueryVariables>(
    GetLabByIdDocument,
    options
  );
}
export type GetLabByIdQueryHookResult = ReturnType<typeof useGetLabByIdQuery>;
export type GetLabByIdLazyQueryHookResult = ReturnType<
  typeof useGetLabByIdLazyQuery
>;
export type GetLabByIdQueryResult = Apollo.QueryResult<
  GetLabByIdQuery,
  GetLabByIdQueryVariables
>;
export const GetLabWorkspaceDataDocument = gql`
  query getLabWorkspaceData($id: String!) {
    getLabById(id: $id) {
      _id
      name
      properties {
        hasDesktop
      }
      machines {
        _id
        description
        alias
        name
        hidden
        imageTag
        type
      }
    }
    getLabMachineFlagState(labId: $id) {
      machineId
      machineAcquired
      flagHash
    }
    getUnlockedMachineHints(labId: $id) {
      labId
      unlockedHints {
        machineId
        hint
      }
    }
    getLabMachineFlagState(labId: $id) {
      machineId
      machineAcquired
      flagHash
    }
    getUserLabStateByLabId(id: $id) {
      state
    }
  }
`;

/**
 * __useGetLabWorkspaceDataQuery__
 *
 * To run a query within a React component, call `useGetLabWorkspaceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabWorkspaceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabWorkspaceDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLabWorkspaceDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabWorkspaceDataQuery,
    GetLabWorkspaceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLabWorkspaceDataQuery,
    GetLabWorkspaceDataQueryVariables
  >(GetLabWorkspaceDataDocument, options);
}
export function useGetLabWorkspaceDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabWorkspaceDataQuery,
    GetLabWorkspaceDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabWorkspaceDataQuery,
    GetLabWorkspaceDataQueryVariables
  >(GetLabWorkspaceDataDocument, options);
}
export type GetLabWorkspaceDataQueryHookResult = ReturnType<
  typeof useGetLabWorkspaceDataQuery
>;
export type GetLabWorkspaceDataLazyQueryHookResult = ReturnType<
  typeof useGetLabWorkspaceDataLazyQuery
>;
export type GetLabWorkspaceDataQueryResult = Apollo.QueryResult<
  GetLabWorkspaceDataQuery,
  GetLabWorkspaceDataQueryVariables
>;
export const SubmitLabMachineFlagDocument = gql`
  mutation submitLabMachineFlag(
    $submitLabMachineFlagInput: SubmitLabMachineFlag!
  ) {
    submitLabMachineFlag(
      submitLabMachineFlagInput: $submitLabMachineFlagInput
    ) {
      labId
      machineId
      flagName
      exam {
        nr
        question
        type
        choiceAnswer
      }
      examState {
        nr
        type
        answer
      }
    }
  }
`;
export type SubmitLabMachineFlagMutationFn = Apollo.MutationFunction<
  SubmitLabMachineFlagMutation,
  SubmitLabMachineFlagMutationVariables
>;

/**
 * __useSubmitLabMachineFlagMutation__
 *
 * To run a mutation, you first call `useSubmitLabMachineFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLabMachineFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLabMachineFlagMutation, { data, loading, error }] = useSubmitLabMachineFlagMutation({
 *   variables: {
 *      submitLabMachineFlagInput: // value for 'submitLabMachineFlagInput'
 *   },
 * });
 */
export function useSubmitLabMachineFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitLabMachineFlagMutation,
    SubmitLabMachineFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitLabMachineFlagMutation,
    SubmitLabMachineFlagMutationVariables
  >(SubmitLabMachineFlagDocument, options);
}
export type SubmitLabMachineFlagMutationHookResult = ReturnType<
  typeof useSubmitLabMachineFlagMutation
>;
export type SubmitLabMachineFlagMutationResult =
  Apollo.MutationResult<SubmitLabMachineFlagMutation>;
export type SubmitLabMachineFlagMutationOptions = Apollo.BaseMutationOptions<
  SubmitLabMachineFlagMutation,
  SubmitLabMachineFlagMutationVariables
>;
export const UnlockMachineHintDocument = gql`
  mutation unlockMachineHint($labId: String!, $machineId: String!) {
    unlockMachineHint(labId: $labId, machineId: $machineId) {
      machineId
      hint
    }
  }
`;
export type UnlockMachineHintMutationFn = Apollo.MutationFunction<
  UnlockMachineHintMutation,
  UnlockMachineHintMutationVariables
>;

/**
 * __useUnlockMachineHintMutation__
 *
 * To run a mutation, you first call `useUnlockMachineHintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockMachineHintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockMachineHintMutation, { data, loading, error }] = useUnlockMachineHintMutation({
 *   variables: {
 *      labId: // value for 'labId'
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useUnlockMachineHintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlockMachineHintMutation,
    UnlockMachineHintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlockMachineHintMutation,
    UnlockMachineHintMutationVariables
  >(UnlockMachineHintDocument, options);
}
export type UnlockMachineHintMutationHookResult = ReturnType<
  typeof useUnlockMachineHintMutation
>;
export type UnlockMachineHintMutationResult =
  Apollo.MutationResult<UnlockMachineHintMutation>;
export type UnlockMachineHintMutationOptions = Apollo.BaseMutationOptions<
  UnlockMachineHintMutation,
  UnlockMachineHintMutationVariables
>;
export const SubmitLabMachineFlagExamDocument = gql`
  mutation submitLabMachineFlagExam(
    $submitLabMachineFlagExamInput: SubmitLabMachineFlagExam!
  ) {
    submitLabMachineFlagExam(
      submitLabMachineFlagExamInput: $submitLabMachineFlagExamInput
    ) {
      examFinished
      examCorrect
      machineAcquired
      exam {
        type
        correct
        answer
        question
      }
      acquiredBadges {
        name
        description
        img
      }
    }
  }
`;
export type SubmitLabMachineFlagExamMutationFn = Apollo.MutationFunction<
  SubmitLabMachineFlagExamMutation,
  SubmitLabMachineFlagExamMutationVariables
>;

/**
 * __useSubmitLabMachineFlagExamMutation__
 *
 * To run a mutation, you first call `useSubmitLabMachineFlagExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLabMachineFlagExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLabMachineFlagExamMutation, { data, loading, error }] = useSubmitLabMachineFlagExamMutation({
 *   variables: {
 *      submitLabMachineFlagExamInput: // value for 'submitLabMachineFlagExamInput'
 *   },
 * });
 */
export function useSubmitLabMachineFlagExamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitLabMachineFlagExamMutation,
    SubmitLabMachineFlagExamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitLabMachineFlagExamMutation,
    SubmitLabMachineFlagExamMutationVariables
  >(SubmitLabMachineFlagExamDocument, options);
}
export type SubmitLabMachineFlagExamMutationHookResult = ReturnType<
  typeof useSubmitLabMachineFlagExamMutation
>;
export type SubmitLabMachineFlagExamMutationResult =
  Apollo.MutationResult<SubmitLabMachineFlagExamMutation>;
export type SubmitLabMachineFlagExamMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitLabMachineFlagExamMutation,
    SubmitLabMachineFlagExamMutationVariables
  >;
export const RetakeLabMachineFlagExamDocument = gql`
  mutation retakeLabMachineFlagExam($labId: String!, $flagHash: String!) {
    retakeLabMachineFlagExam(
      retakeLabMachineFlagExamInput: { labId: $labId, flagHash: $flagHash }
    ) {
      exam {
        nr
        question
        type
        choiceAnswer
      }
    }
  }
`;
export type RetakeLabMachineFlagExamMutationFn = Apollo.MutationFunction<
  RetakeLabMachineFlagExamMutation,
  RetakeLabMachineFlagExamMutationVariables
>;

/**
 * __useRetakeLabMachineFlagExamMutation__
 *
 * To run a mutation, you first call `useRetakeLabMachineFlagExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetakeLabMachineFlagExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retakeLabMachineFlagExamMutation, { data, loading, error }] = useRetakeLabMachineFlagExamMutation({
 *   variables: {
 *      labId: // value for 'labId'
 *      flagHash: // value for 'flagHash'
 *   },
 * });
 */
export function useRetakeLabMachineFlagExamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetakeLabMachineFlagExamMutation,
    RetakeLabMachineFlagExamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetakeLabMachineFlagExamMutation,
    RetakeLabMachineFlagExamMutationVariables
  >(RetakeLabMachineFlagExamDocument, options);
}
export type RetakeLabMachineFlagExamMutationHookResult = ReturnType<
  typeof useRetakeLabMachineFlagExamMutation
>;
export type RetakeLabMachineFlagExamMutationResult =
  Apollo.MutationResult<RetakeLabMachineFlagExamMutation>;
export type RetakeLabMachineFlagExamMutationOptions =
  Apollo.BaseMutationOptions<
    RetakeLabMachineFlagExamMutation,
    RetakeLabMachineFlagExamMutationVariables
  >;
export const StartLabDocument = gql`
  mutation startLab($labId: String!) {
    startLab(labId: $labId)
  }
`;
export type StartLabMutationFn = Apollo.MutationFunction<
  StartLabMutation,
  StartLabMutationVariables
>;

/**
 * __useStartLabMutation__
 *
 * To run a mutation, you first call `useStartLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLabMutation, { data, loading, error }] = useStartLabMutation({
 *   variables: {
 *      labId: // value for 'labId'
 *   },
 * });
 */
export function useStartLabMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartLabMutation,
    StartLabMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartLabMutation, StartLabMutationVariables>(
    StartLabDocument,
    options
  );
}
export type StartLabMutationHookResult = ReturnType<typeof useStartLabMutation>;
export type StartLabMutationResult = Apollo.MutationResult<StartLabMutation>;
export type StartLabMutationOptions = Apollo.BaseMutationOptions<
  StartLabMutation,
  StartLabMutationVariables
>;
export const GetLabMachineWalkthroughDocument = gql`
  query getLabMachineWalkthrough($labId: String!, $machineId: String!) {
    getLabMachineWalkthrough(labId: $labId, machineId: $machineId) {
      labId
      machineId
      walkthrough
    }
  }
`;

/**
 * __useGetLabMachineWalkthroughQuery__
 *
 * To run a query within a React component, call `useGetLabMachineWalkthroughQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabMachineWalkthroughQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabMachineWalkthroughQuery({
 *   variables: {
 *      labId: // value for 'labId'
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetLabMachineWalkthroughQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabMachineWalkthroughQuery,
    GetLabMachineWalkthroughQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLabMachineWalkthroughQuery,
    GetLabMachineWalkthroughQueryVariables
  >(GetLabMachineWalkthroughDocument, options);
}
export function useGetLabMachineWalkthroughLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabMachineWalkthroughQuery,
    GetLabMachineWalkthroughQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabMachineWalkthroughQuery,
    GetLabMachineWalkthroughQueryVariables
  >(GetLabMachineWalkthroughDocument, options);
}
export type GetLabMachineWalkthroughQueryHookResult = ReturnType<
  typeof useGetLabMachineWalkthroughQuery
>;
export type GetLabMachineWalkthroughLazyQueryHookResult = ReturnType<
  typeof useGetLabMachineWalkthroughLazyQuery
>;
export type GetLabMachineWalkthroughQueryResult = Apollo.QueryResult<
  GetLabMachineWalkthroughQuery,
  GetLabMachineWalkthroughQueryVariables
>;
export const UserLabsDocument = gql`
  query userLabs($pagination: PaginationInput!) {
    userLabs(pagination: $pagination) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        id: labId
        name: labName
        state
        machinesCount
        finishedCount
        finished
      }
    }
  }
`;

/**
 * __useUserLabsQuery__
 *
 * To run a query within a React component, call `useUserLabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLabsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserLabsQuery(
  baseOptions: Apollo.QueryHookOptions<UserLabsQuery, UserLabsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserLabsQuery, UserLabsQueryVariables>(
    UserLabsDocument,
    options
  );
}
export function useUserLabsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserLabsQuery,
    UserLabsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserLabsQuery, UserLabsQueryVariables>(
    UserLabsDocument,
    options
  );
}
export type UserLabsQueryHookResult = ReturnType<typeof useUserLabsQuery>;
export type UserLabsLazyQueryHookResult = ReturnType<
  typeof useUserLabsLazyQuery
>;
export type UserLabsQueryResult = Apollo.QueryResult<
  UserLabsQuery,
  UserLabsQueryVariables
>;
export const NotesDocument = gql`
  query notes($pagination: PaginationInput!) {
    notes(pagination: $pagination) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        labName
        labId
        notes {
          id
          content
        }
      }
    }
  }
`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useNotesQuery(
  baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotesQuery, NotesQueryVariables>(
    NotesDocument,
    options
  );
}
export function useNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(
    NotesDocument,
    options
  );
}
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<
  NotesQuery,
  NotesQueryVariables
>;
export const LabNotesDocument = gql`
  query labNotes($id: String!) {
    labNotes(id: $id) {
      _id
      notes {
        id
        content
      }
    }
  }
`;

/**
 * __useLabNotesQuery__
 *
 * To run a query within a React component, call `useLabNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabNotesQuery(
  baseOptions: Apollo.QueryHookOptions<LabNotesQuery, LabNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LabNotesQuery, LabNotesQueryVariables>(
    LabNotesDocument,
    options
  );
}
export function useLabNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LabNotesQuery,
    LabNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LabNotesQuery, LabNotesQueryVariables>(
    LabNotesDocument,
    options
  );
}
export type LabNotesQueryHookResult = ReturnType<typeof useLabNotesQuery>;
export type LabNotesLazyQueryHookResult = ReturnType<
  typeof useLabNotesLazyQuery
>;
export type LabNotesQueryResult = Apollo.QueryResult<
  LabNotesQuery,
  LabNotesQueryVariables
>;
export const CreateNoteDocument = gql`
  mutation createNote($input: UserLabNoteInput!) {
    createNote(input: $input) {
      id
      content
    }
  }
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNoteMutation,
    CreateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options
  );
}
export type CreateNoteMutationHookResult = ReturnType<
  typeof useCreateNoteMutation
>;
export type CreateNoteMutationResult =
  Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const UpdateNoteDocument = gql`
  mutation updateNote($input: UserLabNoteUpdateInput!) {
    updateNote(input: $input)
  }
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNoteMutation,
    UpdateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    options
  );
}
export type UpdateNoteMutationHookResult = ReturnType<
  typeof useUpdateNoteMutation
>;
export type UpdateNoteMutationResult =
  Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;
export const DeleteNoteDocument = gql`
  mutation deleteNote($input: UserLabNoteDeleteInput!) {
    deleteNote(input: $input)
  }
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
    options
  );
}
export type DeleteNoteMutationHookResult = ReturnType<
  typeof useDeleteNoteMutation
>;
export type DeleteNoteMutationResult =
  Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;
export const BookmarkLabDocument = gql`
  mutation bookmarkLab($input: LabBookmarkInput!) {
    bookmarkLab(input: $input)
  }
`;
export type BookmarkLabMutationFn = Apollo.MutationFunction<
  BookmarkLabMutation,
  BookmarkLabMutationVariables
>;

/**
 * __useBookmarkLabMutation__
 *
 * To run a mutation, you first call `useBookmarkLabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkLabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkLabMutation, { data, loading, error }] = useBookmarkLabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookmarkLabMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookmarkLabMutation,
    BookmarkLabMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookmarkLabMutation, BookmarkLabMutationVariables>(
    BookmarkLabDocument,
    options
  );
}
export type BookmarkLabMutationHookResult = ReturnType<
  typeof useBookmarkLabMutation
>;
export type BookmarkLabMutationResult =
  Apollo.MutationResult<BookmarkLabMutation>;
export type BookmarkLabMutationOptions = Apollo.BaseMutationOptions<
  BookmarkLabMutation,
  BookmarkLabMutationVariables
>;
export const DeleteLabBookmarkDocument = gql`
  mutation deleteLabBookmark($input: LabBookmarkInput!) {
    deleteLabBookmark(input: $input)
  }
`;
export type DeleteLabBookmarkMutationFn = Apollo.MutationFunction<
  DeleteLabBookmarkMutation,
  DeleteLabBookmarkMutationVariables
>;

/**
 * __useDeleteLabBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteLabBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabBookmarkMutation, { data, loading, error }] = useDeleteLabBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLabBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLabBookmarkMutation,
    DeleteLabBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLabBookmarkMutation,
    DeleteLabBookmarkMutationVariables
  >(DeleteLabBookmarkDocument, options);
}
export type DeleteLabBookmarkMutationHookResult = ReturnType<
  typeof useDeleteLabBookmarkMutation
>;
export type DeleteLabBookmarkMutationResult =
  Apollo.MutationResult<DeleteLabBookmarkMutation>;
export type DeleteLabBookmarkMutationOptions = Apollo.BaseMutationOptions<
  DeleteLabBookmarkMutation,
  DeleteLabBookmarkMutationVariables
>;
export const SaveUserLabRateDocument = gql`
  mutation saveUserLabRate($input: UserLabRateInput!) {
    saveUserLabRate(input: $input)
  }
`;
export type SaveUserLabRateMutationFn = Apollo.MutationFunction<
  SaveUserLabRateMutation,
  SaveUserLabRateMutationVariables
>;

/**
 * __useSaveUserLabRateMutation__
 *
 * To run a mutation, you first call `useSaveUserLabRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserLabRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserLabRateMutation, { data, loading, error }] = useSaveUserLabRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserLabRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserLabRateMutation,
    SaveUserLabRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserLabRateMutation,
    SaveUserLabRateMutationVariables
  >(SaveUserLabRateDocument, options);
}
export type SaveUserLabRateMutationHookResult = ReturnType<
  typeof useSaveUserLabRateMutation
>;
export type SaveUserLabRateMutationResult =
  Apollo.MutationResult<SaveUserLabRateMutation>;
export type SaveUserLabRateMutationOptions = Apollo.BaseMutationOptions<
  SaveUserLabRateMutation,
  SaveUserLabRateMutationVariables
>;
export const GetUserLabRateByLabIdDocument = gql`
  query getUserLabRateByLabId($id: String!) {
    getUserLabRateByLabId(id: $id) {
      labId
      rate
    }
  }
`;

/**
 * __useGetUserLabRateByLabIdQuery__
 *
 * To run a query within a React component, call `useGetUserLabRateByLabIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLabRateByLabIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLabRateByLabIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserLabRateByLabIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserLabRateByLabIdQuery,
    GetUserLabRateByLabIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserLabRateByLabIdQuery,
    GetUserLabRateByLabIdQueryVariables
  >(GetUserLabRateByLabIdDocument, options);
}
export function useGetUserLabRateByLabIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserLabRateByLabIdQuery,
    GetUserLabRateByLabIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserLabRateByLabIdQuery,
    GetUserLabRateByLabIdQueryVariables
  >(GetUserLabRateByLabIdDocument, options);
}
export type GetUserLabRateByLabIdQueryHookResult = ReturnType<
  typeof useGetUserLabRateByLabIdQuery
>;
export type GetUserLabRateByLabIdLazyQueryHookResult = ReturnType<
  typeof useGetUserLabRateByLabIdLazyQuery
>;
export type GetUserLabRateByLabIdQueryResult = Apollo.QueryResult<
  GetUserLabRateByLabIdQuery,
  GetUserLabRateByLabIdQueryVariables
>;
export const LabBookmarksDocument = gql`
  query labBookmarks($input: PaginationInput!) {
    labBookmarks(pagination: $input) {
      totalDocs
      limit
      page
      hasPrevPage
      hasNextPage
      totalPages
      docs {
        _id
        lab {
          name
          _id
          info {
            difficulty
            difficultyIndex
            releaseDate
            duration
          }
        }
      }
    }
  }
`;

/**
 * __useLabBookmarksQuery__
 *
 * To run a query within a React component, call `useLabBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLabBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<
    LabBookmarksQuery,
    LabBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LabBookmarksQuery, LabBookmarksQueryVariables>(
    LabBookmarksDocument,
    options
  );
}
export function useLabBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LabBookmarksQuery,
    LabBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LabBookmarksQuery, LabBookmarksQueryVariables>(
    LabBookmarksDocument,
    options
  );
}
export type LabBookmarksQueryHookResult = ReturnType<
  typeof useLabBookmarksQuery
>;
export type LabBookmarksLazyQueryHookResult = ReturnType<
  typeof useLabBookmarksLazyQuery
>;
export type LabBookmarksQueryResult = Apollo.QueryResult<
  LabBookmarksQuery,
  LabBookmarksQueryVariables
>;
