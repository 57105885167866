import { useParams } from "react-router-dom";
import { useGetLabByIdQuery } from "../../api/LabService";
import ContentAbout from "../../components/ContentAbout";
import Spinner from "../../components/Spinner";

export default function Lab() {
  let { labId } = useParams();

  const { data, loading } = useGetLabByIdQuery({
    variables: {
      labId: labId as string,
    },
  });

  if (loading) {
    return (
      <div className="h-[80vh] flex items-center justify-center">
        <Spinner heigt={"h-10"} />
      </div>
    );
  }

  return (
    <ContentAbout
      state={data?.getLabById?.state}
      contentType="lab"
      userRate={data?.getUserLabRateByLabId}
      contentData={data?.getLabById}
    />
  );
}
